import { validateSurveys } from '../validateSurveys';

const mockedSurveys = [
  {
    name: 'Survey 1',
    optionNumber: 3,
    options: [
      { value: 'Option 1' },
      { value: 'Option 2' },
      { value: 'Option 3' },
    ],
  },
  {
    name: 'Survey 2',
    optionNumber: 3,
    options: [
      { value: 'Option 1' },
      { value: 'Option 2' },
      { value: 'Option 3' },
    ],
  },
];

describe('validateSurveys', () => {
  it('should validate the surveys', () => {
    const validatedSurveys = validateSurveys(mockedSurveys);
    expect(validatedSurveys).toEqual(mockedSurveys);
  });

  it('should return null when no data is provided', () => {
    const validatedSurveys = validateSurveys(null);
    expect(validatedSurveys).toEqual(null);
  });

  it('should handle object with dateLimit field', () => {
    const surveys = [
      {
        name: 'Teste de enquete',
        optionNumber: 1,
        options: ['opção 1', 'opção 2', 'opção 3'],
        dateLimit: '2023-03-19 23:59',
        time: null,
        previousId: null,
      },
    ];
    const validatedSurveys = validateSurveys(surveys);
    expect(validatedSurveys[0].name).toEqual('Teste de enquete');
    expect(validatedSurveys[0].optionNumber).toEqual(1);
    expect(validatedSurveys[0].options).toEqual([
      { value: 'opção 1' },
      { value: 'opção 2' },
      { value: 'opção 3' },
    ]);
    expect(validatedSurveys[0].dateLimit).toEqual('2023-03-19 23:59');
  });
});
