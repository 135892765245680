import React from 'react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import { filter } from 'graphql-anywhere';
import { inject, observer } from 'mobx-react';
import { Loader } from 'semantic-ui-react';

import SurveyResultForm from '../SurveyResult/Form';

@inject('store')
@graphql(gql`query MessageSurveyResultsEntityQuery (
  $entityId: ID!,
  $messageId: ID!
) {
    node(id: $entityId) @connection(key: "Entity", filter: ["entityId"]) {
      ... on Entity {
        id: dbId
        message (id: $messageId ) {
          id: dbId
          surveys (limit: 40) {
            nodes {
              id: dbId
              ...SurveyResultForm
            }
          }
        }
      }
    }
  }
${SurveyResultForm.fragments.survey}
`, {
  options: ownProps => ({
    skip: !ownProps.params.entity_id || ownProps.fromHistory,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    variables: {
    entityId: ownProps.params.entity_id,
    messageId: ownProps.params.message_id
    }
    })
  })
@graphql(gql`query MessageSurveyResultsOrganizationQuery (
  $messageId: ID!,
) {
    node(id: $messageId) @connection(key: "Message", filter: ["messageId"]) {
      ... on Message {
        id: dbId
        surveys (limit: 40) {
          nodes {
            id: dbId
            ...SurveyResultForm
          }
        }
      }
    }
  }
${SurveyResultForm.fragments.survey}
`, {
  options: ownProps => ({
    skip: !ownProps.params.organization_id || ownProps.fromHistory,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    variables: {
    organizationId: ownProps.params.organization_id,
    messageId: ownProps.params.message_id
    }
    })
  })
@observer

export default class MessageSurveyResults extends React.Component {
  static fragments = {
    surveys: gql`
      fragment MessageSurveyResults on Message {
        surveys (limit: 40) {
          nodes {
            id: key
            ...SurveyResultForm
          }
        }
      }
      ${SurveyResultForm.fragments.survey}
      `,
    surveysHistory: gql`
      fragment MessageSurveyResultsHistory on Message {
        surveys (limit: 40) {
          nodes {
            id: key
            ...SurveyResultFormHistory
          }
        }
      }
      ${SurveyResultForm.fragments.surveyHistory}
      `,
  }

  state = {
    isLoaded: false
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!nextProps.fromHistory && !this.state.isLoaded && nextProps.data.node && !nextProps.data.loading) {
      this.setState({ isLoaded: true });
    }
  }

  render() {
    const { data, params, messageType, entity, fromHistory } = this.props;
    const { isLoaded } = this.state;

    if (!fromHistory && ((data.loading && !isLoaded) || !data.node)) { return <Loader active inline="centered" />; }

    const surveys = fromHistory ? this.props.surveys : (data.node.surveys || data.node.message.surveys);

    if (!surveys || !surveys.nodes.length) return null;

    return (
      <div id="MessageSurveyResults">
        {
          surveys.nodes.map(survey => (
            <SurveyResultForm
              key={survey.id}
              survey={filter(SurveyResultForm.fragments.survey, survey)}
              params={params}
              messageType={messageType}
              entity={entity}
              onSubmit={() => data.refetch()}
              fromHistory={this.props.fromHistory}
            />
          ))
        }
      </div>
    );
  }
}
