import React from 'react';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router';
import {
  Menu, Icon, Divider, Segment, Label, Grid, Dropdown, Popup, Header, Button, Image, Container
} from 'semantic-ui-react';
import * as utils from '../../../utils';
import Modal from '../../../components/Modal';
import { __ } from '../../../i18n';

export class MessageDetailsModal extends React.Component {
  /**
   * @param {{
   *  id: string,
   *  hasStatusModal: boolean,
   *  entityId: number | string,
   *  messageId: number | string,
   *  organizationId: number | string,
   *  onClose?: () => void,
   *  moreOptions?: [],
   * }} props
   */
  /* constructor(props) {
    super(props);
    this.state = {
      approveIsFocused: false,
      reproveIsFocused: false,
    };
  } */

  render() {
    const {
      id, onClose, hasStatusModal, hasMoreOptionsMenu, entityId, organizationId, messageId, moreOptions, store
    } = this.props;
    const { app } = store;
    const baseLink = entityId ? `/entities/${entityId}` : `/organizations/${organizationId}`;

    return (
      <Modal
        id={id}
        open
        size="small"
        closeIcon="times close inside"
        onClose={onClose}
        closeOnRootNodeClick={false}
        key={id}
      >
        <Modal.Header style={{ padding: '0.5em' }}>
          <Menu compact secondary>
            {
              hasStatusModal // (store.currentUser.isMaster || message.type === 'SENT' || params.organization_id || (type === 'ADMIN' && organizationId === message.entity.organizationId)) && !isDeletedForMe &&
              && (
                this.isMobile() ? <Menu.Item as={Link} to={baseLink + `/messages/${messageId}/entities`} icon><Icon data-name="status" name="info circle" /></Menu.Item>
                  : (
                    <Popup
                      trigger={
                        <Menu.Item as={Link} to={baseLink + `/messages/${messageId}/entities`} icon><Icon data-name="status" name="info circle" /></Menu.Item>
                    }
                      content={__('Status')}
                    />
                  )
              )
            }
            {
              hasMoreOptionsMenu// moreOptions.length > 0 && !isDeletedForMe
              && (this.isMobile()
                ? (
                  <Dropdown data-action="open-options" data-params="more" icon="ellipsis h" className="link item icon">
                    <Dropdown.Menu>
                      {moreOptions}
                    </Dropdown.Menu>
                  </Dropdown>
                )
                : (
                  <Popup
                    trigger={(
                      <Dropdown data-action="open-options" data-params="more" icon="ellipsis h" className="link item icon">
                        <Dropdown.Menu>
                          {moreOptions}
                        </Dropdown.Menu>
                      </Dropdown>
)}
                    content={isWriterAndApproved ? __('Approved messages can only be edited by an reviewer') : __('More')}
                  />
                ))
            }

          </Menu>
        </Modal.Header>
        <Modal.Content style={{ paddingBottom: 24 }}>
          <Segment.Group style={{ margin: 0 }}>
            <Segment secondary>
              <Grid>
                <Grid.Row columns={2}>
                  <Grid.Column width={13} style={{ display: 'flex', alignItems: 'center' }}>
                    {
                      message.type === 'SENT' && !params.organization_id
                        ? (
                          message.recipientsCount === 1
                            ? (
                              <span className="thirteen wide column" style={{ display: 'flex' }}>
                                {message.toEntity && <EntityItem to={entityLink(message.toEntity)} entity={filter(EntityItem.fragments.entity, message.toEntity)} fromMessage />}
                              </span>
                            )
                            : (
                              <div style={{
                                width: '40px', height: '40px', position: 'relative', marginBottom: 3
                              }}
                              >
                                <Avatar
                                  avatar
                                  src={message.toEntity && message.toEntity.picture && message.toEntity.picture.uri}
                                  alt={message.toEntity && message.toEntity.fullname}
                                  style={message.deleted ? (message.recipientsCount > 1 ? { width: '32px', height: '32px', opacity: 0.65 } : { width: '40px', height: '40px', opacity: 0.65 }) : (message.recipientsCount > 1 ? { width: '32px', height: '32px' } : { width: '40px', height: '40px' })}
                                />
                                {
                                message.recipientsCount > 1
                                  ? (
                                    <div style={styles.moreRecipients}>
                                      +
                                      {message.recipientsCount - 1}
                                    </div>
                                  )
                                  : null
                              }
                              </div>
                            )
                        )
                        : (
                          <EntityItem
                            to={(store.currentEntity.type === 'ADMIN' || (store.currentEntity.organization && store.currentEntity.organization.permissions && (store.currentEntity.organization.permissions.hasAdmin || (store.currentEntity.organization.permissions.entityScopes.includes('READ_ENTITY') && !!params.organization_id)))) && entityLink(message.entity)}
                            entity={filter(EntityItem.fragments.entity, message.entity)}
                            indicator="user"
                            fromMessage
                          />
                        )
                    }
                    <div style={{ display: 'inline-block', marginLeft: '10px' }}>
                      <div>
                        {
                          message.type === 'SENT' && !params.organization_id
                            ? (
                              message.recipientsCount === 1
                                ? (
                                  <span style={{ display: 'flex', alignItems: 'baseline' }}>
                                    <span>{__('to')}</span>
                                    {
                                    message.toEntity
                                    && (
                                      entityLink(message.toEntity)
                                        ? (
                                          <Link
                                            to={entityLink(message.toEntity)}
                                            style={{
                                              color: '#007fff', fontWeight: 500, fontSize: '16px', paddingLeft: '5px'
                                            }}
                                          >
                                            {message.toEntity.fullname}
                                          </Link>
                                        )
                                        : <span style={{ fontWeight: 500, fontSize: '16px', paddingLeft: '5px' }}>{message.toEntity.fullname}</span>
                                    )
                                  }
                                  </span>
                                )
                                : (
                                  <span style={{ display: 'flex', alignItems: 'baseline' }}>
                                    <span>{__('to')}</span>
                                    <Link
                                      to={baseLink + `/messages/${params.message_id}/entities`}
                                      style={{
                                        color: '#007fff', fontWeight: 500, fontSize: '16px', paddingLeft: '5px'
                                      }}
                                    >
                                      {message.recipientsCount.toString() + ' ' + __('recipients')}
                                    </Link>
                                  </span>
                                )
                            )
                            : (
                              <span style={{ display: 'flex', alignItems: 'baseline' }}>
                                <span>{__('from')}</span>
                                {
                                  entityLink(message && message.entity)
                                    ? <Link to={entityLink(message && message.entity)} style={{ color: '#007fff', fontSize: '16px', paddingLeft: '5px' }}>{message && message.entity && message.entity.fullname}</Link>
                                    : <span style={{ fontSize: '16px', paddingLeft: '5px' }}>{message && message.entity && message.entity.fullname}</span>
                                }
                                {
                                  (message.user && message.user.fullname && (
                                    message.user.tags && message.user.tags.nodes && message.user.tags.nodes.length
                                      ? (
                                        <Popup
                                          trigger={<Label basic size="tiny" className="uppercase" content={message.user.fullname} style={{ alignSelf: 'center', marginLeft: '8px', fontSize: '10px' }} />}
                                          content={message.user.tags.nodes.map(item => (
                                            <div style={{ display: 'block' }}>
                                              <span key={item.name} id={item.id} style={{ fontSize: '12px', fontWeight: 'medium' }}>
                                                {item.name}
                                              </span>
                                            </div>
                                          ))}
                                        />
                                      )
                                      : <Label basic size="tiny" className="uppercase" content={message.user.fullname} style={{ alignSelf: 'center', marginLeft: '8px', fontSize: '10px' }} />
                                  ))
                                }
                              </span>
                            )
                        }
                      </div>
                      <div style={{ whiteSpace: 'nowrap', color: '#9696a0', fontSize: '12px' }}>
                        <span>{utils.simpleDate(message.modified, true, 'LLL', app.locale)}</span>
                        {!this.isMobile() && this.renderMessageStatus(message)}
                      </div>

                      {this.isMobile() && (
                      <div style={{ whiteSpace: 'nowrap', color: '#9696a0', fontSize: '12px' }}>
                        {this.renderMessageStatus(message)}
                      </div>
                      )}
                    </div>
                  </Grid.Column>
                  <Grid.Column data-action="toggle-information" textAlign="right" width={3} onClick={() => this.setState({ information: !information })}>
                    {
                      (message.tags.nodes.length > 0)
                        || (message.entity.type === 'STUDENT' && message.entity.roles.nodes.length > 0)
                        ? <Icon data-name="tag-detail" name={information ? 'chevron up' : 'chevron down'} size="small" />
                        : null
                    }
                  </Grid.Column>
                </Grid.Row>
                {
                  information && message.tags.nodes.length > 0
                  && (
                  <Grid.Row>
                    <Grid.Column>
                      <Icon name="tag" />
                      {message.tags.nodes.map(tag => (
                        <Label data-name="label" data-value={tag.name} basic key={tag.id}>
                          {tag.name}
                        </Label>
                      ))}
                    </Grid.Column>
                  </Grid.Row>
                  )
                }
                {
                  information && message.entity.type === 'STUDENT' && message.entity.roles.nodes.length > 0
                  && (
                  <Grid.Row>
                    <Grid.Column>
                      <Icon name="graduation cap" />
                      {message.entity.roles.nodes.map((role, key) => (
                        <Label basic key={key}>
                          {(role.name.length > 36) ? (role.name.substring(0, 36) + '...') : role.name}
                        </Label>
                      ))}
                    </Grid.Column>
                  </Grid.Row>
                  )
                }
              </Grid>
              {
                isDeletedForMe
                && (
                <Grid style={{ background: '#00000029' }}>
                  <Grid.Row>
                    <Grid.Column>
                      {userHasEntity
                        ? (
                          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <span>
                              <Icon name="trash" style={{ margin: '0 0.25rem 0 0', fontWeight: '300' }} />
                              {__('Message deleted for you on %s', utils.simpleDate(message.statuses.nodes[0].created, false, 'lll', app.locale).toLowerCase())}
                            </span>
                            <button
                              style={{
                                border: 'none', background: 'transparent', color: '#084FFF', fontWeight: 'bold', cursor: 'pointer'
                              }}
                              onClick={() => this.recoverMessage()}
                            >
                              {__('Recover')}

                            </button>
                          </div>
                        )
                        : message.statuses.nodes.length === 1
                          ? (
                            <span>
                              <Icon name="trash" style={{ margin: '0 0.25rem 0 0', fontWeight: '300' }} />
                              {utils.parseFormattedTranslation(
                                {
                                  text: __(
                                    'Message deleted for **%s** on %s',
                                    message.statuses.nodes[0].user.fullname,
                                    utils.simpleDate(message.statuses.nodes[0].created, false, 'lll', app.locale).toLowerCase()
                                  ),
                                  Wrapper: ({ children }) => <span style={{ fontWeight: 'bold' }}>{utils.renderLongText(children, 13)}</span>
                                }
                              )}
                            </span>
                          )
                          : [
                            <Icon name="trash" style={{ margin: '0 0.25rem 0 0', fontWeight: '300' }} />,
                            utils.parseFormattedTranslation(
                              {
                                text: __('Message deleted for **%s users**', message.statuses.nodes.length),
                                Wrapper: ({ children }) => (
                                  <Popup
                                    basic
                                    className="deletedForUserList"
                                    trigger={(
                                      <span
                                        style={{
                                          fontWeight: 'bold',
                                          textDecoration: 'underline',
                                          cursor: 'pointer'
                                        }}
                                        className="lighten-on-hover"
                                      >
                                        {children}
                                      </span>
                                    )}
                                    on="click"
                                    header={(
                                      <div className="userListHeader">
                                        {__('Deleted for')}
                                      </div>
                                    )}
                                    content={(
                                      <ul className="userList">
                                        {message.statuses.nodes.map(({ created, user, id }) => (
                                          <li key={id}>
                                            <span>{utils.renderLongText(user.fullname, 13)}</span>
                                            {' '}
                                            <time>
                                              {__('on %s', utils.simpleDate(created, false, 'lll', app.locale).toLowerCase())}
                                              {' '}
                                            </time>
                                          </li>
                                        ))}
                                      </ul>
                                    )}
                                    position="bottom left"
                                    wide="very"
                                  />
                                )
                              }
                            )]}
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
                )
              }
            </Segment>

            {
              hasMessageApproval && messageReview
              && (
              <ReviewStatusTooltip
                status={messageReview?.reviewStatus === 'approved' ? 'approved' : 'inReview'}
                entityFullname={messageReview?.user && messageReview?.user?.fullname}
                date={utils.simpleDate(messageReview?.reviewIn, true, 'DD MMM, HH:mm', app.locale)}
              />
              )
            }

            <Segment className="wordWrapped">
              {
                (!!message.subject || !!message.label)
                && (
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  {message.subject ? <Header>{message.subject}</Header> : <div />}
                  {
                    !!message.label && (
                    <div style={{ marginLeft: 8 }}>
                      <Label data-name="label" size="tiny" style={{ color: 'white', backgroundColor: `#${message.label.color}`, wordBreak: 'initial' }}>{message.label.title}</Label>
                    </div>
                    )
                  }
                </div>
                )
              }
              {!!message.rendered && (
              <div
                className="content clsp-content ql-editor"
                dangerouslySetInnerHTML={{
                  __html: message.rendered
                }}
              />
              )}
              <Divider hidden />
              {
                message.fwMessage
                && (
                <div style={{ marginBottom: '1em' }}>
                  <Menu.Item data-action="open-forwarded-message" as={Link} to={baseLink + `/messages/${message.fwMessage.id}?forwarded=true`}>
                    <Segment>
                      <MessageItem message={filter(MessageItem.fragments.entityMessage, message.fwMessage)} />
                    </Segment>
                  </Menu.Item>
                </div>
                )
              }
              <MessageMedias images={message.images} videos={message.videos} audios={message.audios} />
              <MessageFiles files={message.files} />
              <MessageCommitmentReminds params={params} messageType={message.type} entity={{ disabled }} />
              <MessageSurveyResults params={params} messageType={message.type} entity={{ disabled }} />
              <MessageReportResults params={params} messageType={message.type} />
              <MessageCharges charges={message.charges} params={params} reload={() => this.reload()} messageType={message.type} entity={{ disabled }} />
              <MessageFormResults params={params} messageType={message.type} entity={{ disabled }} />
              <MessageSignatures signatures={message.signatures} params={params} messageType={message.type} entity={{ disabled }} updateQuery={data.refetch} />
            </Segment>
          </Segment.Group>
          <MessageLogs logs={message.logs} />
          {location.query.type !== 'messagesToApprove'
            && <MessageReplies params={{ entity_id: message.entity.id, ...this.props.params }} forwarded={location.query.forwarded} location={location} setWritingReply={value => (this.state.writingReply !== value ? this.setState({ writingReply: value }) : null)} />}
          {this.props.children && React.cloneElement(
            this.props.children,
            {
              super: this.props.super
            }
          )}
          {!this.isMobile() && <ElementModifier element={document.body} newClassName="paddingOnModalDimmer" />}
        </Modal.Content>
      </Modal>
    );
  }
}
