/**
 * @param {{
 *  shouldShowButton: boolean
 * }}
 */
export function getAttachmentButtonStyles({ shouldShowButton = false }) {
  let styles = {
    button: {},
    icon: {},
  };

  if (shouldShowButton) {
    styles = {
      button: {
        margin: '0px 4px',
        borderRadius: '0.5rem',
        padding: '0.5rem',
        minHeight: '33px',
      },
      icon: {
        fontSize: '0.875rem',
      },
    };
  }

  return styles;
}
