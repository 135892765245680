import React from 'react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import { filter } from 'graphql-anywhere';
import { inject, observer } from 'mobx-react';
import { Loader } from 'semantic-ui-react';

import ReportResultItem from '../ReportResult/Item';

@inject('store')
@graphql(gql`query MessageReportsEntityQuery (
  $entityId: ID!,
  $messageId: ID!
) {
    node(id: $entityId) @connection(key: "Entity", filter: ["entityId"]) {
      ... on Entity {
        id: dbId
        message (id: $messageId ) {
          id: dbId
          reports (limit: 40) {
            nodes {
              id: dbId
              ...ReportResultItem
            }
          }
        }
      }
    }
  }
${ReportResultItem.fragments.report}
`, {
  options: ownProps => ({
    skip: !ownProps.params.entity_id || ownProps.fromHistory,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    variables: {
    entityId: ownProps.params.entity_id,
    messageId: ownProps.params.message_id
    }
    })
  })
@graphql(gql`query MessageReportsOrganizationQuery (
  $messageId: ID!,
) {
    node(id: $messageId) @connection(key: "Message", filter: ["messageId"]) {
      ... on Message {
        id: dbId
        reports (limit: 40) {
          nodes {
            id: dbId
            ...ReportResultItem
          }
        }
      }
    }
  }
${ReportResultItem.fragments.report}
`, {
  options: ownProps => ({
    skip: !ownProps.params.organization_id || ownProps.fromHistory,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    variables: {
    organizationId: ownProps.params.organization_id,
    messageId: ownProps.params.message_id
    }
    })
  })
@observer
export default class MessageReportResults extends React.Component {
  static fragments = {
    reports: gql`
      fragment MessageReportResults on Message {
        reports (limit: 40) {
          nodes {
            id: dbId
            ...ReportResultItem
          }
        }
      }
      ${ReportResultItem.fragments.report}
      `,
  }

  state = {
    isLoaded: false
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!nextProps.fromHistory && !this.state.isLoaded && nextProps.data.node && !nextProps.data.loading) {
      this.setState({ isLoaded: true });
    }
  }

  render() {
    const { data, params, messageType, fromHistory } = this.props;
    const { isLoaded } = this.state;

    if (!fromHistory && ((data.loading && !isLoaded) || !data.node)) { return <Loader active inline="centered" />; }

    const reports = fromHistory ? this.props.reports : (data.node.reports || data.node.message.reports);

    if (!reports || !reports.nodes.length) return null;

    return (
      <div id="MessageReportResults">
        {
          reports.nodes.map(report =>
            <ReportResultItem
              key={report.id}
              report={report}
              params={params}
              messageType={messageType}
            />
          )
        }
        {this.props.children}
      </div>
    );
  }
}
