import moment from 'moment';
/**
 * Get the forms from helpers and validate it
 * @param {string} helpers
* @returns {string}
*/
export const getFormsFromHelpers = (helpers) => {
  if (!helpers) return [];

  try {
    const parsedHelpers = JSON.parse(helpers);
    const forms = parsedHelpers?.forms;

    if (forms) {
      return forms instanceof Array ? forms.map(item => ({ ...item, dateLimit: item.dateLimit ? moment(item.dateLimit).format('YYYY-MM-DD 23:59:59') : null })) : [];
    }

    return [];
  } catch {
    return [];
  }
};
