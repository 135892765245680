import React from 'react';
import { Icon } from 'semantic-ui-react';

import { __ } from '../../../../i18n';

class MessageStatus extends React.Component {
  render() {
    const { message, isMobile } = this.props;

    if (!message) {
      return null;
    }

    return (
      <span>
        {
            message && message.deleted
              ? (
                <span>
                  {!isMobile ? ' . ' : ''}
                  {' '}
                  {__('deleted')}
                </span>
              )
              : (
                message && message.logs && message.logs.totalCount > 0
                  ? (
                    <span>
                      {!isMobile ? ' . ' : ''}
                      {' '}
                      {__('edited')}
                    </span>
                  )
                  : ''
              )
          }
        {
              message.toEntity && message.toEntity.status > 0 && message.toEntity.status < 1
                ? <Icon data-testid="message-status-icon-black" color="black" name="check double" style={{ marginLeft: '8px', marginRight: '8px' }} />
                : ''
            }
        {
              message.toEntity && message.toEntity.status > 1
                ? <Icon data-testid="message-status-icon-blue" name="check double" style={{ marginRight: '8px', marginLeft: '8px', color: '#0080FF' }} />
                : ''
            }
      </span>
    );
  }
}

export default MessageStatus;
