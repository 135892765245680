import React from 'react';
import { inject, observer } from 'mobx-react';
import { Dropdown, Label } from 'semantic-ui-react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import { filter } from 'graphql-anywhere';
import { cloneDeep, uniqBy } from 'lodash';
import { execute, makePromise } from 'apollo-link';
import '../../assets/css/ui/Recipients.module.scss';

import Avatar from '../../components/Avatar';
import Loading from '../../components/ui/Loading';
import RecipientsModal from '../../components/ui/RecipientsModal';

import EntityItem from '../Entity/Item';

import { __ } from '../../i18n';
import * as utils from '../../utils';

const entityRecipientsQuery = gql`
  query EntityGroupRecipientsQuery($entityId: ID!, $search: String ) {
  node (id: $entityId) {
    ... on Entity {
      id: dbId
      groups (search: $search) {
        nodes {
          name
          id: dbId
          expanded
          loaded
          recipientsCount
          key
          organizationId
          internal
          seeAll
          loading
          entities (limit: 0) {
            nodes {
              id: dbId
              selected
              ... EntityItemRecipient
            }
          }
        }
      }
      recipients (search: $search) {
        nodes {
          id: dbId
          ... EntityItemRecipient
        }
      }
    }
  }
}
${EntityItem.fragments.recipient}`;

const entityGroupRecipientsQuery = gql`
  query EntityGroupRecipientsQuery($entityId: ID!, $groupId: ID!, $selected: Boolean ) {
  node (id: $entityId) {
    ... on Entity {
      id: dbId
      recipients: groupRecipients (groupId: $groupId, limit: 1400) {
        nodes {
          id: dbId
          selected (selected: $selected)
          ... EntityItemRecipient
        }
      }
    }
  }
}
${EntityItem.fragments.recipient}`;

const entityStaffRecipientsQuery = gql`
  query EntityStaffRecipientsQuery($entityId: ID!, $selected: Boolean ) {
  node (id: $entityId) {
    ... on Entity {
      id: dbId
      recipients: staffRecipients (limit: 800) {
        nodes {
          id: dbId
          selected (selected: $selected)
          ... EntityItemRecipient
        }
      }
    }
  }
}
${EntityItem.fragments.recipient}`;

@inject('store', 'client')
@graphql(gql`query MessageRecipientsQuery ($id: ID!, $limit: Int) {
    node(id: $id) @connection(key: "Entity", filter: ["id"]) {
      ... on Entity {
        id: dbId
        selected
        groups (limit: $limit) {
          nodes {
            name
            id: dbId
            selected
            expanded
            loaded
            recipientsCount
            key
            organizationId
            internal
            seeAll
            loading
            entities (limit: 0) {
              nodes {
                id: dbId
                selected
                ... EntityItemRecipient
              }
            }
          }
        }
        recipients (limit: 0) {
          nodes {
            id: dbId
            selected
            ... EntityItemRecipient
          }
        }
      }
    }
  }
${EntityItem.fragments.recipient}
`, {
  options: ownProps => ({
    fetchPolicy: 'network-only',
    variables: {
    id: ownProps.entity.id,
    limit: ownProps.entity.groups.totalCount
    }
    })
  })
@observer
export default class MessageRecipients extends React.Component {
  state = {
    text: '',
    isLoading: false,
    open: false,
    loading: false,
    fetching: false,
    recipientsModal: false
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!this.loaded && nextProps.data && nextProps.data.node && !nextProps.data.loading) {
      this.loaded = true;
      this.originalData = cloneDeep(nextProps.data.node);

      this.addStaffItemAndDraftRecipients(nextProps);
    }
  }

  componentDidUpdate() {
    const { data } = this.props;
    if (data.node && !data.loading) {
      // Enable add "+" recipients button
      if (this.props.enableAddRecipients) this.props.enableAddRecipients();
    }
  }

  componentWillUnmount() {
    this.props.data.updateQuery((previousResult) => {
      const nextResult = cloneDeep(previousResult);

      nextResult.node.selected = false;

      nextResult.node.groups.nodes.forEach((group) => {
        group.selected = false;

        group.entities.nodes = group.entities.nodes.map((e) => {
          e.selected = false;
          return e;
        });
      });

      nextResult.node.recipients.nodes.forEach((e) => {
        e.selected = false;
        return e;
      });

      return nextResult;
    });
  }

  // eslint-disable-next-line react/sort-comp
  toggleRecipientsModal = () => {
    this.setState((previousState => ({ recipientsModal: !previousState.recipientsModal })));
  }

  // eslint-disable-next-line react/sort-comp
  removeGroup = async (value) => {
    const entitiesId = value.entities.nodes.map(e => e.id);
    const { data } = this.props;
    let changed = null;
    await data.updateQuery((previousResult) => {
      const nextResult = cloneDeep(previousResult);

      nextResult.node.groups.nodes = nextResult.node.groups.nodes.map((group, i) => {
        if (value.id === group.id) {
          group.selected = false;
          changed = { index: i, value: group };
        }
        return group;
      });

      nextResult.node.recipients.nodes = changed ? nextResult.node.recipients.nodes : nextResult.node.recipients.nodes.map((recipient, i) => {
        if (entitiesId.includes(recipient.id)) {
          recipient.selected = false;
          changed = { index: i, value: recipient };
        }
        return recipient;
      });

      nextResult.node.selected = false;
      return nextResult;
    });

    this.toggleGroup(changed);
    this.props.handleSelectedItems(this.props.data.node);
  }

  onSelectionChange = async (e, { value }) => {
    const { data } = this.props;
    let changed = null;

    if (value.indexOf('fetching') > -1) return; // Cannot choose the fetching label option

    this.dropdown.clearSearchQuery();

    await data.updateQuery((previousResult) => {
      const nextResult = cloneDeep(previousResult);

      nextResult.node.groups.nodes = nextResult.node.groups.nodes.map((group, i) => {
        if (group.selected && value.indexOf(`g ${group.id}`) === -1) {
          group.selected = false;
          changed = { index: i, value: group };
        } else if (!group.selected && value.indexOf(`g ${group.id}`) > -1) {
          group.selected = true;
          changed = { index: i, value: group };
        }

        return group;
      });

      nextResult.node.recipients.nodes = changed ? nextResult.node.recipients.nodes : nextResult.node.recipients.nodes.map((recipient, i) => {
        if (recipient.selected && value.indexOf(`e ${recipient.id}`) === -1) {
          recipient.selected = false;
          changed = { index: i, value: recipient };
        } else if (!recipient.selected && value.indexOf(`e ${recipient.id}`) > -1) {
          recipient.selected = true;
          changed = { index: i, value: recipient };
        }

        return recipient;
      });

      nextResult.node.selected = false;

      this.setState({ text: '', open: false });
      return nextResult;
    });

    if (changed.value.__typename === 'Group') {
      await this.toggleGroup(changed);
    } else if (!changed.value.selected) {
      await this.toggleEntity(changed);
    }

    this.props.handleSelectedItems(this.props.data.node);
    this.dropdown.clearSearchQuery();
  }

  onSearchChange = async (e, value) => {
    if (!value.searchQuery) {
      this.setState({ text: value.searchQuery, open: false });
      return;
    }

    if (value.searchQuery.length > 0) {
      this.setState({ open: true, fetching: true, text: value.searchQuery });
    }

    const { client, entity, data } = this.props;
    const response = await makePromise(execute(client.link, {
      query: entityRecipientsQuery,
      variables: {
        entityId: entity.id,
        search: value.searchQuery
      },
    }));

    data.updateQuery((previousResult) => {
      const nextResult = cloneDeep(previousResult);

      nextResult.node.recipients.nodes = uniqBy([
        ...nextResult.node.recipients.nodes,
        ...response.data.node.recipients.nodes.map((node) => {
          const newNode = cloneDeep(node);
          newNode.selected = null;

          if (newNode.organization) newNode.organization.__typename = 'Organization';
          if (newNode.picture) newNode.picture.__typename = 'Picture';
          newNode.__typename = 'Entity';

          return newNode;
        })
      ], 'id');

      nextResult.node.groups.nodes = uniqBy([
        ...nextResult.node.groups.nodes,
        ...response.data.node.groups.nodes.map((node) => {
          const newNode = cloneDeep(node);
          newNode.selected = null;
          newNode.__typename = 'Group';
          newNode.entities.__typename = 'GroupEntitiesConnection';

          return newNode;
        })
      ], 'id');

      return nextResult;
    });

    this.setState({ fetching: false });
  }

  addStaffItemAndDraftRecipients = (props) => {
    const { data, store } = props;

    data.updateQuery((previousResult) => {
      const nextResult = cloneDeep(previousResult);

      if (props.recipients) {
        const { selected, recipients } = props.recipients;
        const groupsIds = data.node.groups.nodes.map(group => group.id);
        const groups = props.recipients.groups.nodes ? props.recipients.groups.nodes.filter(group => (groupsIds.indexOf(group.id) > -1) || group.id === 'staff') : [];

        nextResult.node.selected = selected;

        nextResult.node.groups.nodes = uniqBy([
          ...groups,
          ...nextResult.node.groups.nodes
        ], 'id');

        nextResult.node.recipients.nodes = uniqBy([
          ...recipients.nodes,
          ...nextResult.node.recipients.nodes
        ], 'id');
      }
      if (store.currentEntity.organization && store.currentEntity.organization.confAdminList &&
        (!nextResult.node.groups.nodes.length || nextResult.node.groups.nodes[0].id !== 'staff')) {
        nextResult.node.groups.nodes.unshift({
          ...nextResult.node.groups.nodes[0],
          id: 'staff',
          name: __('Staff'),
          entities: {
            nodes: [],
            __typename: 'GroupEntitiesConnection'
          },
          expanded: false,
          loaded: false,
          selected: false,
          key: 'staff',
          internal: true,
          seeAll: false,
          loading: null,
          organizationId: store.currentEntity.organization.id,
          recipientsCount: null,
          __typename: 'Group'
        });
      }

      if (this.props.defaultSelectedAll) {
        nextResult.node.selected = !nextResult.node.selected;

        nextResult.node.groups.nodes.forEach((group) => {
          group.selected = nextResult.node.selected;
        });

        nextResult.node.recipients.nodes.forEach((entity) => {
          entity.selected = nextResult.node.selected;
        });

        this.props.handleSelectedItems(nextResult.node);
      }

      return nextResult;
    });
  }

  toggleEntity = (entity) => {
    this.setState({ loading: true });

    const { data } = this.props;

    data.updateQuery((previousResult) => {
      const nextResult = cloneDeep(previousResult);
      // Find groups that contain entity, remove selected
      const entityIdsInSelectedGroups = [];

      nextResult.node.groups.nodes = nextResult.node.groups.nodes.map((group) => {
        if (group.entities.nodes
          .findIndex(e => e.id === entity.value.id) > -1) {
          if (group.selected) {
            group.selected = false;

            group.entities.nodes.forEach((e) => {
              entityIdsInSelectedGroups.push(e.id);
            });
          }
        }

        return group;
      });

      nextResult.node.recipients.nodes = nextResult.node.recipients.nodes.map((e) => {
        if (e.id === entity.value.id) {
          e.selected = false;
        } else if (entityIdsInSelectedGroups.includes(e.id)) {
          e.selected = true;
        }

        return e;
      });

      return nextResult;
    });

    this.setState({ loading: false });
  }

  toggleGroup = async (group) => {
    this.setState({ loading: true });

    const { data, entity, client } = this.props;
    let response;

    if (!group.value.loaded) {
      response = await client.query({
        query: group.value.id === 'staff' ? entityStaffRecipientsQuery : entityGroupRecipientsQuery,
        variables: {
          entityId: entity.id,
          groupId: group.value.id,
          selected: true
        },
      });
    }

    data.updateQuery((previousResult) => {
      const nextResult = cloneDeep(previousResult);
      const node = nextResult.node.groups.nodes[group.index];

      nextResult.node.selected = false;

      if (response) {
        node.loading = false;
        node.loaded = true;

        node.entities = response.data.node.recipients;
        nextResult.node.recipients.nodes = cloneDeep(uniqBy([
          ...nextResult.node.recipients.nodes,
          ...response.data.node.recipients.nodes
        ], 'id'));
      } else if (!node.selected) {
        // Don't unselect from other groups if group is selected
        const selectedEntityIdsInOtherGroups = [];

        nextResult.node.groups.nodes
          .filter(g => g.selected).forEach((g) => {
            g.entities.nodes.filter(e => e.selected).forEach((e) => {
              selectedEntityIdsInOtherGroups.push(e.id);
            });
          });

        node.entities.nodes = node.entities.nodes.map((e) => {
          if (!selectedEntityIdsInOtherGroups.includes(e.id)) {
            e.selected = false;
          }

          return e;
        });
      } else {
        // Don't unselect from other groups if group is selected
        const selectedEntityIdsInOtherGroups = [];

        nextResult.node.groups.nodes
          .filter(g => g.selected).forEach((g) => {
            g.entities.nodes.filter(e => e.selected).forEach((e) => {
              selectedEntityIdsInOtherGroups.push(e.id);
            });
          });

        node.entities.nodes = node.entities.nodes.map((e) => {
          e.selected = true;
          return e;
        });
      }

      nextResult.node.recipients.nodes = uniqBy([...node.entities.nodes, ...nextResult.node.recipients.nodes], 'id');
      return nextResult;
    });

    this.setState({ loading: false });
  }

  searchFilter = (options, target) => {
    const newTarget = utils.normalize(target).toLowerCase();
    const newOptions = options.filter(option => option.value === 'fetching' || utils.normalize(option.text).toLowerCase().indexOf(newTarget) !== -1);

    return newOptions;
  }

  renderGroupLabel = label => ({
    className: `image${this.props.round ? ' round' : ''}${this.props.roundCorners ? ' round-corners' : ''}`,
    size: 'tiny',
    style: this.props.round ? {} : { backgroundColor: '#fff' },
    content: label.text.length > 24 ? (label.text.substring(0, 21) + '...') : label.text,
    icon: 'users'
  })

  renderEntityLabel = (label, item) => ({
    className: `image${this.props.round ? ' round' : ''}${this.props.roundCorners ? ' round-corners' : ''}`,
    size: 'tiny',
    style: this.props.round ? {} : { backgroundColor: '#fff' },
    content: label.text.length > 24 ? (label.text.substring(0, 21) + '...') : label.text,
    image: <Avatar avatar src={item.picture && item.picture.uri} alt={item.fullname} />
  })

  renderLabel = (label, i, groupsCount) => {
    if (this.props.groupsOnly) {
      if (!label.group) return null;

      if (this.state.open || i < 2) {
        return this.renderGroupLabel(label);
      } else if (i === 2) {
        return (<Label
          className={`image${this.props.round ? ' round' : ''}${this.props.roundCorners ? ' round-corners' : ''}`}
          size="tiny"
          style={this.props.round ? {} : { backgroundColor: '#fff' }}
          onClick={() => this.setState({ open: true })}
        >
          + {groupsCount - 2}
        </Label>);
      }

      return null;
    }

    const item = label.recipient || label.group;

    if (this.state.open || i < 2) {
      return label.recipient ? this.renderEntityLabel(label, item) : this.renderGroupLabel(label);
    } else if (i === 2) {
      return (<Label
        className={`image${this.props.round ? ' round no-close-button' : ''}`}
        size="tiny"
        style={this.props.round ? {} : { backgroundColor: '#fff', fontWeight: 'bold', padding: '.5833em .833em .5833em .833em' }}
        onClick={() => this.setState({ open: true })}
      >
        {__('See all')}
      </Label>);
    }

    return null;
  }

  renderFetchingLabel = () => ({
    text: __('Searching for recipients'),
    value: 'fetching',
    icon: { name: 'circle notch', loading: true }
  })

  render() {
    const { data, groupsOnly, hideSearch, hidePlaceholder, wrapType, roundCorners } = this.props;
    const { text, fetching, open, recipientsModal } = this.state;

    if ((data.loading && !data.node) || !data.node) {
      return (
        hideSearch ?
          <div style={{ display: 'flex', alignItems: 'center', marginTop: groupsOnly ? '10px' : '0px' }}>
            <Loading width={20} height={20} style={{ justifyContent: 'flex-start', margin: '0px 10px 0px 10px', width: 'unset' }} />
            <span style={{ color: 'rgba(191,191,191,.87)', fontSize: '16px' }}>{hidePlaceholder ? null : __('Recipients')}</span>
          </div>
          :
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Loading width={20} height={20} style={{ justifyContent: 'flex-start', margin: '0px 10px 0px 10px', width: 'unset' }} />
            <span style={{ color: 'rgba(191,191,191,.87)', fontSize: '16px' }}>{this.props.placeholder}</span>
          </div>
      );
    }

    const { groups, recipients } = data.node;
    const selected = groups.nodes.filter(group => group.selected).map(group => `g ${group.id}`)
      .concat(recipients.nodes.filter(recipient => recipient.selected).map(recipient => `e ${recipient.id}`));
    let options = groups.nodes.map(group => ({
      text: `${(group.name.length > 35) ? (group.name.substring(0, 35) + '...') : group.name}`,
      value: `g ${group.id}`,
      icon: 'users',
      id: group.id,
      group
    })).concat(groupsOnly ? [] : recipients.nodes.map(recipient => ({
      text: recipient.fullname,
      value: `e ${recipient.id}`,
      content: <EntityItem id={`optionEntity${recipient.id}`} wrapType={wrapType} indicator="typeLabel" entity={filter(EntityItem.fragments.recipient, recipient)} />,
      recipient
    })));
    const selectedGroups = groups.nodes.filter(group => group.selected);
    const groupsCount = selectedGroups.length;
    const entitiesCount = recipients.nodes.filter(recipient => recipient.selected).length;

    if (fetching) options = options.concat([this.renderFetchingLabel()]);

    return (
      <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
        {
          !hideSearch &&
          <Dropdown
            ref={(c) => { this.dropdown = c; }}
            fluid multiple scrolling selection
            selectOnBlur={false} icon={fetching ? undefined : null} minCharacters={0} noResultsMessage={__('No results were found')}
            value={selected}
            options={options}
            renderLabel={(label, i) => this.renderLabel(label, i, groupsCount)}
            onChange={this.onSelectionChange}
            onSearchChange={this.onSearchChange}
            text={text}
            open={this.state.open}
            openOnFocus={false}
            search={this.searchFilter}
            loading={fetching}
            className={`${roundCorners ? 'round-corners' : ''}`}
            onBlur={() => this.setState({ text: '', open: false })}
            style={{ paddingRight: 0, ...this.props.style }}
            placeholder={this.props.placeholder}
          />
        }

        {
          !fetching && !groupsOnly && !open && (groupsCount || entitiesCount) ?
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                whiteSpace: 'nowrap',
                color: hideSearch ? '#084FFF' : '#868E96',
                fontSize: '16px'
              }}
            >
              {groupsCount > 0 && (groupsCount === 1 ? __('%s group', 1) : __('%s groups', groupsCount))}
              {groupsCount > 0 && entitiesCount > 0 && ', '}
              {entitiesCount > 0 && (entitiesCount === 1 ? __('%s recipient', 1) : __('%s recipients', entitiesCount))}
            </div>
            :
            hideSearch ?
              <div>
                <span style={{ color: 'rgba(0, 0, 0, 0.4)', fontSize: '16px' }}>{hidePlaceholder ? null : __('Recipients')}</span>
              </div>
              :
              null
        }
        {
          hideSearch && groupsOnly &&
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {groupsCount > 0 &&
              <Label
                className={'image round align-icon'}
                size="tiny"
                style={{ border: '1px solid #D6D6D6', display: 'flex', alignItems: 'center', fontSize: '16px', fontWeight: 400, marginRight: '5px', marginTop: '12px' }}
                content={selectedGroups[0].name.length > 24 ? (selectedGroups[0].name.substring(0, 21) + '...') : selectedGroups[0].name}
                removeIcon="delete"
                onRemove={() => this.removeGroup(selectedGroups[0])}
              />
            }
            {groupsCount > 1 &&
              <Label
                className={'image round align-icon'}
                size="tiny"
                onClick={() => this.toggleRecipientsModal()}
                style={{ border: '1px solid #D6D6D6', display: 'flex', alignItems: 'center', fontSize: '16px', fontWeight: 400, minWidth: '60px', justifyContent: 'center', marginTop: '12px' }}
                content={<span>{`+${groupsCount - 1}`}</span>}
              />
            }
          </div>
        }
        {
          recipientsModal &&
          <RecipientsModal groups selectedEntities={selectedGroups} onClose={() => this.toggleRecipientsModal()} onRemove={g => this.removeGroup(g)} />
        }
      </div>
    );
  }
}
