import onClose from '../onClose';

describe('onClose', () => {
  it('should return a function', () => {
    expect(typeof onClose).toBe('function');
  });

  it('should be defined', () => {
    expect(onClose).toBeDefined();
  });

  it('should call close action if writingReply is false', async () => {
    const mocks = {
      store: {
        appends: [],
      },
      closeAction: jest.fn(),
    };
    onClose(false, mocks.store, mocks.closeAction);
    await new Promise((resolve) => setTimeout(resolve, 0));
    expect(mocks.closeAction).toHaveBeenCalled();
  });

  it('should not call close action if writingReply is true', async () => {
    const mocks = {
      store: {
        appends: [],
      },
      closeAction: jest.fn(),
    };
    onClose(true, mocks.store, mocks.closeAction);
    await new Promise((resolve) => setTimeout(resolve, 0));
    expect(mocks.closeAction).not.toHaveBeenCalled();
  });
});
