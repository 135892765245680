import React from 'react';
import { inject, observer } from 'mobx-react';
import { Container, Icon } from 'semantic-ui-react';
import gql from 'graphql-tag';

import { __ } from '../../i18n';
import * as utils from '../../utils';
import { getOnReference } from './helpers/getOnReference';

const styles = {
  item: {
    marginTop: 8,
    marginBottom: 8,
    display: 'flex'
  },
  bold: {
    fontWeight: 'bold'
  },
  logText: {
    color: '#0a1e3c'
  },
  timestamp: {
    color: '#9696a0'
  },
  icon: {
    color: '#9696a0',
    alignSelf: 'flex-start',
    marginRight: '8px'
  }
};

const fieldNames = {
  date_limit: __('Deadline'),
  date: __('Date'),
  time: __('Time'),
  name: __('Name'),
  option: __('Option')
};

const onReference = getOnReference();

@inject('store') @observer
export default class MessageLogs extends React.Component {
  static fragments = {
    logs: gql`
      fragment MessageLogs on Message {
        logs (limit: 40, orderBy: { column: ID, direction: DESC }) {
          nodes {
            id: dbId
            name
            reference
            event
            field
            currentValue
            previousValue
            created
            version
            referenceId
          }
          totalCount
        }
      }
      `,
  }

  handleFieldName = (log) => {
    let fieldName;

    switch (log.field) {
      case 'name':
        fieldName = __('Name');
        break;
      case 'date':
        fieldName = __('Date');
        break;
      case 'date_limit':
        fieldName = __('Date limit');
        break;
      default:
        fieldName = log.field;
        break;
    }

    return fieldName;
  }

  formatDate = (date, lang, format = 'L') => {
    const d = new Date(date.replace(/-/g, '/'));
    const tzDate = date.length === 10 ? utils.adjustTZDate(d, -d.getTimezoneOffset()) : d;

    return utils.simpleDate(tzDate, true, format, lang);
  }

  handleEvent = (log, lang) => {
    const { reference, event, field, name, currentValue, previousValue, created } = log;

    let formattedPreviousValue = __('No previous value');
    let formattedCurrentValue = currentValue;

    if (['date_limit', 'date'].includes(field)) {
      let format = 'L';

      if (field === 'date_limit') format = 'L, HH:mm';
      if (previousValue) formattedPreviousValue = this.formatDate(previousValue, lang, format);

      formattedCurrentValue = this.formatDate(currentValue, lang, format);
    } else if (field === 'time' && previousValue) {
      formattedPreviousValue = previousValue.substring(0, 5);
    } else if (previousValue) {
      formattedPreviousValue = previousValue;
    }

    let content = null;

    switch (reference) {
      case 'COMMITMENT':
        content = (
          <div style={{ ...styles.fill, wordBreak: 'break-all' }}>
            <div>
              {(event === 'UPDATED') ?
                (
                  field === 'date_limit' || field === 'date' ?
                    <span style={styles.logspan}>
                      <span>{fieldNames[field]}</span>
                      {onReference[reference].middle}
                      <span style={styles.bold}>{name}</span>
                      {__(' was changed from(commitment_date) ')}
                      <span style={styles.bold}>{formattedPreviousValue}</span>
                      {__(' to ')}
                      <span style={styles.bold}>{formattedCurrentValue}</span>
                    </span>
                    :
                    (
                      field === 'videocall_link' || field === 'videocall_type' ?
                        field === 'videocall_link' && formattedCurrentValue ?
                          <span style={styles.logspan}>
                            <span>{fieldNames[field]}</span>
                            {onReference[reference].start}
                            <span style={styles.bold}>{name}</span>
                            {__(' the video conference changed to ')}
                            <span style={styles.bold}>{formattedCurrentValue}</span>
                          </span>
                        :
                        (
                          field === 'videocall_link' && !formattedCurrentValue ?
                            <span style={styles.logspan}>
                              <span>{fieldNames[field]}</span>
                              {onReference[reference].start}
                              <span style={styles.bold}>{name}</span>
                              {__(' the video conference has canceled ')}
                              <span style={styles.bold}>{formattedCurrentValue}</span>
                            </span>
                          :
                          (
                            []
                          )
                        )
                      :
                      (
                        <span style={styles.logspan}>
                          <span>{fieldNames[field]}</span>
                          {onReference[reference].middle}
                          <span style={styles.bold}>{name}</span>
                          {__(' was changed from(commitment) ')}
                          <span style={styles.bold}>{formattedPreviousValue}</span>
                          {__(' to ')}
                          <span style={styles.bold}>{formattedCurrentValue}</span>
                        </span>
                      )
                    )
                )
                :
                (
                  event === 'CREATED' ?
                    (
                      <span style={styles.logspan}>
                        {onReference[reference].start}
                        <span style={styles.bold}>{name}</span>
                        {__(' was created(commitment)')}
                      </span>
                    )
                    :
                    (
                      <span style={styles.logspan}>
                        {onReference[reference].start}
                        <span style={styles.bold}>{name}</span>
                        {__(' was canceled(commitment)')}
                      </span>
                    )
                )
              }
            </div>
            {
              field != 'videocall_type' ?
                <span style={styles.timestamp}>{utils.simpleDate(created, true, null, lang)}</span> : ([])
            }
          </div>
        );
        break;
      case 'SURVEY':
        content = (
          <div style={styles.fill}>
            <div>
              {(event === 'UPDATED') ?
                (
                  field === 'name' ?
                    <span style={styles.logspan}>
                      <span>{fieldNames[field]}</span>
                      {onReference[reference].middle}
                      <span style={styles.bold}>{name}</span>
                      {__(' was changed from(survey_name) ')}
                      <span style={styles.bold}>{formattedPreviousValue}</span>
                      {__(' to ')}
                      <span style={styles.bold}>{formattedCurrentValue}</span>
                    </span>
                    :
                    <span style={styles.logspan}>
                      <span>{fieldNames[field]}</span>
                      {onReference[reference].middle}
                      <span style={styles.bold}>{name}</span>
                      {__(' was changed from(survey) ')}
                      <span style={styles.bold}>{formattedPreviousValue}</span>
                      {__(' to ')}
                      <span style={styles.bold}>{formattedCurrentValue}</span>
                    </span>
                )
                :
                (
                  event === 'CREATED' ?
                    (
                      <span style={styles.logspan}>
                        {onReference[reference].start}
                        <span style={styles.bold}>{name}</span>
                        {__(' was created(survey)')}
                      </span>
                    )
                    :
                    (
                      <span style={styles.logspan}>
                        {onReference[reference].start}
                        <span style={styles.bold}>{name}</span>
                        {__(' was canceled(survey)')}
                      </span>
                    )
                )
              }
            </div>
            <span style={styles.timestamp}>{utils.simpleDate(created, true, null, lang)}</span>
          </div>
        );
        break;
      case 'FORM':
        content = (
          <div style={styles.fill}>
            <div>
              {(event === 'UPDATED') ?
                <span style={styles.logspan}>
                  <span>{fieldNames[field]}</span>
                  {onReference[reference].middle}
                  <span style={styles.bold}>{name}</span>
                  {__(' was changed from(form) ')}
                  <span style={styles.bold}>{formattedPreviousValue}</span>
                  {__(' to ')}
                  <span style={styles.bold}>{formattedCurrentValue}</span>
                </span>
                :
                (
                  event === 'CREATED' ?
                    (
                      <span style={styles.logspan}>
                        {onReference[reference].start}
                        <span style={styles.bold}>{name}</span>
                        {__(' was added(form)')}
                      </span>
                    )
                    :
                    (
                      <span style={styles.logspan}>
                        {onReference[reference].start}
                        <span style={styles.bold}>{name}</span>
                        {__(' was removed(form)')}
                      </span>
                    )
                )
              }
            </div>
            <span style={styles.timestamp}>{utils.simpleDate(created, true, null, lang)}</span>
          </div>
        );
        break;
      default:
        content = null;
    }

    return (
      <div style={styles.item}>
        { field != 'videocall_type' ? <Icon name={onReference[reference].icon} circular style={styles.icon}/> : ([]) }
        {content}
      </div>
    );
  };

  render() {
    const { logs } = this.props;

    if (!logs || !logs.nodes.length || !logs.nodes.filter(log => Object.keys(onReference).includes(log.reference)).length) return null;

    const lang = this.props.store.app.locale && this.props.store.app.locale.substring(0, 2);

    return (
      <div id="MessageLogs">
        <Container key={'logs'} style={{ marginTop: '24px' }}>
          <p><strong>{__('Last Activity')}</strong></p>
          {logs.nodes.filter(log => Object.keys(onReference).includes(log.reference)).map(log => this.handleEvent(log, lang))}
        </Container>
      </div>
    );
  }
}
