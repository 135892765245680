/**
 * Get the charges from input and validate it
 * @param {string} input
* @returns {string}
*/
export const getChargesFromInput = (input) => {
  if (!input) return [];

  try {
    const parsedInput = JSON.parse(input);

    const charges = parsedInput?.charges;

    if (charges) {
      return charges.map(charge => ({
        ...charge,
        absorbBoleto: charge.absorbedFee.includes('boleto'),
        absorbPix: charge.absorbedFee.includes('pix'),
        absorbCard: charge.absorbedFee.includes('card')
      }));
    }

    return [];
  } catch {
    return [];
  }
};
