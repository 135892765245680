import React from 'react';
import { inject, observer } from 'mobx-react';
import { Loader } from 'semantic-ui-react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';

import ReplyGroup from '../Reply/Group';
import ReplyList from '../Reply/List';
import ReplyForm from '../Reply/Form';
import Responsive from '../../components/Responsive';

@inject('store')
@graphql(gql`query MessageRepliesEntityQuery (
  $entityId: ID!,
  $messageId: ID!
) {
    node(id: $entityId) @connection(key: "Entity", filter: ["entityId"]) {
      ... on Entity {
        id: dbId
        message (id: $messageId) {
          id: dbId
          type
          forum
          noReply
          recipientsCount
          myConversations (entityId: $entityId, groupByParent: true, showDeletedReplies: true) {
            totalCount
            nodes {
              parentId
            }
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
          }
          toEntity {
            id: dbId
            disabled
            fullname
          }
          entity {
            id: dbId
            disabled
            fullname
          }
        }
      }
    }
  }
`, {
  options: ownProps => ({
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    variables: {
      entityId: ownProps.params.entity_id,
      messageId: ownProps.params.message_id
    }
  }),
  skip: ownProps => ownProps.params.organization_id
})
@graphql(gql`query MessageRepliesOrganizationQuery (
  $entityId: ID!,
  $messageId: ID!
) {
    node(id: $messageId) @connection(key: "Message", filter: ["messageId"]) {
      ... on Message {
        id: dbId
        type
        forum
        noReply
        recipientsCount
        myConversations (entityId: $entityId, groupByParent: true, showDeletedReplies: true) {
          totalCount
          nodes {
            parentId
          }
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
        }
        toEntity {
          id: dbId
          disabled
          fullname
        }
        entity {
          id: dbId
          disabled
          fullname
        }
      }
    }
  }
`, {
  options: ownProps => ({
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    variables: {
      entityId: ownProps.params.entity_id,
      messageId: ownProps.params.message_id
    }
  }),
  skip: ownProps => !ownProps.params.organization_id
})
@observer
export default class MessageReplies extends Responsive {
  render() {
    const { data, params, store, location, setWritingReply } = this.props;

    if ((data.loading && !data.node) || !data.node) return <Loader active inline="centered" />;

    let { message } = data.node;

    if (params.organization_id) {
      message = data.node;
    }

    let parentId = null;
    // If I'm not the sender, show ReplyList where parent_id is my entity's id.
    // If I'm the sender and there's only one reply_thread, show ReplyList where
    // parent_id is that of the thread.
    // If I'm the sender and there's more than one reply_thread, show ReplyGroup (ignore parent_id)
    if ((message.type !== 'SENT' && !params.organization_id) || message.forum) {
      parentId = params.entity_id;
    } else if (message.myConversations.totalCount === 1) {
      parentId = message.myConversations.nodes[0].parentId;
    }

    const canReply = (
      store.currentEntity && (
        (store.currentEntity.organization && store.currentEntity.organization.confRepliesStudent) ||
        store.currentEntity.type !== 'STUDENT'
      ) && !store.currentEntity.disabled) &&
      !message.noReply && !params.organization_id && message.recipientsCount === 1 && message.toEntity;
    const showDeletedReplies = !!(location && location.pathname && location.pathname.search('organizations') === 1);

    return (
      <div id="MessageReplies" style={{ padding: parentId || message.myConversations.totalCount > 1 || canReply ? this.isMobile() ? '30px 10px 0px 10px' : '30px 20px 0px 20px' : '' }}>
        {
          parentId ?
            <ReplyList
              key={parentId}
              parent_id={parentId}
              entity_id={params.entity_id}
              organization_id={params.organization_id}
              message_id={params.message_id}
              setWritingReply={setWritingReply}
              location={location}
              params={params}
              showDeletedReplies={showDeletedReplies}
            /> :
            (
              message.myConversations.totalCount > 1 ?
                <ReplyGroup
                  params={params}
                  location={location}
                  showDeletedReplies={showDeletedReplies}
                />
                :
                canReply ?
                  <ReplyForm
                    onSubmit={() => data.refetch()}
                    message_id={params.message_id}
                    entity_id={store.currentEntity.id}
                    parent_id={message.toEntity.id}
                    replyRecipient={message.type === 'RECEIVED' ? message.entity : message.toEntity}
                  />
                  :
                  null
            )
        }
        {this.props.children}
      </div>
    );
  }
}
