import { __ } from '../../../i18n';
import * as utils from '../../../utils';
import { backAndRefresh } from './backAndRefresh';
import recoverMessage from './recoverMessage';

export const deleteMessage = (params, router, location, props, hasUndoOption = true, isUndo = false) => {
  props.super.setLoading(true);

  const { deleteForMe, store } = props;
  const request = {
    mutate: deleteForMe,
    input: 'CreateMessageStatusMutation',
    message: hasUndoOption ? __('Message deleted') : __('Action successfully undone'),
  };

  const requestProps = {};

  requestProps.messageId = params.message_id;
  requestProps.entityId = store.entity.id;
  requestProps.status = 'DELETED';

  return request.mutate({
    variables: {
      [request.input]: {
        ...requestProps
      }
    }
  }).then(async (response) => {
    if (props.super.resetStore) await props.super.resetStore();
    props.super.setLoading(false);
    const { messageStatus } = response.data.createMessageStatus;

    utils.clearSnackbar(store);

    store.snackbar = {
      active: true,
      message: request.message,
      success: true,
      action: hasUndoOption ? __('Undo') : __('Close'),
      dismissAfter: 10000,
      onClick: hasUndoOption ? () => recoverMessage(false, messageStatus, props, hasUndoOption) : null
    };

    if (hasUndoOption) backAndRefresh(store, router, location, true, isUndo);
  }).catch((err) => {
    console.error(err);

    utils.clearSnackbar(store);

    props.store.snackbar = {
      active: true,
      message: __('An error occurred while deleting message'),
      success: false,
      dismissAfter: 5000
    };
  });
};
