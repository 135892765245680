/**
 * Create helpers from values
 * @param {{
 *   reports: object[]
 *   forms: object[]
 *   allRecipients: object[]
 *   allGroups: object[]
 * }} values
 * @param {{
 *   entity: {
 *    id: string
 *  }
 * }} store
 * @param {boolean} selectedAll
* @returns {string}
*/
export const createHelpersFromValues = (values, store, selectedAll) => {
  if (!values) return null;

  const helpers = { reports: values.reports, forms: values.forms };

  helpers.recipients = ((!!values.allRecipients && !!values.allRecipients.length) || (!!values.allGroups && !!values.allGroups.length)) ? {
    id: store && store.entity && store.entity.id,
    selected: selectedAll,
    groups: {
      nodes: values.allGroups,
      __typename: 'EntityGroupsConnection'
    },
    recipients: {
      nodes: values.allRecipients,
      __typename: 'EntityRecipientsConnection'
    },
    __typename: 'Entity'
  } : undefined;

  return JSON.stringify(helpers);
};
