import React from 'react';
import { Divider } from 'semantic-ui-react';
import gql from 'graphql-tag';
import { filter } from 'graphql-anywhere';

import FileItem from '../File/Item';

export default class MessageFiles extends React.Component {
  static fragments = {
    files: gql`
      fragment MessageFiles on Message {
        files: medias (type: FILE, limit: 40) {
          nodes {
            id: dbId
            ...FileItem
          }
        }
      }
      ${FileItem.fragments.file}
      `,
  }

  render() {
    const { files, router } = this.props;

    if ((!files || !files?.length) && !files?.nodes?.length) return null;

    if (router || files.length) {
      return (
        <div id="MessageFiles">
          {
            files.map(file => (
              <div key={file.id}>
                <FileItem file={filter(FileItem.fragments.file, file)} />
                <Divider hidden fitted />
              </div>
            ))
          }
        </div>
      );
    }

    return (
      <div id="MessageFiles">
        {
          files.nodes.map(file => (
            <div key={file.id}>
              <FileItem file={filter(FileItem.fragments.file, file)} />
              <Divider hidden fitted />
            </div>
          ))
        }
      </div>
    );
  }
}
