import { getDetailCommitmentsFromInput } from '../getDetailCommitmentsFromInput';

describe('getDetailCommitmentsFromInput', () => {
  it('should get commitments from input', () => {
    const input = {
      commitments: [
        {
          name: 'test',
          // eslint-disable-next-line sonarjs/no-duplicate-string
          date: '2020-01-01',
          time: '10:00:00',
          endDate: '2020-01-01',
          endTime: '10:00:00',
          noConfirm: false,
          dateLimit: '2020-01-01',
          hasvideocallLink: false,
          videocallLink: '',
          videocallType: '',
        },
      ],
    };
    const result = getDetailCommitmentsFromInput(JSON.stringify(input));
    expect(result).toEqual([
      {
        name: 'test',
        date: '2020-01-01',
        time: '10:00:00',
        endDate: '2020-01-01',
        endTime: '10:00:00',
        noConfirm: false,
        dateLimit: '2020-01-01',
        hasvideocallLink: false,
        videocallLink: '',
        videocallType: '',
      },
    ]);
  });
});
