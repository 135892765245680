import React from 'react';
import { inject, observer } from 'mobx-react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import { Button, Icon } from 'semantic-ui-react';
import { withRouter } from 'react-router';
import { cloneDeep, uniqueId, flattenDeep, uniqBy, uniq, flatten } from 'lodash';
import { execute, makePromise } from 'apollo-link';

import Controller from '../../../../components/Controller';
import Modal from '../../../../components/Modal';
import BetaModal from '../../../../components/ui/Modal';
import BetaButton from '../../../../components/ui/Button';

import MessageForm from '../../Form';
import EntityItem from '../../../Entity/Item';

import { __ } from '../../../../i18n';
import * as utils from '../../../../utils';
import { updateChargeFromMessageDraft } from '../utils/updateChargeFromMessageDraft';

const GROUPS_SIZE = 40;

const entityGroupRecipientsQuery = gql`
  query EntityGroupRecipientsQuery($entityId: ID!, $groupId: ID!, $selected: Boolean ) {
  node (id: $entityId) {
    ... on Entity {
      id: dbId
      recipients: groupRecipients (groupId: $groupId, limit: 1400) {
        nodes {
          id: dbId
          selected (selected: $selected)
          ... EntityItemRecipient
        }
      }
    }
  }
}
${EntityItem.fragments.recipient}`;

const entityStaffRecipientsQuery = gql`
  query EntityStaffRecipientsQuery($entityId: ID!, $selected: Boolean ) {
  node (id: $entityId) {
    ... on Entity {
      id: dbId
      recipients: staffRecipients (limit: 800) {
        nodes {
          id: dbId
          selected (selected: $selected)
          ... EntityItemRecipient
        }
      }
    }
  }
}
${EntityItem.fragments.recipient}`;

@withRouter @inject('store', 'client')
@graphql(gql`query MessageEditDraftQuery ($draftId: ID!, $entityId: ID!, $limit: Int!) {
  draft: node(id: $draftId) @connection(key: "MessageDraft", filter: ["draftId"]) {
    ... on MessageDraft {
      id: dbId
      input
      helpers
      sendAt
      type
      fwMessageId
      userAuthorHasEntity
      user{
        id: dbId
        fullname
      }
      review {
        id
        user {
          id: dbId
          fullname
        }
        reviewStatus
        reviewIn
      }
    }
  }
  entity: node(id: $entityId) @connection(key: "Entity", filter: ["entityId"]) {
    ... on Entity {
      id: dbId
      type
      fullname
      picture {
        uri
        id: dbId
        key
      }
      organization {
        id: dbId
        confMedia
        confSurvey
        confCharge
        confReport
        confCommitment
        confForm
        confMediaStudent
        confAdminList
        plan
        premiumPeriod
        isDemonstration
        monthlyMessagesCount
        monthlyMessageQuota
        features {
          messages
          messageManager
          readingControl
          files
          surveys
          commitments
          charges
          moments
          reports
          videos
          audios
          forms
          otherFiles
          dashboard
          labels
          deleteMessages
          editMessages
          videoConference
        }
        labels (limit: 40) {
          nodes {
            id: dbId
            title
            color
          }
        }
        tags (limit: 400) {
          nodes {
            name
            checked
            id: dbId
          }
        }
      }
      groups (limit: $limit) {
        totalCount
        nodes {
          id: dbId
          name
        }
      }
      }
    }
  }
`, {
  options: ownProps => ({
    variables: {
      draftId: ownProps.params.message_id,
      entityId: ownProps.params.entity_id,
      limit: GROUPS_SIZE
    },
  }),
  skip: ownProps => ownProps.shouldSkipDraft
})
@graphql(gql`query MessageEditQuery($entityId: ID!, $messageId: ID!, $limit: Int!) {
  entity: node(id: $entityId) @connection(key: "Entity", filter: ["entityId"]) {
    ... on Entity {
      id: dbId
      type
      fullname
      picture {
        uri
        id: dbId
        key
      }
      organization {
        id: dbId
        confMedia
        confSurvey
        confCharge
        confReport
        confCommitment
        confForm
        confMediaStudent
        confAdminList
        plan
        premiumPeriod
        isDemonstration
        monthlyMessagesCount
        monthlyMessageQuota
        features {
          messages
          messageManager
          readingControl
          files
          surveys
          commitments
          moments
          reports
          api
          videos
          audios
          charges
          forms
          otherFiles
          dashboard
          labels
          deleteMessages
          editMessages
          videoConference
        }
        labels (limit: 40) {
          nodes {
            id: dbId
            title
            color
          }
        }
        tags(limit: 400) {
          nodes {
            name
            checked
            id: dbId
          }
        }
      }
      message(id: $messageId) {
        id: dbId
        created
        modified
        recipientsCount
        fwMessageId
        subject
        content
        type
        pin
        forum
        public
        noReply
        labelId
        entity {
          id: dbId
          type
        }
        medias {
          nodes {
            id: dbId
            type
            filename
            origName
            uri
            type
          }
        }
        surveys {
          nodes {
            id: dbId
            name
            optionNumber
            options(orderBy: {column: ID, direction: ASC}) {
              nodes {
                id: dbId
                value
              }
            }
            dateLimit
            created
            deleted
          }
        }
        commitments {
          nodes {
            id: dbId
            name
            date
            time
            noConfirm
            dateLimit
            videocallType
            videocallLink
            created
            deleted
          }
        }
        reports(limit: 40) {
          nodes {
            id: dbId
            name
            description
            created
            fields(limit: 100) { # in stage and production, reports have less than 80 fields
              nodes {
                type
                results {
                  nodes {
                    id: dbId
                    reportFieldId
                    entityId
                    value
                  }
                }
              }
            }
          }
        }
        charges(limit: 40) {
          nodes {
            id:dbId
            name
            description
            organizationAccountId
            amount
            dateLimit
            currency
            paymentMethods
            absorbedFee
            created
            deleted
          }
        }
        forms(limit: 40) {
          nodes {
            id: formMessageId
            dbId
            name
            description
            dateLimit
            created
            deleted
          }
        }
        signatures {
          nodes {
            id
            title
            canSign(entityId: $entityId)
            status
            results(entityId: $entityId) {
              nodes {
                userId
                signerFullname
                documentNumber
                created
              }
              totalCount
            }
            dateLimit
          }
        }
        toEntity {
          id: dbId
          disabled
          fullname
        }
        recipients (limit: $limit) {
          nodes {
            id: key
            entity {
              id: dbId
              selected (selected: true)
              ... EntityItemRecipient
            }
          }
        }
      }
    }
  }
}
${EntityItem.fragments.recipient}
`, {
  options: ownProps => ({
    variables: {
      entityId: ownProps.params.entity_id,
      messageId: ownProps.params.message_id,
      limit: ownProps.message.recipientsCount
    },
  }),
  skip: ownProps => !ownProps.shouldSkipDraft || !ownProps.params.entity_id
})
@graphql(gql`query MessageOrganizationEditQuery($messageId: ID!, $limit: Int!) {
    node(id: $messageId) @connection(key: "Message", filter: ["messageId"]) {
      ... on Message {
        id: dbId
        created
        modified
        recipientsCount
        labelId
        fwMessageId
        subject
        content
        type
        pin
        forum
        public
        noReply
        entity {
          id: dbId
          fullname
          type
          picture {
            uri
            id: dbId
            key
          }
          organization {
            id: dbId
            confMedia
            confSurvey
            confCharge
            confReport
            confCommitment
            confForm
            confMediaStudent
            confAdminList
            plan
            premiumPeriod
            isDemonstration
            monthlyMessagesCount
            monthlyMessageQuota
            features {
              messages
              messageManager
              readingControl
              files
              surveys
              commitments
              moments
              reports
              api
              videos
              audios
              charges
              forms
              otherFiles
              dashboard
              labels
              deleteMessages
              editMessages
              videoConference
            }
            tags(limit: 400) {
              nodes {
                name
                checked
                id: dbId
              }
            }
            labels (limit: 40) {
              nodes {
                id: dbId
                title
                color
              }
            }
          }
        }
        medias {
          nodes {
            id: dbId
            type
            filename
            origName
            uri
            type
          }
        }
        surveys {
          nodes {
            id: dbId
            name
            optionNumber
            options(orderBy: {column: ID, direction: ASC}) {
              nodes {
                id: dbId
                value
              }
            }
            dateLimit
            created
            deleted
          }
        }
        commitments {
          nodes {
            id: dbId
            name
            date
            time
            noConfirm
            dateLimit
            videocallType
            videocallLink
            created
            deleted
          }
        }
        reports(limit: 40) {
          nodes {
            id: dbId
            name
            description
            created
            fields {
              nodes {
                type
                results {
                  nodes {
                    id: dbId
                    reportFieldId
                    entityId
                    value
                  }
                }
              }
            }
          }
        }
        charges(limit: 40) {
          nodes {
            id:dbId
            name
            description
            organizationAccountId
            amount
            dateLimit
            currency
            paymentMethods
            absorbedFee
            created
            deleted
          }
        }
        forms(limit: 40) {
          nodes {
            id: formMessageId
            dbId
            name
            description
            dateLimit
            created
            deleted
          }
        }
        signatures {
          nodes {
            id
            title
            canSign(entityId: null)
            status
            results(entityId: null) {
              nodes {
                userId
                signerFullname
                documentNumber
                created
              }
              totalCount
            }
            dateLimit
          }
        }
        toEntity {
          id: dbId
          disabled
          fullname
        }
        recipients (limit: $limit) {
          nodes {
            id: key
            entity {
              id: dbId
              selected (selected: true)
              ... EntityItemRecipient
            }
          }
        }
      }
    }
  }
${EntityItem.fragments.recipient}
`, {
  options: ownProps => ({
    variables: {
      messageId: ownProps.params.message_id,
      limit: ownProps.message.recipientsCount
    },
  }),
  skip: ownProps => !ownProps.shouldSkipDraft || ownProps.params.entity_id
})
@graphql(gql`mutation updateMessageDraft($updateMessageDraftMutation: UpdateMessageDraftInput!) {
  updateMessageDraft(input: $updateMessageDraftMutation) {
    clientMutationId
  }
}`, {
  name: 'updateMessageDraft',
  options: {
    refetchQueries: ['EntityMessagesQuery', 'EntitySendLaterQuery', 'EntityDraftsQuery', 'MessageRecipientsQuery', 'MessageEditDraftQuery']
  }
})
@graphql(gql`mutation createMessage($createMessageMutation: CreateMessageInput!) {
  createMessage(input: $createMessageMutation) {
    clientMutationId
  }
}`, {
  name: 'createMessage',
  options: {
    refetchQueries: ['EntityMessagesQuery', 'OrganizationMessagesQuery', 'MessageRecipientsQuery']
  }
})
@graphql(gql`mutation deleteMessageDraft($deleteMessageDraftMutation: DeleteMessageDraftInput!) {
  deleteMessageDraft(input: $deleteMessageDraftMutation) {
    clientMutationId
  }
}`, {
  name: 'deleteMessageDraft',
  options: {
    refetchQueries: ['EntityMessagesQuery', 'EntitySendLaterQuery', 'EntityDraftsQuery', 'MessageRecipientsQuery']
  }
})
@graphql(gql`mutation updateMessage($updateMessageInput: UpdateMessageInput!) {
  updateMessage(input: $updateMessageInput) {
    message {
      id: dbId
    }
  }
}`, {
  name: 'updateMessage',
  options: {
    awaitRefetchQueries: true,
    refetchQueries: [
      'MessageNodeOrganizationQuery',
      'MessageNodeEntityQuery',
      'MessageEditQuery',
      'MessageOrganizationEditQuery',
      'MessageSurveyResultsEntityQuery',
      'MessageSurveyResultsOrganizationQuery',
      'MessageCommitmentRemindsEntityQuery',
      'MessageCommitmentRemindsOrganizationQuery',
      'MessageReportsEntityQuery',
      'MessageReportsOrganizationQuery',
      'MessageEntityHistoryQuery',
      'MessageOrganizationHistoryQuery',
      'EntityMessagesQuery',
      'OrganizationMessagesQuery',
      'MessageFormsEntityQuery',
      'MessageFormsOrganizationQuery'
    ]
  }
})
@graphql(gql`mutation createMessageToApprove($CreateMessageToApproveInput: CreateMessageToApproveInput!) {
  createMessageToApprove(input: $CreateMessageToApproveInput) {
    messageToApprove {
			id
    }
  }
}`, {
  name: 'createMessageToApprove',
  options: (ownProps) => {
    const refetchQueries = ['MessageRecipientsQuery', 'EntityCalendarQuery', 'EntityMessagesQuery'];
    const { hasAdmin, entityScopes } = ownProps.store.currentOrganization.permissions;

    if (ownProps.store.currentUser.isMaster || hasAdmin || entityScopes.includes('READ_MESSAGE')) {
      refetchQueries.push('OrganizationMessagesQuery');
    }

    return {
      awaitRefetchQueries: true,
      refetchQueries
    };
  }
})
@observer
export default class MessageEdit extends Controller {
  constructor(props) {
    super(props);
    this.state = {
      values: null,
      shouldNotifyUpdate: false,
    };

    this.message_draft_status = 'DISCARDED';
  }

  componentDidMount() {
    const { shouldSkipDraft } = this.props;

    if (!shouldSkipDraft) {
      this.props.router.setRouteLeaveHook(this.props.route, () => {
        if (this.props.store.appends.length > 1) {
          this.props.store.appends.pop();
          return false;
        } else if (!this.state.onLeave) {
          return __('Your message was not sent! Are you sure you want to leave?');
        }
      });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.data && nextProps.data.draft && Number(nextProps.data.draft.id) !== Number(nextProps.params.message_id)) {
      nextProps.data.refetch();
      return;
    }
    if (!this.state.values && !nextProps.shouldSkipDraft && nextProps.data.draft) {
      const { totalCount, nodes } = nextProps.data.entity.groups;

      if (nextProps.data.entity.type !== 'STUDENT' && (totalCount > nodes?.length)) {
        this.props.data.fetchMore({
          variables: { limit: totalCount },
          updateQuery: (previous, { fetchMoreResult }) => fetchMoreResult
        });
        return;
      }

      this.parseDraft(nextProps);
    } else if (!this.state.values && nextProps.shouldSkipDraft && (nextProps.data.entity || nextProps.data.node)) {
      this.parseMessage(nextProps);
    }
  }

  parseMessage = (nextProps) => {
    const values = {};
    const message = nextProps.data.node || nextProps.data.entity.message;
    if (message.entity.type !== 'STUDENT') {
      values.reports = ((message && message.reports.nodes) || []).map((report) => {
        report.report = { id: report.id, name: report.name, description: report.description };

        if (report.fields.nodes) {
          report.currentResults = flattenDeep(report.fields.nodes.map(field => field.results.nodes.map((result) => {
            if (field.type === 'CHECK') {
              result.value = typeof result.value === 'string' ? result.value : JSON.stringify(result.value);
            } else if (field.type === 'SELECT') {
              result.value = typeof result.value === 'string' ? result.value : result.value[0];
            }

            return result;
          })));

          report.entities = uniqBy(report.currentResults.map(result => ({ id: result.entityId })).filter(entity => !!entity.id), 'id');
          report.multiple = !!report.entities?.length;
        }

        return report;
      });
      values.tags = [];
      values.groups = [];
      values.recipients = [];
      values.surveys = message && message.surveys
        && message.surveys.nodes ? message.surveys.nodes.map((survey) => {
          survey.options = (survey && survey.options && survey.options.nodes && survey.options.nodes.map(option => option.value));

          if (survey.dateLimit) {
            survey.dateLimit = new Date(utils.parseUniversalDateFormat(survey.dateLimit));
            survey.hasDateLimit = true;
          }

          return survey;
        }) : [];
      values.surveys = values.surveys.filter(survey => survey.deleted === null);

      values.commitments = message.commitments.nodes ? message.commitments.nodes.map((commitment) => {
        if (commitment.date) {
          const date = new Date(commitment.date);
          commitment.date = new Date(date.setTime(date.getTime() + (date.getTimezoneOffset() * 60 * 1000)));
          commitment.hasDate = true;
        } else {
          commitment.date = null;
          commitment.hasDate = false;
        }

        if (commitment && commitment.dateLimit) {
          const date = typeof commitment.dateLimit === 'string' ? new Date(commitment && commitment.dateLimit && commitment.dateLimit.substring(0, 10)) : commitment.dateLimit;
          commitment.dateLimit = new Date(date.setTime(date.getTime() + (date.getTimezoneOffset() * 60 * 1000)));
        }

        if (commitment.time) {
          commitment.time = commitment.time.substring(0, 5);
        }

        return commitment;
      }) : [];
      values.commitments = values.commitments.filter(commitment => commitment.deleted === null);

      values.charges = message.charges.nodes ? message.charges.nodes.map((charge) => {
        charge.currency = 'BRL';

        if (charge.dateLimit) {
          charge.dateLimit = new Date(utils.parseUniversalDateFormat(charge.dateLimit));
          charge.hasDate = true;
        }

        charge.organizationAccountId = parseInt(charge.organizationAccountId, 10);

        return charge;
      }) : [];
      values.charges = values.charges.filter(charge => charge.deleted === null);

      values.forms = message.forms.nodes.filter(form => form.deleted === null).map((form) => {
        if (form.dateLimit) {
          form.dateLimit = new Date(form.dateLimit);
          form.hasDateLimit = true;
        }

        form.id = form.dbId;

        return form;
      });

      values.signatures = message.signatures.nodes;

      values.allRecipients = message.recipients.nodes.map(r => r.entity);
      values.recipients = values.allRecipients;
    }

    values.medias = message && message.medias && message.medias.nodes && message.medias.nodes.map(media => ({
      name: media.origName || uniqueId(),
      type: media.type.toLowerCase(),
      preview: media.uri,
      thumbnail: media.type.toLowerCase() === 'video' ? `https://images.classapp.com.br/w320/classapp-${nextProps.store.app.env === 'production' ? 'live' : nextProps.store.app.env}-media-1/thumb-${media.filename}-00001.png` : null,
      response: { filename: media.filename, uri: media.uri }
    }));

    values.noReply = message && message.noReply;
    values.content = message && message.content;
    values.subject = message && message.subject;
    values.pin = message && message.pin;
    values.public = message && message.public;
    values.forum = message && message.forum;
    values.fwMessageId = message && message.fwMessageId;
    values.labelId = message.labelId;

    this.setState({ values });
  }

  async parseDraft(nextProps) {
    const { input, helpers, sendAt, type, fwMessageId, userAuthorHasEntity, user } = nextProps.data.draft;
    const entity = nextProps.data.entity;
    const values = { ...JSON.parse(input), sendAt: sendAt && new Date(sendAt), type, fwMessageId, recipients: [], userAuthorHasEntity, user, entity };

    const parsedHelpers = JSON.parse(helpers);

    if (nextProps.data.entity.type !== 'STUDENT') {
      values.reports = (parsedHelpers.reports || []).map((report) => {
        report.report = { id: report.id, name: report.name, description: report.description };

        if (report.entities) {
          report.entities = report.entities.map((entity) => {
            entity.fields = entity.fields.map((field, i) => {
              if (report.fields[i].type === 'TEXT') {
                return field || '';
              }

              return field;
            });

            return entity;
          });
        }

        return report;
      });
      values.tags = values.tags ? values.tags.map(tag => parseInt(tag.id, 10)) : [];

      values.allGroups = parsedHelpers.recipients && parsedHelpers.recipients.groups && parsedHelpers.recipients.groups.nodes ? parsedHelpers.recipients.groups.nodes : [];
      values.allRecipients = parsedHelpers.recipients && parsedHelpers.recipients.recipients && parsedHelpers.recipients.recipients.nodes ? parsedHelpers.recipients.recipients.nodes : [];

      const { groups, recipients } = await this.refetchGroupsAndRecipients(values.allGroups, values.allRecipients);

      values.allGroups = groups;
      values.allRecipients = recipients;

      values.groups = groups.filter(group => group.selected);
      values.recipients = recipients.filter(recipient => recipient.selected && values.groups.find(group => group.entities.nodes.find(entityItem => entityItem.id == recipient.id)) === undefined);

      values.surveys = values.surveys ? values.surveys.map((survey) => {
        survey.options = survey.options.map(option => option.value);

        if (survey.dateLimit) {
          survey.dateLimit = new Date(utils.parseUniversalDateFormat(survey.dateLimit));
          survey.hasDateLimit = true;
        }

        return survey;
      }) : [];
      values.commitments = values.commitments ? values.commitments.map((commitment) => {
        if (commitment.date) {
          const date = new Date(commitment.date);
          commitment.date = new Date(date.setTime(date.getTime() + (date.getTimezoneOffset() * 60 * 1000)));
          commitment.hasDate = true;
        } else {
          commitment.date = null;
          commitment.hasDate = false;
        }

        if (commitment.dateLimit) {
          const date = new Date(commitment.dateLimit.substring(0, 10));
          commitment.dateLimit = new Date(date.setTime(date.getTime() + (date.getTimezoneOffset() * 60 * 1000)));
        }

        if (commitment.time) {
          commitment.hasTime = true;
        } else {
          commitment.hasTime = false;
          commitment.time = null;
        }

        return commitment;
      }) : [];
      values.charges = values.charges ? values.charges.map((charge) => {
        charge.currency = 'BRL';

        if (charge.dateLimit) {
          charge.dateLimit = new Date(utils.parseUniversalDateFormat(charge.dateLimit));
          charge.hasDate = true;
        }

        charge.organizationAccountId = parseInt(charge.organizationAccountId, 10);

        return charge;
      }) : [];
      values.forms = (parsedHelpers.forms || []).map((form) => {
        if (form.dateLimit) {
          form.dateLimit = new Date(form.dateLimit);
          form.hasDateLimit = true;
        }

        return form;
      });
    } else if (parsedHelpers.recipients && parsedHelpers.recipients.recipients.nodes[0]) {
      const recipient = parsedHelpers.recipients.recipients.nodes[0];

      values.recipients = [recipient];
      values.singleRecipient = {
        text: recipient.fullname,
        value: recipient.id,
        image: { avatar: true, src: (recipient.picture && recipient.picture.uri) || `/public/avatars/${utils.normalize(recipient.fullname[0].toLowerCase())}.svg` },
        icon: false,
        'data-id': recipient.id,
        entity: recipient
      };
      values.allRecipients = values.recipients;
    }

    values.medias = values && values.medias && values.medias.map(media => ({
      name: media.origName || uniqueId(),
      type: media.type.toLowerCase(),
      preview: media.uri,
      thumbnail: media.type.toLowerCase() === 'video' ? `https://images.classapp.com.br/w320/classapp-${nextProps.store.app.env === 'production' ? 'live' : nextProps.store.app.env}-media-1/thumb-${media.filename}-00001.png` : null,
      response: { filename: media.filename, uri: media.uri }
    }));

    delete values.helpers;
    this.setState({ values });
  }

  refetchGroupsAndRecipients = async (groups, recipients) => {
    const { params, client } = this.props;
    const response = {};

    const promises = groups.filter(group => group.loaded).map(group => makePromise(execute(client.link, {
      query: group.id === 'staff' ? entityStaffRecipientsQuery : entityGroupRecipientsQuery,
      variables: {
        entityId: params.entity_id,
        groupId: group.id,
        selected: group.selected
      },
    })).then((result) => {
      response[group.id] = cloneDeep(result);
    }));

    await Promise.all(promises);

    groups.filter(group => group.loaded).forEach((group) => {
      group.entities.nodes = response[group.id].data.node.recipients.nodes.map((r) => {
        if (r.organization) r.organization.__typename = 'Organization';
        if (r.picture) r.picture.__typename = 'Picture';

        r.__typename = 'Entity';

        return r;
      });
      group.recipientsCount = group.entities.nodes.length;
      group.loaded = true;
    });

    return { groups, recipients };
  }

  treatValues = (values) => {
    if (values.groups.find(group => group === 'staff')) {
      values.types = 'STAFF';
      values.groups = values && values.groups && values.groups.filter(group => group !== 'staff');
    }

    if (values.commitments.length > 0) {
      values.commitments = values && values.commitments && values.commitments.map((c) => {
        const params = { name: c.name, noConfirm: c.noConfirm, videocallLink: c.videocallLink, videocallType: c.videocallType };

        if (c.hasDate) {
          params.date = new Date(c.date.getTime() - (c.date.getTimezoneOffset() * 60000)).toISOString().substring(0, 10);

          if (c.time) {
            params.time = c.time;
          }
        }

        if (c.noConfirm) {
          c.dateLimit = null;
        } else {
          const time = c.time ? c.time.split(':').map(t => parseInt(t, 10)) : c.time;
          let timeLimit = '23:59';

          if (time && c.dateLimit && c.date.getDate() === c.dateLimit.getDate() && c.date.getMonth() === c.dateLimit.getMonth() &&
            c.date.getFullYear() === c.dateLimit.getFullYear()) {
            timeLimit = c.time;
          }

          params.dateLimit = new Date(c.dateLimit && c.dateLimit.getTime() - (c.dateLimit && c.dateLimit.getTimezoneOffset() * 60000)).toISOString().substring(0, 10) + ' ' + timeLimit;
        }

        if (c.videocallLink) {
          params.videocallLink = utils.castToValidURL(c.videocallLink);
          params.videocallType = c.videocallType;
        } else {
          params.videocallLink = null;
          params.videocallType = null;
        }

        return params;
      });
    }

    if (values.reports.length > 0) {
      values.reports = values && values.reports && values.reports.reduce((reports, report) => {
        if (report && report.currentResults) {
          report.currentResults.forEach((result) => {
            reports.push({
              reportId: report.id,
              value: result.value,
              reportFieldId: result.reportFieldId,
              entityId: result.entityId || undefined
            });
          });
        } else if (report && report.fields && !report.multiple) {
          report.fields.forEach((field) => {
            if (field.type === 'CHECK' && field.result !== null) {
              field.result = field.result.filter(item => item.selected).map(item => item.value);
              field.result = (field.result.length > 0) ? JSON.stringify(field.result) : null;
            }

            if (!field.result) return;

            reports.push({
              reportId: report.id,
              value: field.result,
              reportFieldId: field.id
            });
          });
        } else if (report && report.fields) {
          report.fields.forEach((field, i) => {
            report.entities.forEach((entity) => {
              if (field.type === 'CHECK' && entity.fields[i] !== null) {
                entity.fields[i] = entity.fields[i].filter(item => item.selected).map(item => item.value);
                entity.fields[i] = (entity.fields[i].length > 0) ? JSON.stringify(entity.fields[i]) : null;
              }

              if (!entity.fields[i]) return;

              reports.push({
                reportId: report.id,
                value: entity.fields[i],
                reportFieldId: field.id,
                entityId: entity.id
              });
            });
          });
        }
        return reports;
      }, []);
    }

    if (values.surveys.length > 0) {
      values.surveys = values.surveys && values.surveys.map((c) => {
        if (!c.dateLimit) {
          return {
            name: c.name,
            optionNumber: c.optionNumber,
            options: c.options.map(value => ({ value }))
          };
        }

        const time = c.time ? c.time.split(':').map(t => parseInt(t, 10)) : c.time;
        let timeLimit = '23:59';

        if (time) {
          timeLimit = c.time;
        }

        c.dateLimit = new Date(c.dateLimit.getTime() - (c.dateLimit.getTimezoneOffset() * 60000)).toISOString().substring(0, 10) + ' ' + timeLimit;
        return {
          name: c && c.name,
          optionNumber: c && c.optionNumber,
          options: c && c.options && c.options.map(value => ({ value })),
          dateLimit: c && c.dateLimit,
          previousId: c && c.id || null
        };
      });
    }

    if(values.charges.length > 0){
      value.charges = updateChargeFromMessageDraft(values.charges)
    }

    if (values.forms.length > 0) {
      values.forms = values.forms.map((c) => {
        const params = { id: c.id, dateLimit: null };

        if (c.hasDateLimit && c.dateLimit) {
          const timeLimit = '23:59';
          params.dateLimit = new Date(c.dateLimit.getTime() - (c.dateLimit.getTimezoneOffset() * 60000)).toISOString().substring(0, 10) + ' ' + timeLimit;
        }

        return params;
      });
    }

    values.content = values && values.content && values.content.replace(new RegExp('<a href', 'g'), '<a target="_blank" rel="noreferrer noopener" href');

    return values;
  }

  onSendToApproving = (values) => {
    this.setState({ loading: !this.state.loading })
    const {
      params, createMessageToApprove, createMessageDraft, store, router, data
    } = this.props;
    let sendDraft = false;

    let request = { mutate: createMessageToApprove, input: 'CreateMessageToApproveInput', message: __('Message sent for approval') };
    const organization = data?.entity && data.entity?.organization;

    if (!utils.canSubmitMessageWithRecipients(values, organization) && !values.sendAt) {
      sendDraft = true;
    }

    // eslint-disable-next-line no-param-reassign
    values = this.treatValues(values);

    const userId = (store.currentUser && store.currentUser.id) || null;

    const props = {
      entityId: this.props.params.entity_id,
      isHtml: true,
      userId,
      organizationId: (organization && organization.id) || null,
      helpers: values.helpers,
      summary: values.subject,
      pin: values.pin,
      public: values.public,
      forum: values.forum,
      noReply: values.noReply,
      content: values.content,
      surveys: values.surveys,
      commitments: values.commitments,
      reports: values.reports,
      medias: values.medias,
      tags: values && values.tags && values.tags.map(id => ({ id })),
      labelId: values.labelId,
      recipients: {
        entityIds: values.recipients,
        groupIds: values.groups,
      },
      charges: values.charges,
      forms: values.forms
    };

    if (values.fwMessageId) props.fwMessageId = values.fwMessageId;

    if (sendDraft) {
      props.type = values.type;
      request = { mutate: createMessageDraft, input: 'createMessageDraftMutation', message: __('Draft saved successfully!') };
    } else if (values.sendAt) {
      const lang = store.app.locale ? (store.app.locale === 'pt' ? 'pt-BR' : store.app.locale) : 'en';

      values.sendAt.setSeconds(0);

      props.sendAt = values.sendAt;
      request.message = __('Message scheduled to send on %s', utils.simpleDate(values.sendAt, true, 'ddd, DD MMM, LT', lang));
    }

    if (sendDraft) {
      this.setState({ loading: !this.state.loading });
    }

    return request.mutate({
      variables: {
        [request.input]: {
          ...props
        }
      }
    }).then(() => {
      store.snackbar = { active: true, message: request.message, success: true };
      const baseLink = 'entity_id' in params
        ? `/entities/${params.entity_id}` : `/organizations/${params.organization_id}`;

      this.setState({ onLeave: true, loading: !this.state.loading }, () => {
        if (store.history.length > 1) {
          router.goBack();
        } else {
          router.push(baseLink + '/messages');
        }
      });

      if (values.type === "DRAFT") {
        this.deleteDraft();
      }
    }).catch((err) => {
      this.setState({ loading: !this.state.loading });
      store.snackbar = {
        active: true, message: utils.handleError(err.graphQLErrors[0]), success: false, dismissAfter: 5000
      };
    });
  }


  request = (values, sendDraft = false) => {
    const { params, createMessage, updateMessageDraft, updateMessage, store, router, data, shouldSkipDraft, message } = this.props;
    const { shouldNotifyUpdate } = this.state;
    let request = { mutate: createMessage, input: 'createMessageMutation', message: __('Message was sent') };

    const organization = data.entity && data.entity.organization;

    if (!utils.canSubmitMessageWithRecipients(values, organization) && !values.sendAt) {
      // eslint-disable-next-line no-param-reassign
      sendDraft = true;
    }

    values = this.treatValues(values);

    const entity = data.entity || (data.node && data.node.entity) || message.entity;
    const props = {
      isHtml: true,
      subject: values.subject,
      content: values.content,
      pin: values.pin,
      public: values.public,
      forum: values.forum,
      noReply: values.noReply,
      surveys: values.surveys,
      commitments: values.commitments,
      reports: values.reports,
      medias: values.medias,
      charges: values.charges,
      forms: values.forms,
      labelId: values.labelId,
      tags: values.tags ? values.tags.map(id => ({ id })) : [],
      recipients: {
        entityIds: values.recipients ? values.recipients : [],
        groupIds: values.groups ? values.groups : [],
        types: values.type ? values.types : []
      }
    };

    if (values.fwMessageId) props.fwMessageId = values.fwMessageId;

    if (sendDraft || values.sendAt || !shouldSkipDraft) props.entityId = this.props.params.entity_id || entity.id;

    if (sendDraft) {
      props.type = values.type;
      props.id = params.message_id;
      props.helpers = values.helpers;
      request = { mutate: updateMessageDraft, input: 'updateMessageDraftMutation', message: __('Draft saved successfully!') };
    } else if (values.sendAt) {
      props.sendAt = values.sendAt;
      props.type = values.type;
      props.id = params.message_id;
      props.helpers = values.helpers;
      request = { mutate: updateMessageDraft, input: 'updateMessageDraftMutation', message: __('Message saved successfully!') };
    } else if (shouldSkipDraft) {
      props.id = params.message_id;
      props.notifyUpdate = shouldNotifyUpdate;
      request = { mutate: updateMessage, input: 'updateMessageInput', message: __('Message edited successfully!') };
    }

    if (sendDraft) {
      this.setState({ loading: !this.state.loading });
    }

    return request.mutate({
      variables: {
        [request.input]: {
          ...props
        }
      }
    }).then(() => {
      store.snackbar = { active: true, message: request.message, success: true };
    }).then(() => {
      if (request.input === 'createMessageMutation') {
        this.message_draft_status = 'SENT';
        this.deleteDraft();
      }
    }).then(() => {
      if (request.input !== 'updateMessageInput') {
        const baseLink = 'entity_id' in params ?
          `/entities/${params.entity_id}` : `/organizations/${params.organization_id}`;

        this.setState({ onLeave: true }, () => {
          if (store.history.length > 1) {
            router.goBack();
          } else {
            router.push(baseLink + '/messages');
          }
        });
      } else {
        this.props.store.appends.pop();
      }
    })
      .catch((err) => {
        this.setState({ loading: !this.state.loading });
        store.snackbar = { active: true, message: utils.handleError(err.graphQLErrors[0]), success: false, dismissAfter: 5000 };
      });
  }

  deleteDraft = cb => this.props.deleteMessageDraft({
    variables: {
      deleteMessageDraftMutation: {
        id: this.props.params.message_id,
        status: this.message_draft_status
      }
    }
  }).then(() => {
    if (cb) {
      cb();
    }
  })

  validateContent = (values) => {
    const div = document.createElement('div');
    div.innerHTML = values.content;
    return div.textContent.trim().length || (values.subject && values.subject.trim().length) || values.medias.length || values.surveys.length || values.commitments.length || values.reports.length || values.charges.length || values.forms.length;
  }

  onCancelEdited = (values) => {
    const hasContent = values && this.validateContent(values);

    this.props.store.appends.push(<BetaModal
      id="MessageCloseModal"
      toast={this.isMobile()}
      invertCloseButton={this.isMobile()}
      size="tiny"
      onClose={() => this.props.store.appends.pop()}
      header={__('Delete Edit', this.props.object)}
      content={
        this.isMobile() ? this.renderCloseEditedToastContent() :
          hasContent &&
          __('Are you sure you want to discard these changes?')}
      actions={this.isMobile() ? null :
        [
          <BetaButton
            data-action="cancel"
            key={0}
            round
            transparent
            text={__('Cancel')}
            style={{ marginRight: 'auto', marginLeft: 0 }}
            onClick={() => this.props.store.appends.pop()}
          />,
          <BetaButton
            data-action="discard-changes"
            key={2}
            round
            red
            text={__('Discard Changes')}
            style={{ marginRight: 0 }}
            onClick={() => {
              this.props.store.appends.pop();
              this.props.store.appends.pop();
              this.props.store.snackbar = { active: true, message: __('Changes discarded'), success: true };
            }}
          />
        ]
      }
    />);
  }

  renderCloseEditedToastContent = () => (
    <div className="options-toast" style={{ display: 'flex', flexDirection: 'column', marginBottom: '12px' }}>
      <Button
        data-action="discard-changes"
        key={2}
        onClick={() => {
          this.props.store.appends.pop();
          this.props.store.appends.pop();
          this.props.store.snackbar = { active: true, message: __('Changes discarded'), success: true };
        }}
      >
        <span style={{ display: 'flex', fontSize: '16px' }}>
          <Icon name="times" style={{ marginRight: '16px', color: 'rgba(0, 0, 0, 0.6)' }} />
          {__('Discard Changes')}
        </span>
      </Button>
    </div>
  );

  onCancel = (values) => {
    const hasContent = values && this.validateContent(values);

    this.props.store.appends.push(<BetaModal
      id="MessageCloseModal"
      toast={this.isMobile()}
      invertCloseButton={this.isMobile()}
      size={(values.sendAt || !hasContent || values.onCancel) ? 'tiny' : 'small'}
      onClose={() => this.props.store.appends.pop()}
      header={values.sendAt ? __('Delete Scheduled', this.props.object) : __('Delete Draft', this.props.object)}
      content={
        this.isMobile() ?
          this.renderCloseToastContent(values) :
          hasContent && !values.onCancel ?
            (values.sendAt ?
              __('You have made changes to the scheduled message, discard changes?') :
              __('This message has not been sent and contains unsaved changes. You can save it as a draft to work on later.')
            ) :
            __('Are you sure you want to discard this message?')
      }
      actions={this.isMobile() ? null :
        [
          <BetaButton
            data-action="cancel"
            key={0}
            round
            transparent
            text={__('Cancel')}
            style={{ marginRight: 'auto', marginLeft: 0 }}
            onClick={() => this.props.store.appends.pop()}
          />,
          values.onCancel ? null :
            (
              <BetaButton
                data-action="discard-changes"
                key={2}
                round red
                secondary={!(values.sendAt || !hasContent || values.onCancel)}
                text={__('Discard Changes')}
                style={(values.sendAt || !hasContent || values.onCancel) ? { marginRight: 0 } : {}}
                onClick={() => {
                  this.props.store.appends.pop();
                  this.setState({ onLeave: true }, () => {
                    this.props.router.goBack();
                    this.props.store.snackbar = { active: true, message: __('Changes discarded'), success: true };
                  });
                }}
              />
            ),
          !values.onCancel ? null :
            (
              <BetaButton
                data-action="discard"
                key={3}
                round red
                text={__('Discard Message')}
                style={{ marginRight: 0 }}
                onClick={() => this.deleteDraft(() => {
                  this.props.store.appends.pop();
                  this.setState({ onLeave: true }, () => {
                    this.props.router.goBack();
                    if (this.props.data.draft.sendAt) {
                      this.props.store.snackbar = { active: true, message: __('Scheduled message discarded'), success: true };
                    } else {
                      this.props.store.snackbar = { active: true, message: __('Draft discarded'), success: true };
                    }
                  });
                })}
              />
            ),
          values.sendAt || !hasContent || values.onCancel ? null :
            (
              <BetaButton
                data-action="save"
                key={1}
                round
                icon={{ name: 'check' }}
                text={__('Save as draft')}
                onClick={() => {
                  this.props.store.appends.pop();
                  this.request(values, true);
                }}
              />
            )
        ]
      }
    />);
  }

  renderCloseToastContent = (values) => {
    const hasContent = values && this.validateContent(values);
    const { store } = this.props
    const isMessageWriter = utils.isMessageWriter({ store })

    const isWriterAndMessageApproved = isMessageWriter && data?.draft?.review?.reviewStatus === 'approved';

    return (
      <div className="options-toast" style={{ display: 'flex', flexDirection: 'column', marginBottom: '12px' }}>
        {
          [
            values.sendAt || !hasContent || values.onCancel ? null : <Button
              data-action="save"
              key={1}
              onClick={() => {
                this.props.store.appends.pop();
                this.request(values, true);
              }}
            >
              <span style={{ display: 'flex', fontSize: '16px' }}>
                <Icon name="save" style={{ marginRight: '16px', color: 'rgba(0, 0, 0, 0.6)' }} />
                {__('Save as draft')}
              </span>
            </Button>,
            <Button
              data-action="discard-changes"
              key={2}
              onClick={() => {
                this.props.store.appends.pop();
                this.setState({ onLeave: true }, () => {
                  this.props.router.goBack();
                  this.props.store.snackbar = { active: true, message: __('Changes discarded'), success: true };
                });
              }}
            >
              <span style={{ display: 'flex', fontSize: '16px' }}>
                <Icon name="times" style={{ marginRight: '16px', color: 'rgba(0, 0, 0, 0.6)' }} />
                {__('Discard Changes')}
              </span>
            </Button>,
            <Button
              data-action="discard"
              key={3}
              disabled={isWriterAndMessageApproved}
              onClick={() => this.deleteDraft(() => {
                this.props.store.appends.pop();
                this.setState({ onLeave: true }, () => {
                  this.props.router.goBack();
                  if (this.props.data.draft.sendAt) {
                    // this.props.store.snackbar = { active: true, message: __('Scheduled message discarded'), success: true };
                  } else {
                    // this.props.store.snackbar = { active: true, message: __('Draft discarded'), success: true };
                  }
                });
              })}
            >
              <span style={{ display: 'flex', fontSize: '16px', color: '#BF2600' }}>
                <Icon name="trash alt" style={{ marginRight: '16px' }} />
                {__('Discard Message')}
              </span>
            </Button>
          ]
        }
      </div>
    );
  }

  shouldNotNotifyUpdate = () => this.setState({ shouldNotifyUpdate: false });

  shouldNotifyUpdate = () => this.setState({ shouldNotifyUpdate: true });

  renderExpiredLimitModal = (values) => {
    const { store } = this.props;

    this.props.store.appends.push(<BetaModal
      id="MessageCloseModal"
      size="tiny"
      onClose={() => this.props.store.appends.pop()}
      header={__('Message limit reached')}
      content={
        <span>
          {__("Your message won't be send because the plan limit has been reached out. You still can salve as draft to send it later.")}
          {' '}
          {utils.isEntityType(store, 'STAFF') && __('Contact the admin for more information.')}
        </span>
      }
      actions={[
        <BetaButton
          data-action="cancel"
          key={0}
          round transparent
          style={{ marginRight: 'auto', marginLeft: 0 }}
          text={__('Cancel')}
          onClick={() => this.props.store.appends.pop()}
        />,
        <BetaButton
          data-action="submit"
          key={1}
          round
          text={__('Save as draft')}
          style={{ marginRight: 0 }}
          onClick={() => {
            this.props.store.appends.pop();
            this.request(values, true);
          }}
        />
      ]}
    />);
  }

  renderEdit(message, entity) {
    const { params, location, shouldSkipDraft, editReply, store } = this.props;
    const { type, picture, fullname, groups, organization } = entity;
    const isMessageWriter = utils.isMessageWriter({ store })

    const isWriterAndMessageApproved = isMessageWriter && message?.review?.reviewStatus === 'rejected';

    return (
      <Controller
        id="MessageEdit"
        edit
        toggleLoading={() => this.setState({ loading: !this.state.loading })}
        form={MessageForm}
        loading={this.state.loading}
        errors={this.state.errors}
        onSubmit={this.onSubmit}
        submitButton={{ text: isWriterAndMessageApproved ? __('Submit for approval') : __('Save'), isActionButtom: true }}
        onCancel={v => this.onCancelEdited(v)}
        cancelButton={{
          text: __('Cancel'),
          style: { marginRight: '12px', fontSize: '16px' },
          isActionButtom: true
        }}
        formProps={{
          organization: {
            id: organization && organization.id,
            confMedia: organization && organization.confMedia,
            confSurvey: organization && organization.confSurvey,
            confCharge: organization && organization.confCharge,
            confReport: organization && organization.confReport,
            confCommitment: organization && organization.confCommitment,
            confForm: organization && organization.confForm,
            confMediaStudent: organization && organization.confMediaStudent,
            confAdminList: organization && organization.confAdminList,
            tags: organization && organization.tags && organization.tags.nodes,
            features: organization && organization.features,
            labels: organization && organization.labels && organization.labels.nodes,
            plan: organization && organization.plan,
            premiumPeriod: organization && organization.premiumPeriod,
            monthlyMessagesCount: organization && organization.monthlyMessagesCount,
            monthlyMessageQuota: organization && organization.monthlyMessageQuota
          },
          entity: {
            id: params.entity_id || entity.id,
            type,
            fullname,
            picture,
            groups
          },
          recipients: [],
          modalTitle: __('Edit Message'),
          location,
          shouldSkipDraft,
          editReply,
          message,
          shouldNotifyUpdate: this.shouldNotifyUpdate,
          shouldNotNotifyUpdate: this.shouldNotNotifyUpdate,
          fwMessageId: this.state.values.fwMessageId,
          params,
          id: 'MessageEdit'
        }}
        values={this.state.values}
        {...this.props}
      />
    );
  }

  render() {
    const { values } = this.state;
    const { data, params, location, message, shouldSkipDraft, store } = this.props;
    const messageData = data && (data.node || (data.entity && data.entity.message));

    if ((data.loading && !messageData) || (shouldSkipDraft && !messageData) || !values || (!message && !this.state.values)) return <Modal loading />;

    const entity = data.entity || (data.node && data.node.entity) || message.entity;

    if (messageData && shouldSkipDraft) return this.renderEdit(messageData, entity);

    const { type, picture, fullname, groups, organization, id } = entity;
    let recipients = null;

    if (!shouldSkipDraft) {
      recipients = JSON.parse(data.draft.helpers).recipients;
      if (recipients) {
        if (!recipients.groups) recipients.groups = {};
        recipients.groups.nodes = values.allGroups;
        if (!recipients.recipients) recipients.recipients = {};
        recipients.recipients.nodes = values.allRecipients;
      }
    }
    const isConsultant = !store.currentOrganization;
    let organizationIds = null;
    if (isConsultant) {
      const organizationId = recipients
        && recipients.recipients
        && recipients.recipients.nodes
        && recipients.recipients.nodes[0]
        && recipients.recipients.nodes[0].organization
        && recipients.recipients.nodes[0].organization.id;
      organizationIds = [organizationId];
    }
    const isMessageWriter = utils.isMessageWriter({ store })
    const hasStudents = utils.hasStudentInGroup(values.groups) || utils.hasStudentInList(values.recipients)
    const isWriterAndMessageApproved = isMessageWriter && data?.draft?.review?.reviewStatus === 'approved';

    return (
      <Controller
        id="DraftEdit"
        edit
        toggleLoading={() => this.setState({ loading: !this.state.loading })}
        form={MessageForm}
        loading={this.state.loading}
        disableSave={isWriterAndMessageApproved}
        errors={this.state.errors}
        //onSubmit={this.onSubmit}
        onSubmit={v => (isMessageWriter && hasStudents) ? this.onSendToApproving(v) : (utils.canSubmitMessageWithRecipients(v, organization) || v.sendAt ? this.onSubmit(v) : this.renderExpiredLimitModal(v))}
        submitButton={{ text: isMessageWriter ? __('Submit for approval') : __('Send now'), isActionButtom: true }}
        onCancel={v => this.onCancel(v)}
        cancelButton={{
          text: null,
          icon: { name: 'trash', style: { margin: '0px', color: '#000000' } },
          style: { boxShadow: 'none' },
          isActionButtom: true
        }}
        formProps={{
          organization: {
            id: organization && organization.id,
            confMedia: organization && organization.confMedia,
            confSurvey: organization && organization.confSurvey,
            confCharge: organization && organization.confCharge,
            confReport: organization && organization.confReport,
            confCommitment: organization && organization.confCommitment,
            confForm: organization && organization.confForm,
            confMediaStudent: organization && organization.confMediaStudent,
            confAdminList: organization && organization.confAdminList,
            tags: organization && organization.tags.nodes,
            features: organization && organization.features,
            labels: organization && organization.labels.nodes,
            plan: organization && organization.plan,
            premiumPeriod: organization && organization.premiumPeriod,
            monthlyMessagesCount: organization && organization.monthlyMessagesCount,
            monthlyMessageQuota: organization && organization.monthlyMessageQuota
          },
          entity: {
            id,
            type,
            fullname,
            picture,
            groups
          },
          recipients,
          modalTitle: (values && values.sendAt) ? __('Edit send later') : __('Edit draft'),
          location,
          params,
          fwMessageId: values.fwMessageId,
          id: 'DraftEdit',
          consultantForm: isConsultant,
          organizationIds
        }}
        values={values}
        {...this.props}
      />
    );
  }
}
