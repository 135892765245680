/**
 * @param {{
 * organization: {
 *  network: {
 *     id: number | string
 *  }
 *  unleashStatus: {
 *   network_communication: boolean;
 *  }
 * }
 * message: {
 *  entity: {
 *   organizationId: number | string;
 *  }
 * }
 * }} props
 */
export function isMessageFromNetwork({ organization, message }) {
  if (!organization || !message) return false;

  const isNetworkMessage =
    !!organization?.network?.id &&
    Number(organization?.network?.id) ===
      Number(message?.entity?.organizationId);

  const hasNetworkUnleash = organization?.unleashStatus?.network_communication;

  return isNetworkMessage && hasNetworkUnleash;
}
