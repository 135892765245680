import { uniqueId } from 'lodash';

/**
 * Get the media from message input detail modal
 * @param {string} input
 * @param {string} env
 * @returns {{
 *  audios: Array<{
 *    id: string,
 *    origName: string,
 *    type: string,
 *    uri: string,
 *    thumbnail: string,
 *    preview: string,
 *    response: { filename: string, uri: string },
 *  }>
 *  images: Array<{
 *    id: string,
 *    origName: string,
 *    type: string,
 *    uri: string,
 *    thumbnail: string,
 *    preview: string,
 *    response: { filename: string, uri: string },
 *  }>
 *  videos: Array<{
 *    id: string,
 *    origName: string,
 *    type: string,
 *    uri: string,
 *    thumbnail: string,
 *    preview: string,
 *    response: { filename: string, uri: string },
 *  }>
 *
 * files: Array<{
*    id: string,
*    origName: string,
*    type: string,
*    uri: string,
*    thumbnail: string,
*    preview: string,
*    response: { filename: string, uri: string },
*  }>
 * }}
*/
export const getDetailMediasFromInput = (input, env) => {
  const defaultMedias = {
    audios: [],
    videos: [],
    images: [],
    files: [],
  };

  if (!input) return defaultMedias;

  const audios = []
  const videos = []
  const images = []
  const files = []

  try {
    const parsedInput = JSON.parse(input);
    const medias = parsedInput?.medias;

    if (medias && medias instanceof Array) {
      medias?.map(media => {
        const formattedMedia = {
          id: uniqueId(),
          origName: media.origName || uniqueId(),
          type: media.type.toLowerCase(),
          mimetype: media.type.toLowerCase(),
          uri: media.uri,
          size: media.size || undefined,
          preview: media.uri,
          thumbnail: media.type.toLowerCase() === 'video' ? `https://images.classapp.com.br/w320/classapp-${env === 'production' ? 'live' : env}-media-1/thumb-${media.filename}-00001.png` : null,
          response: { filename: media.filename, uri: media.uri }
        }

        switch (media.type) {
          case 'audio':
            audios.push(formattedMedia);
            break;
          case 'video':
            videos.push(formattedMedia);
            break;
          case 'image':
            images.push(formattedMedia);
            break;
          case 'file':
            files.push(formattedMedia);
            break;
          default:
            break;
        }
      });
    }


    return {
      audios,
      videos,
      images,
      files
    };
  } catch {
    return defaultMedias;
  }
};
