/* eslint-disable sonarjs/no-duplicate-string */
import React from 'react';
import MessageLabel from '../MessageLabel';
import { render } from '../../../../../newRender';

describe('MessageLabel', () => {
  it('should return null if label is not provided', () => {
    const { container } = render(<MessageLabel />);
    expect(container).toBeEmptyDOMElement();
  });

  it('should render label with correct title and color', () => {
    const label = { title: 'Test Label', color: 'FF0000' };
    const { getByText } = render(<MessageLabel label={label} />);
    expect(getByText('Test Label')).toBeInTheDocument();
    expect(getByText('Test Label')).toHaveStyle('background-color: #FF0000');
  });
});
