import React, { Component } from 'react';
import gql from 'graphql-tag';

import SignatureItem from '../Signature/Item';

export default class MessageSignatures extends Component {
  static fragments = {
    signatures: gql`
      fragment MessageSignatures on Message {
        signatures {
          nodes {
            id
            title
            canSign (entityId: $entityId)
            status
            results (entityId: $entityId) {
              nodes {
                userId
                signerFullname
                documentNumber
                created
              }
              totalCount
            }
            dateLimit
          }
        }
      }
    `
  };

  render() {
    const { signatures, params, messageType, entity, updateQuery } = this.props;

    if (!signatures || !signatures.nodes.length) return null;

    return (
      <div id="MessageSignatures">
        {signatures.nodes.map(signature => (
          <SignatureItem
            signature={signature}
            params={params}
            messageType={messageType}
            entity={entity}
            updateQuery={updateQuery}
          />
        ))}
      </div>
    );
  }
}
