/**
 * Get the tags from input and validate it
 * @param {string} input
* @returns {Array<{ id: number }>}
*/
export const getTagsFromInput = (input) => {
  if (!input) return [];

  try {
    const parsedInput = JSON.parse(input);
    const tags = parsedInput?.tags;

    if (tags) {
      return tags instanceof Array ? tags.map(tag => parseInt(tag.id, 10)) : [];
    }

    return [];
  } catch {
    return [];
  }
};
