import React from 'react';
import { inject, observer } from 'mobx-react';
import {
  Table, Card, Container, Icon, Divider
} from 'semantic-ui-react';
import { __ } from '../../../i18n';
import Responsive from '../../../components/Responsive';
import { formatReportResult } from '../Node/utils/formatReportResult';
import ReportResultMultiple from './ReportResultMultiple';

@inject('store') @observer
class ReportResultItem extends Responsive {
  renderSingleReportFields = fields => (
    <div data-testid="singleReport">
      {fields.map((field, i) => {
        const fieldResult = formatReportResult(field.result);
        return [
          (fieldResult && fieldResult !== '') && (
            <Table.Row
              className="reportResultItem"
              title={field.name}
              data-id={field.id}
              key={i}
              style={{ backgroundColor: i % 2 === 0 ? null : '#f6f7f8', display: 'flex', border: 'none' }}
            >
              <Table.Cell textAlign={this.isMobile() ? 'left' : 'right'} style={{ flex: 1, border: 'none' }}>{field.name}</Table.Cell>
              <Table.Cell textAlign="left" style={{ whiteSpace: 'pre-line', border: 'none', flex: 1 }}>{fieldResult}</Table.Cell>
            </Table.Row>
          )
        ];
      })}
    </div>
  )

  showReportModal(report) {
    const { store } = this.props;
    const { name, entities, fields } = report;
    store.appends.push(<ReportResultMultiple
      name={name}
      entities={entities}
      fields={fields}
    />);
  }

  renderMultipleReportFields = report => {
    const { entities, fields } = report;
    const entity = entities[0];

    if (entities && entities.length > 0) {
      return (
        <>
          <Table.Row data-testid="ReportMultipleID">
            <Table.Cell textAlign="left" style={{ borderBottom: '#eeeeee 1px solid' }}>
              <div>
                {__('You are viewing')}
                {' '}
                <span className="bold" style={{ color: '#0080ff' }}>
                  {' '}
                  {__("%s's Report", entity.fullname)}
                </span>
              </div>
            </Table.Cell>
            <Table.Cell textAlign="right" style={{ borderBottom: '#eeeeee 1px solid' }}>
              <div
                className="bold pointer"
                style={{ color: '#0080ff', fontSize: '12px' }}
                onKeyDown={() => this.showReportModal(report)}
                onClick={() => this.showReportModal(report)}
              >
                {__('View all reports').toUpperCase()}
                {' '}
                {`(${entities.length})`}
              </div>
            </Table.Cell>
          </Table.Row>
          {
            fields.map((field, index) => {
              const fieldResult = formatReportResult(entity.fields[index]);
              return (fieldResult && fieldResult !== '')
              && (
                <Table.Row
                  className="reportResultItem"
                  title={field.name}
                  data-id={field.id}
                  key={index}
                  style={{ backgroundColor: index % 2 === 0 ? null : '#f6f7f8' }}
                >
                  <Table.Cell textAlign={this.isMobile() ? 'left' : 'right'} style={{ width: '50%', border: 'none', fontWeight: 'bold' }}>
                    {field.name}
                  </Table.Cell>
                  <Table.Cell textAlign="left" style={{ whiteSpace: 'pre-line', border: 'none' }}>
                    {fieldResult}
                  </Table.Cell>
                </Table.Row>
              );
            })
          }
        </>
      );
    }
    return this.renderSingleReportFields(fields);
  }

  render() {
    const {
      report
    } = this.props;

    if (!report) return null;

    const { id, name } = report;

    return (
      <Card
        className="reportItem"
        title={name}
        data-id={id}
        fluid
        style={{ marginBottom: '1em' }}
      >
        <Card.Content>
          <Container>
            <div className="horizontallySpacedItems">
              <div className="bold">
                <Icon name="list layout" style={{ color: '#0080ff', margin: '0 .25rem 0 0', padding: 0 }} />
                {name}
              </div>
              {/* <Icon name="ellipsis v" /> */}
            </div>
            <Table basic="very">
              <Table.Body>
                {this.renderMultipleReportFields(report)}
              </Table.Body>
            </Table>
          </Container>
        </Card.Content>
      </Card>
    );
  }
}

export { ReportResultItem };
