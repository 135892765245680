import { formatReportResult } from '../formatReportResult';

describe('formatReportResult', () => {
  it('should be able to return the report result', () => {
    const result = formatReportResult('test');
    expect(result).toEqual('test');
  });

  it('should be able to return the report result in array', () => {
    const result = formatReportResult(['test']);
    expect(result).toEqual('test');
  });

  it('should check if result[0] return the report result', () => {
    const result = formatReportResult([{ value: 'test' }]);
    expect(result).toEqual('');
  });
});
