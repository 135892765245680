import React from 'react';
import { Label } from 'semantic-ui-react';

function MessageLabel({ label }) {
  if (!label) return null;

  const { color, title } = label;

  return (
    <div style={{ marginLeft: 8 }}>
      <Label data-name="label" size="tiny" style={{ color: 'white', backgroundColor: `#${color}`, wordBreak: 'initial' }}>{title}</Label>
    </div>
  );
}
export default MessageLabel;
