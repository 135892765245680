import React from 'react';
import MessageStatus from '../MessageStatus';
import { render } from '../../../../../newRender';

describe('MessageStatus', () => {
  it('should return null if message is not provided', () => {
    const { container } = render(<MessageStatus message={null} />);
    expect(container).toBeEmptyDOMElement();
  });

  it('should be defined when message with logs is provided', () => {
    const message = {
      deleted: null,
      logs: {
        totalCount: 1,
        nodes: [
          {
            id: 11,
            name: null,
            reference: 'MEDIA',
            event: 'DELETED',
            field: null,
            currentValue: null,
            previousValue: null,
            created: '2023-03-20T15:16:49.000Z',
            version: 0,
            referenceId: 53,
            __typename: 'MessageLog',
          },
        ],
      },
    };
    const { container } = render(<MessageStatus message={message} />);
    expect(container).toBeDefined();
  });
});
