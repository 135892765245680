/**
 * Get the content from input and validate it
 * @param {string} input
* @returns {string}
*/
export const getContentFromInput = (input) => {
  if (!input) return null;

  try {
    const parsedInput = JSON.parse(input);
    const content = parsedInput?.content;

    if (content) {
      return typeof content === 'string' ? content : JSON.stringify(content);
    }

    return null;
  } catch {
    return null;
  }
};
