import React from 'react';
import { inject, observer } from 'mobx-react';
import {
  Table,
  Segment,
  Button
} from 'semantic-ui-react';

import Modal from '../../../components/Modal';
import Page from '../../../components/Page';
import Avatar from '../../../components/Avatar';

import { __ } from '../../../i18n';
import { formatReportResult } from '../Node/utils/formatReportResult';

const styles = {
  iconStyle: {
    fontSize: '18px',
    padding: '8px'
  }
};

export default
@inject('store')@observer
class ReportResultMultiple extends Page {
  constructor(props) {
    super(props);

    this.state = {
      selectedEntity: 0,
    };
  }

  eventReload = (to) => {
    let { selectedEntity } = this.state;

    if (to === 'left') {
      selectedEntity -= 1;
    } else {
      selectedEntity += 1;
    }

    this.setState({ selectedEntity });
  }

  // onEntityChange = (selectedEntity) => {
  //   const { query } = this.state;
  //   const { node, loading } = this.props.data;

  //   if (loading) return;

  //   const { entities } = node.report;

  //   query.entityId = entities.nodes[selectedEntity].id;

  //   this.setState({ query, selectedEntity });

  //   this.props.data.refetch({
  //     ...query
  //   });
  // }

  renderReportFields = (fields, entities) => fields.map((field, i) => {
    const { selectedEntity } = this.state;
    const entity = entities[selectedEntity];
    const fieldResult = formatReportResult(entity.fields[i]);
    return { field, fieldResult };
  }).filter(({ fieldResult }) => !!fieldResult && fieldResult.trim() !== '').map(({ field, fieldResult }, i) => (
    <Table.Row
      className="reportResultItem"
      title={field.name}
      data-id={field.id}
      key={i}
      style={{ backgroundColor: i % 2 === 0 ? null : '#f6f7f8' }}
    >
      <Table.Cell textAlign={this.isMobile() ? 'left' : 'right'} style={{ width: '50%', border: 'none', fontWeight: 'bold' }}>{field.name}</Table.Cell>
      <Table.Cell textAlign="left" style={{ whiteSpace: 'pre-line', border: 'none' }}>{fieldResult}</Table.Cell>
    </Table.Row>
  ));

  render() {
    const {
      store, fields, entities, name, searchContainer
    } = this.props;
    const { selectedEntity } = this.state;

    if (!entities || entities.length === 0) return <Modal data-testid="loading" loading />;
    const entity = entities[selectedEntity];
    const left = selectedEntity > 0 ? { onClick: () => this.eventReload('left'), className: 'pointer buttonHover' } : { disabled: true };
    const right = selectedEntity < entities.length - 1 ? { onClick: () => this.eventReload('right'), className: 'pointer buttonHover' } : { disabled: true };

    return (
      <Modal id="MessageReportResult" open size="small" closeIcon="times close" onClose={() => store.appends.pop()}>
        <Modal.Header style={{ border: 'none' }}>{__('%s: All Reports', name)}</Modal.Header>
        <Modal.Content style={{ padding: '0px', backgroundColor: '#ffffff' }}>
          {/* {
            searchContainer
          } */}
          <Segment basic style={{ margin: '0px' }}>
            <div className="horizontallySpacedItems" style={{ paddingBottom: '14px', alignItems: 'center', borderBottom: '1px solid #eeeeee' }}>
              <Button data-testid="left-button" circular basic icon="angle left" {...left} style={{ ...styles.iconStyle }} />
              <div className="bold">
                <Avatar avatar spaced="right" src={entity.picture && entity.picture.uri} alt={entity.fullname || ''} />
                {entity.fullname}
              </div>
              <Button data-testid="right-button" circular basic icon="angle right" {...right} style={{ ...styles.iconStyle }} />
            </div>
            <Table basic="very">
              <Table.Body>
                { this.renderReportFields(fields, entities) }
              </Table.Body>
            </Table>
          </Segment>
        </Modal.Content>
      </Modal>
    );
  }
}
