import React from 'react';
import BetaModal from '../../../components/ui/Modal';
import BetaButton from '../../../components/ui/Button';
import { __ } from '../../../i18n';

/**
 * @param {boolean} writingReply
 * @param {object} store
 * @param {() => Promise<void>} closeAction
 * @param {() => Promise<void>} onClick
 * @param {any} object
 */

const onClose = (writingReply, store, closeAction, onClick, object) => {
  if (writingReply) {
    store.appends.push(<BetaModal
      id="MessageCloseModal"
      size="tiny"
      onClose={() => store.appends.pop()}
      header={__('Discard reply', object)}
      content={
        __('Are you sure you want to discard this reply?')
      }
      actions={[
        <BetaButton
          data-action="cancel"
          key={0}
          round
          transparent
          text={__('Cancel')}
          style={{ marginRight: 'auto', marginLeft: 0 }}
          onClick={() => store.appends.pop()}
        />,
        <BetaButton
          data-action="submit"
          key={2}
          round
          red
          text={__('Discard')}
          style={{ marginRight: 0 }}
          onClick={onClick}
        />
      ]}
    />);
  } else {
    closeAction();
  }
};

export default onClose;
