import React from 'react';
import { Icon, Divider, Segment, Label, Grid, Header, Loader, Container, Image } from 'semantic-ui-react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';

import Avatar from '../../components/Avatar';

import MessageMedias from './Medias';
import MessageFiles from './Files';

import * as utils from '../../utils';
import { __ } from '../../i18n';

@graphql(gql`query MessagePublicQuery (
  $id: ID!
) {
    node(id: $id) @connection(key: "Message", filter: ["id"]) {
      ... on Message {
          id: dbId
          type
          subject
          rendered
          public
          created
          recipientsCount
          status
          deleted
          entity {
            id: dbId
            fullname
            picture {
              uri
              id: dbId
              key
            }
          }
          toEntity {
            id: dbId
            status
            fullname
            picture {
              uri
              id: dbId
              key
            }
          }
          ...MessageMedias
          ...MessageFiles
      }
    }
  }
${MessageMedias.fragments.medias}
${MessageFiles.fragments.files}
`, {
  options: ownProps => ({
    variables: {
      id: ownProps.params.message_id
    }
  })
})
export default class MessagePublic extends React.Component {
  render() {
    const { data } = this.props;

    if ((data.loading && !data.node) || !data.node) return <Loader active />;

    const { recipientsCount, type, toEntity, entity, created, subject, rendered, images, videos, audios, files, deleted } = data.node;

    if (deleted) {
      return (
        <Container textAlign="center">
          <Image
            src={utils.asset('/images/deletedMessage.png')}
            style={{
              width: '300px',
              height: '300px',
              margin: 'auto',
              marginTop: '48px'
            }}
          />
          <div style={{ display: 'grid', marginTop: '24px' }}>
            <span style={{ fontSize: '18px', fontWeight: '500', color: '#212529' }}>{__('Unavailable Message')}</span>
            <span style={{ fontSize: '14px', color: '#868e96', marginBottom: '48px', marginTop: '8px' }}>{__('This message has been deleted and can no longer be viewed!')}</span>
          </div>
        </Container>
      );
    }

    return (
      <Container id="MessagePublic">
        <Grid stackable>
          <Grid.Column width={4} />
          <Grid.Column stretched width={8}>
            <Segment.Group>
              <Segment secondary>
                <Grid>
                  <Grid.Row columns={2}>
                    <Grid.Column>
                      <Label basic size="small">
                        {type === 'SENT' ? __('To') : __('From')}
                      </Label>
                      {
                        type === 'SENT' ?
                          (
                            recipientsCount === 1 ?
                              <span>
                                <Avatar avatar spaced src={toEntity.picture && toEntity.picture.uri} alt={toEntity.fullname} />
                                <span>{`${toEntity.fullname} `}</span>
                                <Icon.Group>
                                  {
                                    toEntity.status > 0 && toEntity.status < 1 &&
                                    <Icon color="black" name="check double" />
                                  }
                                  {
                                    toEntity.status > 1 &&
                                    <Icon name="check double" style={{ color: '#0080FF' }} />
                                  }
                                </Icon.Group>
                              </span>
                              :
                              <span>
                                <Icon name="users" />
                                <span>{`${recipientsCount.toString()} recipients`}</span>
                              </span>
                          )
                          :
                          (
                            <span>
                              <Avatar avatar spaced src={entity.picture && entity.picture.uri} alt={entity.fullname} />
                              <span>{`${entity.fullname} `}</span>
                            </span>
                          )
                      }
                    </Grid.Column>
                    <Grid.Column textAlign="right">
                      <span>{utils.simpleDate(created, true)}</span>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Segment>
              <Segment className="wordWrapped">
                {!!subject && <Header>{subject}</Header>}
                {!!rendered && <div
                  className="content cslp-content ql-editor"
                  dangerouslySetInnerHTML={{
                    __html: rendered
                  }}
                />}
                <Divider hidden />
                <MessageMedias images={images} videos={videos} audios={audios} />
                <MessageFiles files={files} />
                {this.props.children}
              </Segment>
            </Segment.Group>
          </Grid.Column>
          <Grid.Column width={4} />
        </Grid>
      </Container>
    );
  }
}
