import { handleUniqueParameter } from '../handleUniqueParameter';

describe('handleUniqueParameter', () => {
  it('should handle unique parameter', () => {
    const name = 'name';
    const value = 'value';
    const type = 'type';
    const query = { query: 'query' };
    const eventReload = jest.fn();
    handleUniqueParameter(name, value, type, query, eventReload);
    expect(eventReload).toHaveBeenCalledWith({ ...query, name: value });
  });

  it('should handle unique parameter with array type and value length less than or equal to 1', () => {
    const name = 'name';
    const value = ['value'];
    const type = 'array';
    const query = { query: 'query' };
    const eventReload = jest.fn();
    handleUniqueParameter(name, value, type, query, eventReload);
    expect(eventReload).toHaveBeenCalledWith({ ...query, name: value[0] });
  });

  it('should handle unique parameter with array type and value length greater than 1', () => {
    const name = 'name';
    const value = ['value1', 'value2'];
    const type = 'array';
    const query = { name: ['value1'] };
    const eventReload = jest.fn();
    handleUniqueParameter(name, value, type, query, eventReload);
    expect(eventReload).toHaveBeenCalledWith({ ...query, name: value[1] });
  });
});
