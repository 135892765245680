import { updateMessageToApprove } from '../utils/updateMessageToApprove';

describe('updateMessageToApprove', () => {
  it('should connect async', () => {
    const mock = {
      mutation: 'mutation test',
      variables: {
        id: 10,
      },
      options: {
        refetchQueries: ['TestQuery'],
      },
      client: {
        mutate: jest.fn(),
      },
    };
    updateMessageToApprove(
      mock.mutation,
      mock.variables,
      mock.options,
      mock.client,
    );
    expect(mock.client.mutate).toBeCalledTimes(1);
    expect(mock.client.mutate).toHaveBeenCalledWith({
      mutation: mock.mutation,
      variables: mock.variables,
      options: mock.options,
    });
  });
});
