import { updateMessageToApprove } from '../updateMessageToApprove';

describe('updateMessageToApprove util', () => {
  it('should return null when passed empty input', async () => {
    const mutation = 'mutation test';
    const variables = {
      id: 10,
    };
    const options = {
      refetchQueries: ['TestQuery'],
    };
    const client = {
      mutate: jest.fn().mockResolvedValue({}),
    };
    const result = await updateMessageToApprove(
      mutation,
      variables,
      options,
      client,
    );
    expect(result).toEqual({});
  });
});
