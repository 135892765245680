/**
 * Get the charges from input and validate it
 * @param {string} mutation
 * @param {string} variables
 * @param {object} options
 * @param {() => {}} client
 * @returns {Promise<object>}
*/
export const updateMessageToApprove = async (mutation, variables, options, client) => client.mutate({
  mutation,
  variables,
  options
});
