/**
 * Get the commitments from message input detail modal
 * @param {string} input
 * @returns {Array<{
 *  name: string,
 *  date: string,
 *  time: string,
 *  endDate: string,
 *  endTime: string,
 *  hasDate: boolean,
 *  hasTime: boolean,
 *  noConfirm: boolean,
 *  dateLimit: string,
 *  hasvideocallLink: boolean,
 *  videocallLink: string,
 *  videocallType: string
 * }>}
*/
export const getDetailCommitmentsFromInput = (input) => {
  if (!input) return [];

  try {
    const parsedInput = JSON.parse(input);
    const commitments = parsedInput?.commitments;

    if (commitments && commitments instanceof Array) {
      return commitments;
    }

    return [];
  } catch {
    return [];
  }
};
