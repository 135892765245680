import gql from 'graphql-tag';

const RESEND_COMMUNICATION_MUTATION = gql`
  mutation ResendMessage($input: ResendMessageInput!) {
    resendCommunicationMessage(input: $input) {
      success
      statusSend
    }
  }
`;

export function resendCommunication(client, messageId) {
  return client.mutate({
    mutation: RESEND_COMMUNICATION_MUTATION,
    variables: {
      input: {
        messageId,
      },
    },
  });
}
