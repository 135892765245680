export const backAndRefresh = (store, router, location, isLegacy = false, undo = false) => {
  // remove all undefined routes
  const history = store.history.filter(path => !!path);

  if (history.length > 1) {
    if (undo) return router.replace(history[history.length - 1] ? history[history.length - 1] + router.location.search : router.goBack());
    if (isLegacy) return router.replace(history[history.length - 2] ? history[history.length - 2] + router.location.search : router.goBack());

    return router.goBack();
  }

  let link = '';
  if (store.previous.params.entity_id) {
    if (location.search) link = `/entities/${store.previous.params.entity_id}/messages${location.search}`;
    else link = `/entities/${store.previous.params.entity_id}/messages`;
  } else if (store.previous.params.organization_id) link = `/organizations/${store.previous.params.organization_id}/messages`;
  else link = '/';

  return router.push(link);
};
