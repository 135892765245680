import { validateCharges } from '../validateCharges';

describe('given validateCharges util', () => {
  const charge1Description = 'Charge 1 description';
  const charge2Description = 'Charge 2 description';
  const mockedCharges = [
    {
      name: 'Charge 1',
      description: charge1Description,
      amount: 1000,
      organizationAccountId: 'organizationAccountId',
      paymentMethods: ['PIX', 'CARD'],
      allowedInstallments: 12,
      absorbPix: true,
      absorbBoleto: false,
      absorbCard: true,
    },
    {
      name: 'Charge 2',
      description: charge2Description,
      amount: 2000,
      organizationAccountId: 'organizationAccountId',
      paymentMethods: ['PIX', 'CARD'],
      allowedInstallments: 12,
      absorbPix: true,
      absorbBoleto: false,
      absorbCard: true,
    },
  ];

  it('should be able to validate charges', () => {
    const validCharges = validateCharges(mockedCharges);

    expect(validCharges).toEqual([
      {
        name: 'Charge 1',
        description: 'Charge 1 description',
        amount: 1000,
        organizationAccountId: 'organizationAccountId',
        paymentMethods: ['PIX', 'CARD'],
        allowedInstallments: 12,
        absorbedFee: ['PIX', 'CARD'],
      },
      {
        name: 'Charge 2',
        description: 'Charge 2 description',
        amount: 2000,
        organizationAccountId: 'organizationAccountId',
        paymentMethods: ['PIX', 'CARD'],
        allowedInstallments: 12,
        absorbedFee: ['PIX', 'CARD'],
      },
    ]);
  });

  it('should not be able have PIX if absorbPix is false', () => {
    const invalidCharges = [
      {
        ...mockedCharges[0],
        absorbPix: false,
      },
    ];

    const validCharges = validateCharges(invalidCharges);

    expect(validCharges).toEqual(
      expect.arrayContaining([
        expect.objectContaining({
          absorbedFee: ['CARD'],
        }),
      ]),
    );
  });

  it('should not be able have CARD if absorbCard is false', () => {
    const invalidCharges = [
      {
        ...mockedCharges[0],
        absorbCard: false,
      },
    ];

    const validCharges = validateCharges(invalidCharges);

    expect(validCharges).toEqual(
      expect.arrayContaining([
        expect.objectContaining({
          absorbedFee: ['PIX'],
        }),
      ]),
    );
  });

  it('should not be able have BOLETO if absorbBoleto is false', () => {
    const invalidCharges = [
      {
        ...mockedCharges[0],
        absorbBoleto: false,
      },
    ];

    const validCharges = validateCharges(invalidCharges);

    expect(validCharges).toEqual(
      expect.arrayContaining([
        expect.objectContaining({
          absorbedFee: ['PIX', 'CARD'],
        }),
      ]),
    );
  });
});
