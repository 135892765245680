import React from 'react';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router';
import {
  Menu, Icon, Divider, Segment, Label, Grid, /*  Dropdown, Popup, */ Header, Button, Image, Container
} from 'semantic-ui-react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import { filter } from 'graphql-anywhere';
import { isEqual } from 'lodash';
// import moment from 'moment';
// import { CopyToClipboard } from 'react-copy-to-clipboard';

import Modal from '../../../../components/Modal';
import Avatar from '../../../../components/Avatar';
import FeaturesModal from '../../../../components/FeaturesModal';

import ElementModifier from '../../../../components/ElementModifier';
import Responsive from '../../../../components/Responsive';
import ReviewStatusTooltip from '../../../../components/ui/ReviewStatusTooltip';

import MessageSurveyResults from '../../ui/MessageSurveyResults';

/* import MessageMedias from '../../Medias';
import MessageItem from '../../Item'; */
import MessageFiles from '../../Files';
import MessageReplies from '../../Replies';

import { MessageReportResults } from '../../ui/MessageReportResults';
import MessageCharges from '../../Charges';
import MessageCommitmentReminds from '../../CommitmentReminds';
import EntityItem from '../../../Entity/Item';
import MessageFormResults from '../../FormResults';
// import MessageSignatures from '../../Signatures';
// import ReviewStatusTooltip from '../../../../components/ui/ReviewStatusTooltip';
import onClose from '../../utils/onClose';
import MessageStatus from './MessageStatus';

import MessageLabel from './MessageLabel';

import * as utils from '../../../../utils';
import { __ } from '../../../../i18n';
import { getChargesFromInput } from '../../Edit/utils/getChargesFromInput';
import { MessageMedias } from '../../ui/MessageMedias';
import { getDetailMediasFromInput } from '../../utils/getDetailMediasFromInput';
import { getDetailCommitmentsFromInput } from '../../utils/getDetailCommitmentsFromInput';
import { getReportsFromHelpers } from '../../Edit/utils/getReportsFromHelpers';
import { filterEntitiesWithResultsInReportData } from '../../Edit/utils/filterEntitiesWithResultsInReportData';
import { getFormsFromHelpers } from '../../Edit/utils/getFormsFromHelpers';
import { getSurveysFromInput } from '../../Edit/utils/getSurveysFromInput';
// import MoreOptionsMenu from '../ui/MoreOptionsMenu';

const styles = {
  moreRecipients: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    width: '32px',
    height: '32px',
    backgroundColor: '#9696a0',
    color: '#fff',
    fontWeight: 'bold',
    borderRadius: '4em',
    border: 'solid 2px #ffffff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
};

/**
 * @typedef {{
 *  id: number,
 *  subject: string,
 *  content: string,
 *  summary: string,
 *  public: number,
 *  created?: string,
 *  modified?: string,
 *  recipientsCount: number,
 *  deleted?: string,
 *  fwMessageId: number,
 *  entity: {
 *    id: number,
 *    type: 'ADMIN' | 'STAFF' | 'STUDENT',
 *    isChannel: boolean,
 *    roles: {
 *     nodes: {
 *      id: number,
 *      name: string
 *     },
 *    },
 *    organizationId: number,
 *    fullname: string,
 *    shortname: string,
 *    seeAll: boolean,
 *    code: number,
 *    disabled: boolean,
 *    picture: {
 *    id: number,
 *   },
 *   organization: {
 *    id: number,
 *    type: string,
 *   },
 *  },
 *  user: {
 *    id: number,
 *    fullname: string,
 * }
 * }} MessageNodeEntityQuery
 */

@inject('store', 'client')
@graphql(gql`query MessageNodeEntityQuery($entityId: ID!, $messageId: ID!) {
  node(id: $entityId){
    ... on Entity {
      id: dbId
      messageToApprove(id: $messageId) {
        id
        subject
        content
        summary
        public
        input
        label {
          id
          title
          color
          modified
          created
        }
        helpers
        created
        modified
        recipientsCount
        sendAt
        deleted
        fwMessageId
        entity {
          id: dbId
          type
          isChannel
          roles {
            nodes {
              id: dbId
              name
            }
          }
          organizationId
          fullname
          shortname
          seeAll
          code
          disabled
          picture {
            id
          }
          organization {
            id
            type
          }
        }
        toEntity {
          id
          isChannel
          status
          organizationId
          id
          fullname
          shortname
          seeAll
          code
          disabled
          type
          picture {
            id
          }
          organization {
            id
            type
          }
        }
        user {
          id: dbId
          fullname
        }
      }
    }
  }
}
  `, {
  options: ownProps => ({
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache', // ownProps.location.query.forceFetch ? 'network-only' : 'cache-first',
    variables: {
      entityId: ownProps.params.entity_id,
      messageId: ownProps.params.message_id
    }
  }),
  skip: ownProps => !ownProps.params.entity_id
  // || ownProps.location.search.search('forwarded=true') == -1
  // || ownProps.location.search.search('deletedForMe=true') > -1
})
@observer
class MessageApproveModal extends Responsive {
  constructor(props) {
    super(props);

    /* const { params, store } = props;
    const userHasEntity = params.entity_id && store.currentUser.entities &&
      (store.currentUser.entities.filter(entity => entity.code && entity.id && entity.id.toString() === params.entity_id).length > 0); */

    this.state = {
      information: false,
      // userHasEntity,
      // writingReply: false,
    };
  }

  /* componentDidMount() {
    const { params, client } = this.props;
    const { userHasEntity } = this.state;

    if (!userHasEntity) return;

    client.query({
      query: gql`query MessageNodeEntityUpdateQuery (
        $entityId: ID!,
        $messageId: ID!,
        $updateStatus: RecipientStatus,
        $deleteNotifications: Boolean,
        $updateAccessed: Boolean
      ) {
          node(id: $entityId) @connection(key: "Entity", filter: ["entityId"]) {
            ... on Entity {
              id: dbId
              message (id: $messageId, deleteNotifications: $deleteNotifications , updateAccessed: $updateAccessed, updateStatus: $updateStatus ) {
                id: dbId
              }
            }
          }
        }`,
      fetchPolicy: 'network-only',
      variables: {
        entityId: params.entity_id,
        messageId: params.message_id,
        updateStatus: userHasEntity ? 'READ' : undefined,
        deleteNotifications: userHasEntity,
        updateAccessed: userHasEntity
      }
    });
  } */

  shouldComponentUpdate(nextProps) {
    // fix for apollo bug, node disappears.. walle#3219
    if (this.props.data.node && !nextProps.data.node && isEqual(this.props.data.variables, nextProps.data.variables)) return false;

    return true;
  }

  handleOnClose = () => {
    const { store, router } = this.props;

    if (store.history.length > 1) {
      router.goBack();
    } else {
      const link = store.previous.params.entity_id ? `/entities/${store.previous.params.entity_id}/messages` : '/';

      router.push(link);
    }
  }

  /* onClose = () => {
    onClose(false,store,null, () => {
      console.log("onClose");
    })
    const { writingReply } = this.state;
    const { store } = this.props;

    const onClick = () => {
      this.closeAction();
      store.appends.pop();
      this.setState({ onLeave: true }, () => {
        store.snackbar = { active: true, message: __('Reply discarded'), success: true };
      });
    }

    onClose(writingReply, store, this.closeAction, onClick, this.props.object)
  }  */

  /* closeAction = async () => {
    const { store, router, data, client, location } = this.props;
    const { userHasEntity } = this.state;
    if (userHasEntity) {
      await data.updateQuery((previousResult) => {
        const nextResult = cloneDeep(previousResult);
        const forwarded = location.search.search('forwarded=true') != -1;

        if (nextResult.node.messageToApprove.unread !== null && !forwarded) {
          nextResult.node.messages.totalCount -= 1;
        }

        nextResult.node.messageToApprove.unread = null;
        nextResult.node.message.notifications.totalCount = 0;

        return nextResult;
      });

      client.query({
        query: gql`query MessageNodeUserNotificationsQuery {
          viewer {
            id: dbId
            notifications {
              totalCount
            }
          }
        }`,
        fetchPolicy: 'network-only'
      });
    }

    if (store.history.length > 1) {
      router.goBack();
    } else {
      const link = store.previous.params.entity_id ? `/entities/${store.previous.params.entity_id}/messages` :
        (store.previous.params.organization_id ? `/organizations/${store.previous.params.organization_id}/messages` : '/');
      router.push(link);
    }
  } */

  /**
   * @param {'AS_UNREAD' | 'ARCHIVED' | 'UNARCHIVED'} to
   * @param {boolean} [hasUndoOption]
   */
  /* updateStatus = async (to, hasUndoOption = true) => {
    this.props.super.setLoading(true);
    if (hasUndoOption) this.setState({ loading: true });

    const { params, router, updateRecipient, location } = this.props;

    let snackbarMessage;
    let onClick;

    switch (to) {
      case 'UNARCHIVED':
        snackbarMessage = __('Message moved to inbox');
        onClick = () => this.updateStatus('ARCHIVED', false);
        break;
      case 'AS_UNREAD':
        snackbarMessage = __('Message marked as unread');
        onClick = () => this.updateStatus('READ', false);
        break;
      case 'ARCHIVED':
      default:
        snackbarMessage = __('Message archived');
        onClick = () => this.updateStatus('UNARCHIVED', false);
        break;
    }

    if (!hasUndoOption) snackbarMessage = __('Action successfully undone');

    await updateRecipient({
      variables: {
        updateRecipientMutation: {
          messageId: params.message_id,
          entityId: params.entity_id,
          status: to,
          legacyMode: false,
        }
      }
    });

    if (hasUndoOption) router.goBack();
    await this.props.super.refetch();

    utils.clearSnackbar(this.props.store);

    const snackbar = { active: true, message: snackbarMessage, success: true, action: hasUndoOption ? __('Undo') : __('Close'), dismissAfter: 10000 };
    if (hasUndoOption) snackbar.onClick = onClick;

    this.props.store.snackbar = snackbar;
    this.props.super.setLoading(false);
  } */

  /* recoverMessage = async (hasUndoOption = true, messageStatus = null) => {
    this.props.super.setLoading(true);
    const { recoverForMe, data, store } = this.props;
    const request = {
      mutate: recoverForMe,
      input: 'DeleteMessageStatusMutation',
      message: hasUndoOption ? __('Message recovered') : __('Action successfully undone'),
      onClick: hasUndoOption ? () => this.deleteMessage(false) : null
    };

    const id = messageStatus ? messageStatus.id : data.node.message.statuses.nodes[0].id;

    return request.mutate({
      variables: {
        [request.input]: {
          id
        }
      }
    }).then(async () => {
      if (this.props.super.resetStore) await this.props.super.resetStore();
      if (hasUndoOption) this.backAndRefresh();
      this.props.super.setLoading(false);

      utils.clearSnackbar(store);

      store.snackbar = {
        active: true,
        message: request.message,
        success: true,
        action: hasUndoOption ? __('Undo') : __('Close'),
        dismissAfter: 10000,
        onClick: request.onClick
      };
    }).catch((err) => {
      console.error(err);

      utils.clearSnackbar(store);

      this.props.store.snackbar = {
        active: true,
        message: __('An error occurred while recovering message'),
        success: false,
        dismissAfter: 5000
      };
    });
  } */

  /* deleteMessage = (hasUndoOption = true) => {
    this.props.super.setLoading(true);
    const { params, deleteForMe, store } = this.props;
    const request = {
      mutate: deleteForMe,
      input: 'CreateMessageStatusMutation',
      message: hasUndoOption ? __('Message deleted') : __('Action successfully undone'),
    };

    const props = {};

    props.messageId = params.message_id;
    props.entityId = store.entity.id;
    props.status = 'DELETED';

    return request.mutate({
      variables: {
        [request.input]: {
          ...props
        }
      }
    }).then(async (response) => {
      if (this.props.super.resetStore) await this.props.super.resetStore();
      this.props.super.setLoading(false);
      const { messageStatus } = response.data.createMessageStatus;

      utils.clearSnackbar(store);

      store.snackbar = {
        active: true,
        message: request.message,
        success: true,
        action: hasUndoOption ? __('Undo') : __('Close'),
        dismissAfter: 10000,
        onClick: hasUndoOption ? () => this.recoverMessage(false, messageStatus) : null
      };

      if (hasUndoOption) this.backAndRefresh();
    }).catch((err) => {
      console.error(err);

      utils.clearSnackbar(store);

      this.props.store.snackbar = {
        active: true,
        message: __('An error occurred while deleting message'),
        success: false,
        dismissAfter: 5000
      };
    });
  } */

  backAndRefresh = () => {
    const { store, router, location } = this.props;

    if (store.history.length > 1) {
      router.goBack();
    } else {
      let link = '';
      if (store.previous.params.entity_id) {
        if (location.search) link = `/entities/${store.previous.params.entity_id}/messages${location.search}`;
        else link = `/entities/${store.previous.params.entity_id}/messages`;
      } else if (store.previous.params.organization_id) link = `/organizations/${store.previous.params.organization_id}/messages`;
      else link = '/';
      router.push(link);
    }
  }

  openPaywall() {
    this.props.store.appends.push(<FeaturesModal feature="DELETE_MESSAGE" />);
  }

  /* reload = async () => {
    const { data } = this.props;
    this.setState({ loading: true });
    await data.refetch();
    this.setState({ loading: false });
  } */

  render() {
    const {
      data, params, store, location
    } = this.props;
    const { information, /* userHasEntity, */ loading } = this.state;
    const { app } = store;
    /* const hasMessageApproval = store.currentOrganization && store.currentOrganization.unleashStatus && store.currentOrganization.unleashStatus.approve_message
      && store.currentOrganization.plan === 'PREMIUM'; */

    if (!params.organization_id && ((data && data.node && data.node.messageToApprove && data.node.messageToApprove.deleted) || (data && data.error && data.error.message === 'GraphQL error: Can\'t access deleted messages'))) {
      return (
        <Modal
          size="small"
          closeOnRootNodeClick={false}
        >
          <Modal.Content style={{ backgroundColor: '#ffffff', borderRadius: '4px', boxShadow: '0 1px 0 0 #dededf' }}>
            <Container textAlign="center">
              <Image
                src={utils.asset('/images/deletedMessage.png')}
                style={{
                  width: '300px',
                  height: '300px',
                  margin: 'auto',
                  marginTop: '48px'
                }}
              />
              <div style={{ display: 'grid', marginTop: '24px' }}>
                <span style={{ fontSize: '18px', fontWeight: '500', color: '#212529' }}>{__('Unavailable Message')}</span>
                <span style={{
                  fontSize: '14px', color: '#868e96', marginBottom: '48px', marginTop: '8px'
                }}
                >
                  {__('This message has been deleted and can no longer be viewed!')}
                </span>
              </div>
            </Container>
          </Modal.Content>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              data-action="goBack"
              style={{
                color: '#666666', margin: '14px', backgroundColor: 'white', border: '.5px solid rgba(102, 102, 102, 0.3)'
              }}
              key={1}
              primary
              content={__('Close')}
              onClick={() => {
                this.setState({ onLeave: true }, () => {
                  this.backAndRefresh();
                });
              }}
            />
          </div>
        </Modal>
      );
    } if (data.error) {
      return (
        <Modal
          header={__('Error')}
          content={__('An error has occurred.')}
          actions={[<Button
            data-action="cancel"
            key={1}
            primary
            content={__('OK')}
            onClick={() => {
              this.setState({ onLeave: true }, () => {
                this.props.router.goBack();
              });
            }}
          />]}
        />
      );
    } if ((data.loading && !data.node) || !data.node || loading) { return <Modal loading />; }

    const {
      type, organizationId, disabled, messageToApprove
    } = data.node;
    const messageToApproveInput = JSON.parse(messageToApprove.input);
    const charges = getChargesFromInput(messageToApprove?.input);
    const medias = getDetailMediasFromInput(messageToApprove?.input, store?.app?.env);
    const messageType = 'SENT';
    const commitments = getDetailCommitmentsFromInput(messageToApprove?.input);
    const forms = getFormsFromHelpers(messageToApprove?.helpers);
    const surveys = getSurveysFromInput(messageToApprove.input);

    const reports = filterEntitiesWithResultsInReportData(getReportsFromHelpers(messageToApprove.helpers));

    /* if (params.organization_id) {
      message = data.node;
    } */

    /* const baseLink = params.entity_id ?
      `/entities/${params.entity_id}` : `/organizations/${params.organization_id}`; */

    const baseLink = `/entities/${params.entity_id}`;

    /* let publicContent = '';

    if (message.public && message.content) {
      publicContent = message.content.replace(/<\/(p|h[1-6])>/gm, '\n').replace(/<br>/gm, '@').replace(/<(?:.|\n)*?>/gm, '');
    } */

    const entityLink = e => ((store.currentUser.isMaster /* || params.organization_id */ || (type === 'ADMIN' && organizationId === messageToApprove.entity.organizationId)) && e.organizationId
      ? `/organizations/${e.organizationId}/${e.isChannel ? 'channels' : 'entities'}?search=${e.fullname}` : null);

    const isMessageOwner = !!messageToApprove.user
      && !!messageToApprove.entity
      && store.currentEntity.id === messageToApprove.entity.id
      && store.currentUser.id === messageToApprove.user.id;

    // const isMessageWriter = utils.isMessageWriter({ store })

    /* const canModifyMessage = (
      store.currentEntity.type === 'ADMIN'
      && message.entity.type !== 'STUDENT'
      && (message.type === 'SENT' || params.organization_id)
    ); */

    // const messageReview = message && message?.review;
    // const isWriterAndApproved = isMessageWriter && messageReview && messageReview?.reviewStatus === 'approved';

    // const { features } = store.currentOrganization || {};

    let newParams = params;
    if (isMessageOwner) {
      newParams = { ...params, entity_id: store.currentEntity.id };
    }

    /* const canDeleteMessage = (
      (features && features.deleteMessages)
      && (isMessageOwner || canModifyMessage)
      && (moment(message.created) >= moment().subtract(3, 'days'))
      && !message.deleted
    ) || (store.currentUser.isMaster && !message.deleted); */

    // const isDeletedForMe = location.query.deletedForMe;
    return (
      <Modal
        id="MessageNode"
        open
        size="small"
        closeIcon="times close inside"
        onClose={() => onClose(false, store, this.handleOnClose, null, null)}
        closeOnRootNodeClick={false}
        key={messageToApprove.id}
      >
        <Modal.Header style={{ padding: '0.5em' }}>
          <Menu compact secondary>
            {
              /* (store.currentUser.isMaster || message.type === 'SENT' || params.organization_id || (type === 'ADMIN' && organizationId === message.entity.organizationId)) && !isDeletedForMe &&
              (
                this.isMobile() ? <Menu.Item as={Link} to={baseLink + `/messages/${params.message_id}/entities`} icon><Icon data-name="status" name="info circle" /></Menu.Item>
                  : <Popup
                    trigger={
                      <Menu.Item as={Link} to={baseLink + `/messages/${params.message_id}/entities`} icon><Icon data-name="status" name="info circle" /></Menu.Item>
                    }
                    content={__('Status')}
                  />
              ) */
            }
            {
              /* (organizationId && type !== 'STUDENT' && !disabled && store.currentEntity.id == params.entity_id) && !isDeletedForMe &&
              (
                !message.fwMessage ?
                  <Popup
                    trigger={<Menu.Item
                      as={Link}
                      to={`/entities/${params.entity_id || store.currentEntity.id}/messages/add?fwMessageId=${params.message_id}`}
                      icon
                    >
                      <Icon data-name="forward" name="share" />
                    </Menu.Item>}
                    content={__('Forward')}
                  />
                  :
                  <Popup
                    trigger={<Menu.Item
                      icon
                      disabled
                    >
                      <Icon data-name="forward" name="share" />
                    </Menu.Item>}
                    content={<span style={{ display: 'inline-block' }}>
                      <Icon name="exclamation triangle" color="red" />
                      {__('You can\'t forward an forwarded message!')}
                    </span>}
                  />
              ) */
            }
            {
              /* message.public && !isDeletedForMe ?
                <Popup
                  trigger={<Dropdown data-action="open-options" data-params="share" icon="share alt" className="link item icon" >
                    <Dropdown.Menu>
                      <Dropdown.Item
                        data-name="facebook"
                        as="a"
                        target="_blank"
                        rel="noreferrer noopener"
                        href={`https://www.facebook.com/dialog/share?app_id=192130234603738&quote=${message.summary}&display=page&href=https://${app.host}/messages/${params.message_id}/&redirect_uri=https://${app.host}/`}
                        text={__('Facebook')}
                        icon="facebook f brand"
                      />
                      <Dropdown.Item
                        data-name="twitter"
                        as="a"
                        target="_blank"
                        rel="noreferrer noopener"
                        href={`https://twitter.com/share?url=https://${app.host}/messages/${params.message_id}/&text=${message.summary}`}
                        text={__('Twitter')}
                        icon="twitter brand"
                      />
                      <Dropdown.Item
                        data-name="email"
                        as="a"
                        target="_blank"
                        rel="noreferrer noopener"
                        href={`mailto:?body=${publicContent}%0D%0A%0D%0Ahttps://${app.host}/messages/${params.message_id}/&subject=${message.subject}`}
                        text={__('Email')}
                        icon="envelope"
                      />
                      <CopyToClipboard text={`${app.env === 'production' ? 'https' : 'http'}://${app.host}/messages/${params.message_id}`}>
                        <Dropdown.Item
                          data-name="link"
                          as="a"
                          target="_blank"
                          rel="noreferrer noopener"
                          onClick={() => {
                            this.props.store.snackbar = {
                              active: true,
                              message: __('Message link copied'),
                              success: true
                            };
                          }}
                          text={__('Copy sharable link')}
                          icon="link"
                        />
                      </CopyToClipboard>
                    </Dropdown.Menu>
                  </Dropdown>}
                  content={__('Share')}
                />
                :
                null */
            }
            {
              /* !isDeletedForMe && <MoreOptionsMenu
                isMobile={this.isMobile()}
                message={message}
                isMessageOwner={isMessageOwner}
                canModifyMessage={canModifyMessage}
                hasEditMessagesFeature={features && features.editMessages}
                hasDeleteMessagesFeature={features && features.deleteMessages}
                isMaster={store.currentUser.isMaster}
                isWriterAndApproved={isWriterAndApproved}
                store={store}
                newParams={newParams}
                location={location}
                params={params}
                userHasEntity={userHasEntity}
                canDeleteMessage={canDeleteMessage}
                baseLink={baseLink}
              /> */
            }
          </Menu>
        </Modal.Header>
        <Modal.Content style={{ paddingBottom: 24 }}>
          <Segment.Group style={{ margin: 0 }}>
            <Segment secondary>
              <Grid>
                <Grid.Row columns={2}>
                  <Grid.Column width={13} style={{ display: 'flex', alignItems: 'center' }}>
                    {
                      messageToApprove.recipientsCount === 1
                        ? (
                          <span className="thirteen wide column" style={{ display: 'flex' }}>
                            {messageToApprove.toEntity && <EntityItem to={entityLink(messageToApprove.toEntity)} entity={filter(EntityItem.fragments.entity, messageToApprove.toEntity)} fromMessage />}
                          </span>
                        )
                        : (
                          <div style={{
                            width: '40px', height: '40px', position: 'relative', marginBottom: 3
                          }}
                          >
                            <Avatar
                              avatar
                              src={messageToApprove.toEntity && messageToApprove.toEntity.picture && messageToApprove.toEntity.picture.uri}
                              alt={messageToApprove.toEntity && messageToApprove.toEntity.fullname}
                              style={messageToApprove.deleted ? (messageToApprove.recipientsCount > 1 ? { width: '32px', height: '32px', opacity: 0.65 } : { width: '40px', height: '40px', opacity: 0.65 }) : (messageToApprove.recipientsCount > 1 ? { width: '32px', height: '32px' } : { width: '40px', height: '40px' })}
                            />
                            {
                              messageToApprove.recipientsCount > 1
                                ? (
                                  <div style={styles.moreRecipients}>
                                    +
                                    {messageToApprove.recipientsCount - 1}
                                  </div>
                                )
                                : null
                            }
                          </div>
                        )
                    }
                    <div style={{ display: 'inline-block', marginLeft: '10px' }}>
                      <div>
                        {
                          messageToApprove.recipientsCount === 1
                            ? (
                              <span style={{ display: 'flex', alignItems: 'baseline' }}>
                                <span>{__('to')}</span>
                                {
                                  messageToApprove.toEntity
                                  && (
                                    entityLink(messageToApprove.toEntity)
                                      ? (
                                        <Link
                                          to={entityLink(messageToApprove.toEntity)}
                                          style={{
                                            color: '#007fff', fontWeight: 500, fontSize: '16px', paddingLeft: '5px'
                                          }}
                                        >
                                          {messageToApprove.toEntity.fullname}
                                        </Link>
                                      )
                                      : <span style={{ fontWeight: 500, fontSize: '16px', paddingLeft: '5px' }}>{messageToApprove.toEntity.fullname}</span>
                                  )
                                }
                              </span>
                            )
                            : (
                              <span style={{ display: 'flex', alignItems: 'baseline' }}>
                                <span>{__('to')}</span>
                                <Link
                                  // to={baseLink + `/messages/${params.message_id}/entities`}
                                  style={{
                                    color: 'rgba(0,0,0,.6)', /* color: '#007fff',  */fontWeight: 500, fontSize: '16px', paddingLeft: '5px'
                                  }}
                                >
                                  {messageToApprove.recipientsCount.toString() + ' ' + __('recipients')}
                                </Link>
                              </span>
                            )
                        }
                      </div>
                      <div style={{ whiteSpace: 'nowrap', color: '#9696a0', fontSize: '12px' }}>
                        <span>{utils.simpleDate(messageToApprove.modified, true, 'LLL', app.locale)}</span>
                        {!this.isMobile()
                          && <MessageStatus message={messageToApprove} isMobile={this.isMobile()} />}
                      </div>

                      {this.isMobile() && (
                        <div style={{ whiteSpace: 'nowrap', color: '#9696a0', fontSize: '12px' }}>
                          <MessageStatus message={messageToApprove} isMobile={this.isMobile()} />
                        </div>
                      )}
                    </div>
                  </Grid.Column>
                  <Grid.Column data-action="toggle-information" textAlign="right" width={3} onClick={() => this.setState({ information: !information })} />
                </Grid.Row>
                {
                  /* information && message.tags.nodes.length > 0 &&
                  <Grid.Row>
                    <Grid.Column>
                      <Icon name="tag" />
                      {message.tags.nodes.map(tag =>
                        <Label data-name="label" data-value={tag.name} basic key={tag.id}>
                          {tag.name}
                        </Label>
                      )}
                    </Grid.Column>
                  </Grid.Row> */
                }
                {
                  information && messageToApprove.entity.type === 'STUDENT' && messageToApprove.entity.roles.nodes.length > 0
                  && (
                    <Grid.Row>
                      <Grid.Column>
                        <Icon name="graduation cap" />
                        {messageToApprove.entity.roles.nodes.map((role, key) => (
                          <Label basic key={key}>
                            {(role.name.length > 36) ? (role.name.substring(0, 36) + '...') : role.name}
                          </Label>
                        ))}
                      </Grid.Column>
                    </Grid.Row>
                  )
                }
              </Grid>
              {
                /* isDeletedForMe &&
                <Grid style={{ background: '#00000029' }}>
                  <Grid.Row>
                    <Grid.Column>
                      {userHasEntity ?
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                          <span>
                            <Icon name="trash" style={{ margin: '0 0.25rem 0 0', fontWeight: '300' }} />
                            {__('Message deleted for you on %s', utils.simpleDate(message.statuses.nodes[0].created, false, 'lll', app.locale).toLowerCase())}
                          </span>
                          <button
                            style={{ border: 'none', background: 'transparent', color: '#084FFF', fontWeight: 'bold', cursor: 'pointer' }}
                            onClick={() => this.recoverMessage()}
                          >{__('Recover')}</button>
                        </div>
                        : message.statuses.nodes.length === 1 ?
                          <span>
                            <Icon name="trash" style={{ margin: '0 0.25rem 0 0', fontWeight: '300' }} />
                            {utils.parseFormattedTranslation(
                              {
                                text: __(
                                  'Message deleted for **%s** on %s',
                                  message.statuses.nodes[0].user.fullname,
                                  utils.simpleDate(message.statuses.nodes[0].created, false, 'lll', app.locale).toLowerCase()
                                ),
                                Wrapper: ({ children }) =>
                                  <span style={{ fontWeight: 'bold' }}>{utils.renderLongText(children, 13)}</span>
                              }
                            )}
                          </span>
                          : [
                            <Icon name="trash" style={{ margin: '0 0.25rem 0 0', fontWeight: '300' }} />,
                            utils.parseFormattedTranslation(
                              {
                                text: __('Message deleted for **%s users**', message.statuses.nodes.length),
                                Wrapper: ({ children }) =>
                                  <Popup
                                    basic
                                    className="deletedForUserList"
                                    trigger={
                                      <span
                                        style={{
                                          fontWeight: 'bold',
                                          textDecoration: 'underline',
                                          cursor: 'pointer'
                                        }}
                                        className="lighten-on-hover"
                                      >
                                        {children}
                                      </span>
                                    }
                                    on="click"
                                    header={
                                      <div className="userListHeader">
                                        {__('Deleted for')}
                                      </div>
                                    }
                                    content={
                                      <ul className="userList">
                                        {message.statuses.nodes.map(({ created, user, id }) => (
                                          <li key={id}>
                                            <span>{utils.renderLongText(user.fullname, 13)}</span> <time>{__('on %s', utils.simpleDate(created, false, 'lll', app.locale).toLowerCase())} </time>
                                          </li>
                                        ))}
                                      </ul>
                                    }
                                    position="bottom left"
                                    wide="very"
                                  />
                              }
                            )]
                      }
                    </Grid.Column>
                  </Grid.Row>
                </Grid> */
              }
            </Segment>

            {
              /* hasMessageApproval && messageReview &&
              <ReviewStatusTooltip
                status={messageReview?.reviewStatus === 'approved' ? 'approved' : 'inReview'}
                entityFullname={messageReview?.user && messageReview?.user?.fullname}
                date={messageReview?.reviewIn}
              /> */
            }
            { messageToApprove.sendAt && (
            <Segment className="tooltip-segment">
              <ReviewStatusTooltip date={utils.simpleDate(messageToApprove.sendAt, true, 'DD MMM, HH:mm', app.locale)} status="sendLater" />
            </Segment>
            )}
            <Segment className="wordWrapped">

              {
                (!!messageToApproveInput.subject || !!messageToApprove.label)
                && (
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    {messageToApproveInput.subject ? <Header>{messageToApproveInput.subject}</Header> : <div />}
                    <MessageLabel label={messageToApprove.label} />
                  </div>
                )
              }
              {!!messageToApproveInput.content && (
                <div
                  className="content clsp-content ql-editor"
                  dangerouslySetInnerHTML={{
                    __html: messageToApproveInput.content
                  }}
                />
              )}
              <Divider hidden />
              {
                /* message.fwMessage &&
                <div style={{ marginBottom: '1em' }}>
                  <Menu.Item data-action="open-forwarded-message" as={Link} to={baseLink + `/messages/${message.fwMessage.id}?forwarded=true`}>
                    <Segment>
                      <MessageItem message={filter(MessageItem.fragments.entityMessage, message.fwMessage)} />
                    </Segment>
                  </Menu.Item>
                </div> */
              }
              <MessageMedias images={medias.images} videos={medias.videos} audios={medias.audios} />
              <MessageFiles files={medias.files} />
              <MessageCommitmentReminds params={params} messageType={messageType} entity={{ disabled }} fromHistory isMessageToApprove commitments={commitments} />
              <MessageReportResults params={params} messageType={messageType} reports={reports} />
              <MessageSurveyResults surveys={surveys} params={params} entity={{ disabled }} />
              {/* <MessageCommitmentReminds params={params} messageType={message.type} entity={{ disabled }} />
              <MessageReportResults params={params} messageType="SENT" reports={reports} />
              <MessageSurveyResults surveys={surveys} params={params} entity={{ disabled }} />
              {/*
              <MessageCommitmentReminds params={params} messageType={message.type} entity={{ disabled }} />
              <MessageSurveyResults params={params} messageType={message.type} entity={{ disabled }} />
              */}
              <MessageCharges
                charges={charges}
                params={params}
                reload={() => this.reload()}
                messageType={messageToApprove.entity.type}
                entity={messageToApprove.entity}
                messageToApprove={messageToApprove}
              />
              <MessageFormResults
                params={params}
                messageType={messageToApprove.entity.type}
                entity={messageToApprove.entity}
                forms={forms}
                messageToApprove={messageToApprove}
              />
              {/*<MessageSignatures signatures={message.signatures} params={params} messageType={message.type} entity={{ disabled }} updateQuery={data.refetch} />  */}
            </Segment>
          </Segment.Group>
          {
            this.props.children && React.cloneElement(
              this.props.children,
              {
                super: this.props.super
              }
            )
          }
          {!this.isMobile() && <ElementModifier element={document.body} newClassName="paddingOnModalDimmer" />}
        </Modal.Content>
      </Modal>
    );
  }
}

export default MessageApproveModal;
