import React from 'react';
import { inject, observer } from 'mobx-react';
import { Icon } from 'semantic-ui-react';
import { Link } from 'react-router'

export class ApproveItemView extends React.Component {
  /**
   * @param {{
   *  toApprove?: boolean,
   *  handleApprove?: () => void,
   *  handleReprove?: () => void,
   * }} props 
   */
  constructor(props) {
    super(props);
    this.state = {
      approveIsFocused: false,
      reproveIsFocused: false,
      editIsFocused: false
    };
  }

  _handleFocusApprove = () => {
    this.setState({ approveIsFocused: true });
  }

  _handleBlurApprove = () => {
    this.setState({ approveIsFocused: false });
  }

  _handleFocusReprove = () => {
    this.setState({ reproveIsFocused: true });
  }

  _handleBlurReprove = () => {
    this.setState({ reproveIsFocused: false });
  }

  _handleFocusEdit = () => {
    this.setState({ editIsFocused: true });
  }

  _handleBlurEdit = () => {
    this.setState({ editIsFocused: false });
  }

  render() {
    const { approveIsFocused, reproveIsFocused, editIsFocused } = this.state;
    const { handleApprove, handleReprove, goToEdit } = this.props;

    return (
      <div className="approval-button-container">
        <div className="approve-button" data-testid="approve-button" onClick={handleApprove} onMouseEnter={this._handleFocusApprove} onMouseLeave={this._handleBlurApprove}>
          <Icon
            data-testid="approve-icon"
            name="thumbs up"
            className={`icon ${approveIsFocused ? 'duotone' : ''}`}
            fitted
          />
        </div>
        <div className="reprove-button" data-testid="reprove-button" onClick={handleReprove} onMouseEnter={this._handleFocusReprove} onMouseLeave={this._handleBlurReprove}>
          <Icon
            data-testid="reprove-icon"
            name="thumbs up"
            className={`icon ${reproveIsFocused ? 'duotone' : ''}`}
            fitted
            flipped='vertically'
          />
        </div>

        <Link to={goToEdit} className="edit-button" data-testid="edit-button" onMouseEnter={this._handleFocusEdit} onMouseLeave={this._handleBlurEdit}>
          <Icon
            data-testid="edit-icon"
            name="pencil"
            className="icon"
            fitted
          />
        </Link>
      </div>
    );
  }
}

@inject("store") @observer
export default class ApproveItem extends ApproveItemView {
  constructor(props) {
    super(props);
  }
}
