import { createHelpersFromValues } from '../createHelpersFromValues';

describe('createHelpersFromValues', () => {
  it('should return null when no data is provided', () => {
    const params = {
      values: null,
      store: null,
    };
    const result = createHelpersFromValues(params.values, params.store);
    expect(result).toBe(null);
  });

  it('should return Json when valid params is provided', () => {
    const params = {
      values: {
        reports: [],
        forms: [],
        allRecipients: [],
        allGroups: [],
      },
      store: {
        entity: {
          id: '1',
        },
      },
      selectedAll: true,
    };
    const result = createHelpersFromValues(
      params.values,
      params.store,
      params.selectedAll,
    );
    expect(JSON.parse(result)).toEqual({
      reports: [],
      forms: [],
    });
  });
});
