import { createInputMessageToApprove } from '../createInputMessageToApprove';

describe('createInputMessageToApprove', () => {
  it('should return null when passed empty input', () => {
    const values = {};
    const approverId = null;
    const selectedAll = false;
    const expectedOutput = null;
    const result = createInputMessageToApprove(values, approverId, selectedAll);
    expect(result).toEqual(expectedOutput);
  });
});
