import { hasCustomFileButton } from '../../../../app/helpers/hasCustomFileButton';

/**
 *
 * @param {{
 *  plan: string,
 *  unleashStatus: {
 *   custom_media_button: boolean
 *   custom_media: boolean
 *  }
 * }} organization
 * @param {{
 *  type: string
 * }} entity
 */

export function shouldShowButton(organization, entity) {
  if (!organization || !entity) return false;

  const isPremiumPlan = organization?.plan === 'PREMIUM';
  const isAdminOrStaff = entity?.type === 'ADMIN' || entity?.type === 'STAFF';

  return hasCustomFileButton(organization) && isPremiumPlan && isAdminOrStaff;
}
