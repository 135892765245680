import { filterEntitiesWithResultsInReportData } from '../filterEntitiesWithResultsInReportData';

describe('filterEntitiesWithResultsInReportData', () => {
  it('should filter entities without result, but keep other with RADIO results', () => {
    const mock = {
      reports: [
        {
          entities: [
            {
              name: 'entity 1',
              fields: [null, null],
            },
            {
              name: 'entity 2',
              fields: [
                null,
                [null],
                [
                  { value: 'Multiple', selected: false },
                  { value: 'Options', selected: false },
                  { value: 'Avaiable', selected: false },
                ],
                null,
              ],
            },
            {
              name: 'entity 3',
              fields: [null],
            },
          ],
        },
      ],
    };

    const result = filterEntitiesWithResultsInReportData(mock.reports);

    expect(result).toEqual([
      {
        entities: [
          {
            fields: [
              null,
              [null],
              [
                { selected: false, value: 'Multiple' },
                { selected: false, value: 'Options' },
                { selected: false, value: 'Avaiable' },
              ],
              null,
            ],
            name: 'entity 2',
          },
        ],
      },
    ]);
  });
});
