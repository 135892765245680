import { resendCommunication } from '../resendCommunication';
describe('resendCommunication', () => {
  it('should call resendCommunication', () => {
    const client = {
      mutate: jest.fn(),
    };
    resendCommunication(client, 1);
    expect(client.mutate).toHaveBeenCalledWith({
      mutation: expect.anything(),
      variables: {
        input: {
          messageId: 1,
        },
      },
    });
  });
});
