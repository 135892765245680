export const validateSurveys = (surveys) => {
  if (!surveys) return null;

  return surveys.map((c) => {
    if (!c.dateLimit) {
      return {
        name: c.name,
        optionNumber: c.optionNumber,
        options: c.options.map(value => value && value.value !== undefined ? value : { value }),
      };
    }

    const time = c.time ? c.time.split(':').map(t => parseInt(t, 10)) : c.time;
    let timeLimit = '23:59';

    if (time) {
      timeLimit = c.time;
    }
    const dateLimitToDateObject = new Date(c.dateLimit);
    c.dateLimit = new Date(dateLimitToDateObject.getTime() - (dateLimitToDateObject.getTimezoneOffset() * 60000)).toISOString().substring(0, 10) + ' ' + timeLimit;

    return {
      name: c.name,
      optionNumber: c.optionNumber,
      options: c.options.map(value => value && value.value !== undefined ? value : { value }),
      dateLimit: c.dateLimit
    };
  });
};
