import { getSurveysFromInput } from '../utils/getSurveysFromInput';

describe('getSurveysFromInput', () => {
  it('should return [] if input is empty', () => {
    const result = getSurveysFromInput('');
    expect(result).toEqual([]);
  });

  it('should return [] if survey is not provided', () => {
    const result = getSurveysFromInput('{}');
    expect(result).toEqual([]);
  });
});
