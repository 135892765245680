import React from 'react';

import MessageModal from './ui/MessageModal';
import MessageApproveModal from './ui/MessageApproveModal';

export default class MessageNode extends React.Component {
  render() {
    const { location } = this.props;

    if (location?.query?.type === 'messagesToApprove') {
      return <MessageApproveModal {...this.props} />;
    }

    return (
      <MessageModal {...this.props} />
    );
  }
}
