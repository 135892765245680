/**
 * Get the reports and filter entities by the result
 * @param {{
* entities: {
  *  fields: string | null[]
  * }[]
  * }[]} reports
 * @returns {}
 */
export const filterEntitiesWithResultsInReportData = (reports) => (reports || []).map((report) => {
  const entitiesFiltered = (report.entities || []).filter(entity => {
    const fieldsFiltered = (entity.fields || []).filter(field => !!field && (typeof field == 'string' || (typeof field == 'object' && field.filter(item => !!item).length > 0)));
    return fieldsFiltered.length > 0;
  });

  return {
    ...report,
    entities: entitiesFiltered
  };
});
