export function handleUniqueParameter(name, value, type, query, eventReload) {
  const parameters = { ...query };
  if (type === 'array' && value.length <= 1) {
    parameters[name] = value[0];
  } else if (type === 'array') {
    parameters[name] = value.filter(
      (o) => parameters[name].indexOf(o) === -1,
    )[0];
  } else if (value.length) {
    parameters[name] = value;
  } else {
    parameters[name] = '';
  }
  eventReload(parameters);
}
