/*eslint prefer-const: off*/
/*eslint-env es6*/
import React from 'react';
import { inject, observer } from 'mobx-react';
import {
  Card,
  Container,
  Icon
} from 'semantic-ui-react';

import Responsive from '../../../components/Responsive';

import { __ } from '../../../i18n';
import * as utils from '../../../utils';

const styles = {
  option: {
    borderRadius: '100px',
    margin: '8px 0px',
    background: 'rgb(250, 250, 250)',
    minHeight: '48px',
    display: 'flex',
    alignItems: 'center',
    padding: '24px'
  }
};

@inject('store')
@observer
export default class SurveyResultForm extends Responsive {
  renderSurveyOptions = (survey) => {
    let { options } = survey;

    return (
      <div data-id={`survey-result-form-${survey.name}`} key={`survey-result-form-${survey.name}`}>
        {options.map((__option) => (
          <div key={`survey-result-form-option-${__option.value}`} style={styles.option}>
            <span data-name="value" className="bold" style={{ maxWidth: '500px', marginRight: '8px', zIndex: 1 }}>{__option.value}</span>
          </div>
        ))}
      </div>
    );
  }

  renderLimitDate = (survey) => {
    if(!survey.dateLimit) return null;

    const date = new Date(survey.dateLimit);
    const lang = this.props.store.app.locale && this.props.store.app.locale.substring(0, 2);

    return (
      <p style={{ color: '#ffb43c' }}>
        <Icon name="exclamation circle" />
        {__('Expires %s', utils.simpleDate(date, true, 'LL, HH:mm', lang))}
      </p>
    );
  }

  render() {
    let { survey } = this.props;

    if(!survey) return null;

    return (
      <Card
        id="SurveyResultForm"
        key={`survey-result-form-${survey.name}`}
        data-id={`survey-result-form-${survey.name}`}
        fluid
        style={{ marginBottom: '1em' }}
      >
        <Card.Content>
          <Container textAlign="center">
            <div className="horizontallySpacedItems">
              <div className="bold"><Icon name="bar chart" style={{ color: '#0080ff' }} />{survey?.name}</div>
            </div>
            {this.renderSurveyOptions(survey)}
            {this.renderLimitDate(survey)}
          </Container>
        </Card.Content>
      </Card>
    );
  }
}
