import { getDetailCommitmentsFromInput } from '../getDetailCommitmentsFromInput';

describe('getDetailCommitmentsFromInput', () => {
  it('should return commitments', () => {
    const input = JSON.stringify({
      commitments: [
        {
          name: 'test',
          date: 'test',
          time: 'test',
          endDate: 'test',
          endTime: 'test',
          hasDate: true,
          hasTime: true,
          noConfirm: true,
          dateLimit: 'test',
          hasvideocallLink: true,
          videocallLink: 'test',
          videocallType: 'test',
        },
      ],
    });

    expect(getDetailCommitmentsFromInput(input)).toEqual([
      {
        name: 'test',
        date: 'test',
        time: 'test',
        endDate: 'test',
        endTime: 'test',
        hasDate: true,
        hasTime: true,
        noConfirm: true,
        dateLimit: 'test',
        hasvideocallLink: true,
        videocallLink: 'test',
        videocallType: 'test',
      },
    ]);
  });

  it('should return null if no input provided', () => {
    expect(getDetailCommitmentsFromInput(null)).toEqual([]);
  });
});
