/**
 * @param {JSON} helpers
 * @param {object[]} allGroups
 * @param {object[]} allRecipients
 */

export const getRecipientsFromHelpers = (helpers, allGroups, allRecipients) => {
  if (!helpers) return null;

  let recipients = null;

  if (JSON.parse(helpers)) {
    recipients = JSON.parse(helpers).recipients;
  }

  if (recipients) {
    if (!recipients.groups) recipients.groups = {};
    recipients.groups.nodes = allGroups;
    if (!recipients.recipients) recipients.recipients = {};
    recipients.recipients.nodes = allRecipients;
  }

  return recipients;
};
