import { __ } from '../../../i18n';

export function getOnReference() {
  return {
    COMMITMENT: {
      icon: 'calendar',
      start: __('The commitment '),
      middle: __(' on commitment '),
    },
    SURVEY: {
      icon: 'bar chart',
      start: __('The survey '),
      middle: __(' on survey '),
    },
    FORM: {
      icon: 'poll h',
      start: __('The form '),
      middle: __(' on form '),
    },
  };
}
