import { shouldShowButton } from '../shouldShowButton';

describe('shouldShowButton', () => {
  let props;

  beforeEach(() => {
    jest.clearAllMocks();

    props = {
      organization: {
        plan: 'PREMIUM',
        unleashStatus: {
          custom_media_button: true,
          custom_media: true,
        },
      },
      entity: {
        type: 'ADMIN',
      },
    };
  });

  describe('when params is not provided', () => {
    it('should return false when organization is not provided', () => {
      const result = shouldShowButton(null, props.entity);
      expect(result).toBe(false);
    });

    it('should return false when entity is not provided', () => {
      const result = shouldShowButton(props.organization, null);
      expect(result).toBe(false);
    });
  });

  describe('when organization has plan ', () => {
    it('should return false when plan is not PREMIUM', () => {
      props.organization.plan = 'STANDARD';

      const result = shouldShowButton(props.organization, props.entity);
      expect(result).toBe(false);
    });

    it('should return true when plan is PREMIUM', () => {
      const result = shouldShowButton(props.organization, props.entity);
      expect(result).toBe(true);
    });
  });

  describe('when entity has type', () => {
    it('should return false when entity type is STUDENT', () => {
      props.entity.type = 'STUDENT';

      const result = shouldShowButton(props.organization, props.entity);
      expect(result).toBe(false);
    });

    it('should return true when entity type is STAFF', () => {
      props.entity.type = 'STAFF';
      const result = shouldShowButton(props.organization, props.entity);
      expect(result).toBe(true);
    });

    it('should return true when entity type is ADMIN', () => {
      const result = shouldShowButton(props.organization, props.entity);
      expect(result).toBe(true);
    });
  });

  describe('when it has custom file feature', () => {
    it('should return false when hasCustomFileButton return false', () => {
      props.organization.unleashStatus.custom_media_button = false;
      props.organization.unleashStatus.custom_media = false;

      const result = shouldShowButton(props.organization, props.entity);
      expect(result).toBe(false);
    });

    it('should return false when hasCustomFileButton return false', () => {
      props.organization.unleashStatus.custom_media_button = false;
      props.organization.unleashStatus.custom_media = false;

      const result = shouldShowButton(props.organization, props.entity);
      expect(result).toBe(false);
    });
  });
});
