export const validateCharges = (charges) => {
  if (!charges) return null;

  return charges.map((c) => {
    const absorbedFee = [];
    if (c.absorbPix) {
      absorbedFee.push('PIX');
    }
    if (c.absorbBoleto) {
      absorbedFee.push('BOLETO');
    }
    if (c.absorbCard) {
      absorbedFee.push('CARD');
    }

    if (!c.dateLimit) {
      return {
        name: c.name,
        description: c.description ? c.description : '',
        amount: c.amount,
        organizationAccountId: c.organizationAccountId,
        paymentMethods: c.paymentMethods && c.paymentMethods.map(item => item.toUpperCase()),
        allowedInstallments: c.allowedInstallments,
        absorbedFee
      };
    }

    const timeLimit = '23:59';

    const dateLimitToDateObject = new Date(c.dateLimit);

    c.dateLimit = new Date(dateLimitToDateObject.getTime() - (dateLimitToDateObject.getTimezoneOffset() * 60000)).toISOString().substring(0, 10) + ' ' + timeLimit;

    return {
      name: c.name,
      description: c.description ? c.description : '',
      amount: c.amount,
      dateLimit: c.dateLimit,
      organizationAccountId: c.organizationAccountId,
      paymentMethods: c.paymentMethods && c.paymentMethods.map(item => item.toUpperCase()),
      allowedInstallments: c.allowedInstallments,
      absorbedFee
    };
  });
};
