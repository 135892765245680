/**
 * Validade message content
 * @param {{
 *  content: string
 *  subject: string
 *  medias: []
 *  surveys: []
 *  commitments: []
 *  reports: []
 *  charges: []
 *  forms: []
 * }} values
 * @returns {boolean}
 */
export const validateContent = (values) => {
  if (!values) return false;

  const div = document.createElement('div');
  div.innerHTML = values.content;
  return !!div.textContent.trim().length || !!(values.subject && values.subject.trim().length) || !!values.medias.length || !!values.surveys.length || !!values.commitments.length || !!values.reports.length || !!values.charges.length || !!values.forms.length;
};
