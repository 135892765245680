import React from 'react';
import { inject, observer } from 'mobx-react';
import { Button } from 'semantic-ui-react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';

import Controller from '../../components/Controller';

import { __ } from '../../i18n';

@inject('store')
@graphql(gql`mutation deleteMessage($deleteMessageInput:DeleteMessageInput!){
  deleteMessage(input:$deleteMessageInput){
    message{
      id
    }
  }
}`, {
  name: 'deleteMessage',
  options: {
    refetchQueries: ['OrganizationMessagesQuery', 'EntityMessagesQuery'],
    awaitRefetchQueries: true
  }
})
@observer
export default class MessageDelete extends Controller {
  constructor(props) {
    super(props);
    this.state = {
      disabled: false
    };
  }

  backAndRefresh = () => {
    const { store, router } = this.props;

    const link = store.previous.params.entity_id ? `/entities/${store.previous.params.entity_id}/messages` :
      (store.previous.params.organization_id ? `/organizations/${store.previous.params.organization_id}/messages` : '/');
    router.push(link);
  }

  request = () => {
    this.props.super.setLoading(true);
    const { params, deleteMessage, store } = this.props;
    const request = { mutate: deleteMessage, input: 'deleteMessageInput', message: __('Message was deleted') };

    const props = {};

    props.id = params.message_id;

    return request.mutate({
      variables: {
        [request.input]: {
          ...props
        }
      }
    }).then(async () => {
      if (this.props.super.resetStore) await this.props.super.resetStore();
      this.backAndRefresh();
      this.props.super.setLoading(false);
      store.snackbar = { active: true, message: request.message, success: true, dismissAfter: 3000 };
    }).catch(() => {
      this.props.store.snackbar = { active: true, message: __('An error occurred while deleting message'), success: false, dismissAfter: 5000 };
      this.setState({ disabled: false });
    });
  }

  render() {
    const { router } = this.props;

    return (
      <Controller
        id="MessageDelete"
        delete
        modal
        title={__('Delete message')}
        content={
          <div style={{ display: 'grid' }}>
            <span>{__('Are you sure you want to delete this message to all recipients? This action cannot be undone.')}</span>
          </div>
        }
        actions={[
          <Button
            data-action="cancel"
            key={0}
            basic
            floated="left"
            content={__('Cancel')}
            onClick={() => router.goBack()}
          />,
          <Button
            data-action="submit"
            key={1}
            negative
            disabled={this.state.disabled}
            loading={this.state.disabled}
            content={__('Delete')}
            onClick={() => {
              this.props.store.appends.pop();
              this.request();
            }}
          />
        ]}
        onClose={() => this.props.store.appends.pop()}
        {...this.props}
      />
    );
  }
}
