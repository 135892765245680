/* eslint-disable sonarjs/no-duplicate-string */
import { getEntityLink } from '../getEntityLink';

describe('getEntityLink', () => {
  let props;
  beforeEach(() => {
    jest.clearAllMocks();

    props = {
      store: {
        currentUser: {
          isMaster: true,
        },
      },
      message: {
        entity: {
          organizationId: 1,
        },
      },
      entityType: 'ADMIN',
      organizationId: 1,
      params: {
        organization_id: 1,
      },
      entity: {
        fullname: 'Test',
        organizationId: 1,
        isChannel: false,
      },
    };
  });

  it('should be able to return null when entity.organizationId is not provided', () => {
    props.entity.organizationId = null;
    const result = getEntityLink(props);

    expect(result).toBeNull();
  });

  it('should be able to return the entity link when props is provided and isMaster is true', () => {
    const result = getEntityLink(props);

    expect(result).toEqual('/organizations/1/entities?search=Test');
  });

  it('should be able to return the entity link when props is provided and params has organization_id', () => {
    props.store.currentUser.isMaster = false;
    const result = getEntityLink(props);

    expect(result).toEqual('/organizations/1/entities?search=Test');
  });

  it('should be able to return the entity link when props is provided and entity type is ADMIN', () => {
    props.store.currentUser.isMaster = false;
    props.params.organization_id = null;
    const result = getEntityLink(props);

    expect(result).toEqual('/organizations/1/entities?search=Test');
  });

  it('should be able to return the entity link with channels path when entity.isChannel is provided', () => {
    props.entity.isChannel = true;
    const result = getEntityLink(props);

    expect(result).toEqual('/organizations/1/channels?search=Test');
  });
});
