import { validateForms } from '../validateForms';

describe('validateForms', () => {
  it('should return null if there is no forms', () => {
    const result = validateForms(null);
    expect(result).toBe(null);
  });

  it('should return params if there is forms', () => {
    const result = validateForms([
      {
        id: 1,
        hasDateLimit: true,
        dateLimit: '2023-03-19T16:31:55.276Z',
      },
    ]);
    expect(result).toEqual([
      {
        id: 1,
        dateLimit: '2023-03-19 23:59',
      },
    ]);
  });
});
