import React from 'react';
import { inject, observer } from 'mobx-react';
import { Dropdown, Icon, Segment, Menu, Label } from 'semantic-ui-react';
import qs from 'qs';
import { browserHistory } from 'react-router';
import omit from 'lodash/omit';

import MultipleAutosuggest from '../../components/MultipleAutosuggest';
import Input from '../../components/Input';
import ColoredCheckbox from '../../components/ColoredCheckbox';
import Responsive from '../../components/Responsive';
import TableActionBar from '../../components/TableActionBar';

import { __ } from '../../i18n';
import { sendGoogleAnalyticsEvent } from '../../lib/analytics';
import * as utils from '../../utils';

const folderTypes = () => [
  { text: __('All'), value: 'ALL', key: 'all', 'data-value': 'all' },
  { text: __('Unread'), value: 'UNREAD_BY_NTF', key: 'unread', 'data-value': 'unread' },
  { text: __('Archived'), value: 'ARCHIVE', key: 'archive', 'data-value': 'archive' },
  { text: __('Bin'), value: 'DELETED', key: 'deleted', 'data-value': 'deleted' },
  { text: __('Read'), value: 'READ', key: 'read', 'data-value': 'read' },
  { text: __('Sent'), value: 'SENT', key: 'sent', 'data-value': 'sent' },
  { text: __('Received'), value: 'RECEIVED', key: 'received', 'data-value': 'received' }
];

const withTypes = () => [
  { text: __('Important'), value: 'PIN', icon: 'star', key: 'pin', 'data-value': 'pin', type: 'info' },
  { text: __('Public'), value: 'PUBLIC', icon: 'globe', key: 'public', 'data-value': 'public', type: 'info' },
  //{ text: __('Public forum'), value: 'FORUM', icon: 'comments', key: 'forum', 'data-value': 'forum', type: 'info' },
  { text: __('With Replies'), value: 'REPLY', icon: 'reply', key: 'reply', 'data-value': 'reply', type: 'info' },
  { text: __('Forwarded'), value: 'FORWARD', icon: 'share', key: 'forward', 'data-value': 'forward', type: 'info' },
  { text: (__('Scheduled')), value: 'SCHEDULED', icon: 'clock', key: '', 'data-value': '', type: '' },
  { text: __('Media'), value: 'MEDIA', icon: 'image', key: 'media', 'data-value': 'media', type: 'attachment' },
  { text: __('File'), value: 'FILE', icon: 'file', key: 'file', 'data-value': 'file', type: 'attachment' },
  { text: __('Survey'), value: 'SURVEY', icon: 'bar chart', key: 'survey', 'data-value': 'survey', type: 'attachment' },
  { text: __('Commitment'), value: 'COMMITMENT', icon: 'calendar', key: 'commitment', 'data-value': 'commitment', type: 'attachment' },
  { text: __('Report'), value: 'REPORT', icon: 'list layout', key: 'report', 'data-value': 'report', type: 'attachment' },
  { text: __('Charge'), value: 'CHARGE', icon: 'donate', key: 'charge', 'data-value': 'charge', type: 'attachment' },
  { text: __('Form'), value: 'FORM', icon: 'poll h', key: 'form', 'data-value': 'form', type: 'attachment' }
];

const withTypesOrganization = () => [
  { text: __('Important'), value: 'PIN', icon: 'star', key: 'pin', 'data-value': 'pin', type: 'info' },
  { text: __('Public'), value: 'PUBLIC', icon: 'globe', key: 'public', 'data-value': 'public', type: 'info' },
  //{ text: __('Public forum'), value: 'FORUM', icon: 'comments', key: 'forum', 'data-value': 'forum', type: 'info' },
  { text: __('With Replies'), value: 'REPLY', icon: 'reply', key: 'reply', 'data-value': 'reply', type: 'info' },
  { text: __('Forwarded'), value: 'FORWARD', icon: 'share', key: 'forward', 'data-value': 'forward', type: 'info' },
  { text: __('Deleted'), value: 'DELETED', icon: 'trash alt', key: 'delete', 'data-value': 'delete', type: 'info' },
  { text: __('Media'), value: 'MEDIA', icon: 'image', key: 'media', 'data-value': 'media', type: 'attachment' },
  { text: __('File'), value: 'FILE', icon: 'file', key: 'file', 'data-value': 'file', type: 'attachment' },
  { text: __('Survey'), value: 'SURVEY', icon: 'bar chart', key: 'survey', 'data-value': 'survey', type: 'attachment' },
  { text: __('Commitment'), value: 'COMMITMENT', icon: 'calendar', key: 'commitment', 'data-value': 'commitment', type: 'attachment' },
  { text: __('Report'), value: 'REPORT', icon: 'list layout', key: 'report', 'data-value': 'report', type: 'attachment' },
  { text: __('Charge'), value: 'CHARGE', icon: 'donate', key: 'charge', 'data-value': 'charge', type: 'attachment' },
  { text: __('Form'), value: 'FORM', icon: 'poll h', key: 'form', 'data-value': 'form', type: 'attachment' }
];

const styles = {
  dropdownHeader: {
    color: '#868e96'
  },
  dropdownMenu: {
    height: '380px',
    overflow: 'auto'
  },
  labelItem: {
    display: 'flex',
    alignItems: 'center'
  }
};

@inject('store') @observer
export default class MessageFilter extends Responsive {
  constructor(props) {
    super(props);
    const parameters = qs.parse(props.location && props.location.query);

    const { location } = props;
    const query = { ...location.query };
    const order = [
      { text: 'Mais recentes primeiro', value: '' },
      { text: 'Mais antigos primeiro', value: '' },
      { text: 'Envio mais próximo', value: '' },
    ];

    this.state = {
      parameters: {
        folder: parameters.folder || 'ALL',
        search: parameters.search || '',
        attachments: (parameters.attachments && JSON.parse(parameters.attachments)) || [],
        recipientIds: (parameters.recipientIds && JSON.parse(parameters.recipientIds)) || null,
        labelIds: (parameters.labelIds && JSON.parse(parameters.labelIds)) || []
      },
      loading: false,
      groups: [],
      order: order.map(e => ({ value: e.value, text: e.text, selected: query.orderBy === e.value })),
      selectedGroups: [],
    };
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    const { location: oldLocation } = this.props;
    const { location: newLocation } = newProps;
    if (oldLocation.pathname !== newLocation.pathname || oldLocation.search !== newLocation.search) {
      const parameters = qs.parse(newLocation.query);

      this.setState({
        parameters: {
          folder: parameters.folder || 'ALL',
          search: parameters.search || '',
          attachments: (parameters.attachments && JSON.parse(parameters.attachments)) || [],
          recipientIds: (parameters.recipientIds && JSON.parse(parameters.recipientIds)) || null,
          labelIds: (parameters.labelIds && JSON.parse(parameters.labelIds)) || []
        }
      });
    }
  }

  componentWillUnmount() {
    const { timer, requestTimer } = this.state;
    if (timer) clearTimeout(timer);
    if (requestTimer) clearTimeout(requestTimer);
  }

  onAutosuggestChange = (idArray) => {
    let value;
    if (idArray.length === 0) {
      value = null;
    } else {
      value = idArray;
    }

    this.onValueChange('recipientIds', value);
  }

  onValueChange = (type, value) => {
    if (this.state.parameters[type] != value || type != 'recipientIds')
      this.setState(
        { parameters: { ...this.state.parameters, [type]: value }, loading: true },
        () => this.debounce(this.request, 500));
  }

  debounce = (func, wait) => {
    clearTimeout(this.state.timer);
    const timer = setTimeout(func, wait);
    this.setState({ timer });
  }

  request = async () => {
    const requestTimer = setTimeout(() => {
      this.setState({ loading: false });
    }, 1000); // estimated time for request to finish
    this.setState({ loading: true, requestTimer });
    const parameters = { ...this.state.parameters };

    if (parameters.attachments) {
      parameters.attachments = parameters.attachments.length ? JSON.stringify(parameters.attachments) : null;
    }

    if (parameters.recipientIds) {
      parameters.recipientIds = parameters.recipientIds.length ? JSON.stringify(parameters.recipientIds) : null;
    }

    if (parameters.labelIds) {
      parameters.labelIds = parameters.labelIds.length ? JSON.stringify(parameters.labelIds) : null;
    }

    if (parameters.folder === 'ALL') {
      parameters.folder = '';
    }

    const params = qs.stringify(parameters);
    const pathname = this.props.location.pathname;

    if (params.length > 0) {
      browserHistory.push(pathname + '?' + params);
    } else {
      browserHistory.push(pathname);
    }
  }

  renderAttachmentItem = (item) => {
    let { attachments } = this.state.parameters;
    const checked = attachments.includes(item.value);
    const { store } = this.props;
    const entityType = store.currentEntity && store.currentEntity.type;
    const organizationId = store.currentOrganization && store.currentOrganization.id;
    const filterNameUpperCase = item && item.value && item.value.toUpperCase();

    const trackingMessage = item.type === 'info' ?
      `Message type filter applyed by ${entityType} - ${filterNameUpperCase}`
      : `Attachment filter applyed by ${entityType} - ${filterNameUpperCase}`;

    return (<Dropdown.Item
      style={{ display: 'flex' }}
      onClick={() => {
        if (checked) {
          attachments = attachments.filter(attachment => attachment !== item.value);
        } else {
          attachments.push(item.value);
          sendGoogleAnalyticsEvent({
            name: 'Entity filter type',
            category: trackingMessage,
            label: `OrganizationId: ${organizationId}`,
          }, { store });
        }
        this.onValueChange('attachments', attachments);
      }}
    >
      <ColoredCheckbox
        data-action={`select-${item.key}`}
        data-value={item['data-value']}
        label={<span>{item.icon && <Icon name={item.icon} />} {item.text}</span>}
        checked={checked}
        style={{ display: 'flex', alignItems: 'center' }}
      />
    </Dropdown.Item>);
  }

  renderLabelItem = (item) => {
    const { store } = this.props;
    let { labelIds } = this.state.parameters;
    const checked = labelIds.includes(item.id);
    const entityType = store.currentEntity && store.currentEntity.type;
    const organizationId = store.currentOrganization && store.currentOrganization.id;

    return (<Dropdown.Item
      style={{ display: 'flex' }}
      onClick={() => {
        if (checked) {
          labelIds = labelIds.filter(id => id !== item.id);
        } else {
          sendGoogleAnalyticsEvent({
            name: 'Entity filter type',
            category: `Message label filter applyed by ${entityType}`,
            label: `OrganizationId: ${organizationId}`,
          }, { store });
          labelIds.push(item.id);
        }
        this.onValueChange('labelIds', labelIds);
      }}
    >
      <ColoredCheckbox
        data-action={`select-${item.id}`}
        data-value={item['data-value']}
        label={<span style={styles.labelItem}><Icon name="square" style={{ color: `#${item.color}`, fontWeight: 'bold' }} /> {item.title}</span>}
        checked={checked}
        style={{ display: 'flex' }}
      />
    </Dropdown.Item>);
  }

  renderAttachment = (item, i) => {
    const { location } = this.props;

    let attachment = withTypes().find(a => a.value === item);

    if (location && location.pathname && location.pathname.search('organizations') === 1) {
      attachment = withTypesOrganization().find(a => a.value === item);
    }

    return (<Label
      size="large"
      style={{ backgroundColor: '#4c6ef5', color: 'white', margin: '0px 8px 8px 0px' }}
      value={attachment.value}
    >
      {attachment.icon && <Icon name={attachment.icon} style={{ margin: '0px 6x 0px 0px' }} />} {attachment.text}
      <Icon
        name="delete"
        data-action="unselect-filter"
        onClick={() => this.onValueChange('attachments', this.state.parameters.attachments.filter(a => a !== item))}
      />
    </Label>);
  }

  __handleStatusFilterChange = (value) => {
    const { store } = this.props;
    const entityType = store.currentEntity && store.currentEntity.type;
    const organizationId = store.currentOrganization && store.currentOrganization.id;
    const statusNameUpperCase = value && value.toUpperCase();

    sendGoogleAnalyticsEvent({
      name: 'Entity status filter',
      category: `Message status filter applyed by ${entityType} - ${statusNameUpperCase}`,
      label: `OrganizationId: ${organizationId}`,
    }, { store });
    this.onValueChange('folder', value);
  }

  renderLabel = (item) => {
    const label = this.props.organization.labels.nodes.find(l => l.id === item);

    return (<Label
      size="large"
      style={{ margin: '0px 8px 8px 0px' }}
      value={label.id}
    // basic
    >
      <Icon name="square" style={{ fontWeight: 'bold', color: `#${label.color}` }} /> {label.title}
      <Icon
        name="delete"
        data-action="unselect-filter"
        onClick={() => this.onValueChange('labelIds', this.state.parameters.labelIds.filter(a => a !== item))}
      />
    </Label>);
  }

  onAddFilter = async (value, key) => {
    this.setState({ loading: true });
    this.setState((prevState) => {
      if (value === 'clear-all') {
        prevState[key] = prevState[key].map(x => ({ ...x, selected: false }));
        return prevState;
      }
      const index = prevState[key].findIndex(x => x.value === value);
      prevState[key][index].selected = true;
      return prevState;
    }, async () => {
      const allIds = this.state[key].map(i => i.selected && i.value).filter(i => i);
      const ids = this.state[key].map(i => i.selected && i.value).filter(i => i && i !== 'noGroup' && i !== 'seeAll');
      if (key === 'groups') {
        const indexNoGroup = allIds.indexOf('noGroup');
        if (indexNoGroup > -1) {
          await this.onUniqueParameterChange('noGroup', 'true');
        } else {
          await this.onUniqueParameterChange('noGroup', 'false');
        }
        const indexSeeAll = allIds.indexOf('seeAll');
        if (indexSeeAll > -1) {
          await this.onUniqueParameterChange('seeAll', 'true');
        } else {
          await this.onUniqueParameterChange('seeAll', 'false');
        }
        this.onMultipleParameterChange('groupsIds', ids);
      }
    }
    );
    this.setState({ loading: false });
  }

  onDeleteFilter = (filter) => {
    this.setState({ loading: true });
    const value = filter.value;
    const key = filter.key;
    if (filter.multipleTags) {
      this.onDelete(value, key);
      return null;
    }

    this.setState((prevState) => {
      const index = prevState[key].findIndex(x => x.value === value);
      prevState[key][index].selected = false;
      return prevState;
    }, async () => {
      const allIds = this.state[key].map(i => i.selected && i.value).filter(i => i);
      const ids = this.state[key].map(i => i.selected && i.value).filter(i => i && i !== 'noGroup' && i !== 'seeAll');
      if (key === 'groups') {
        const indexNoGroup = allIds.indexOf('noGroup');
        if (indexNoGroup > -1) {
          await this.onUniqueParameterChange('noGroup', 'true');
        } else {
          await this.onUniqueParameterChange('noGroup', 'false');
        }
        const indexSeeAll = allIds.indexOf('seeAll');
        if (indexSeeAll > -1) {
          await this.onUniqueParameterChange('seeAll', 'true');
        } else {
          await this.onUniqueParameterChange('seeAll', 'false');
        }
        this.onMultipleParameterChange('groupsIds', ids);
      }
    }
    );
    this.setState({ loading: false });
  }

  simpleFiltersCallback = (params) => {
    const keys = {
      order: 'orderBy',
      groups: 'groups'
    };
    let value = params.filterBy;
    const key = params.key;
    if (params.multipleTags) {
      this.onAddFilter(value, key);
      return null;
    }


    this.setState({ loading: true });
    if (value === 'clear-all') {
      this.onUniqueParameterChange(keys[key], '');
      this.setState((prevState) => {
        prevState[key] = prevState[key].map(x => ({ ...x, selected: false }));
        return prevState;
      });
    } else {
      this.onUniqueParameterChange(keys[key], value);
      this.setState((prevState) => {
        if (key != 'order') {
          const index = prevState[key].findIndex(x => x.value === value);
          prevState[key] = prevState[key].map(x => ({ ...x, selected: false }));
          prevState[key][index].selected = true;
        }
        return prevState;
      });
    }

    this.setState({ loading: false });
  }

  renderMobile = () => {
    const { id, request, location, resultExtract, selection, organization, store, onOpenFilters, onCloseFilters } = this.props;
    const { parameters } = this.state;

    return (
      <div>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <h2 style={{ fontSize: '1.4rem', paddingLeft: 8, lineHeight: '38px', paddingBottom: '3px' }}>{__('Messages')}</h2>
          <a href="https://ajuda.classapp.com.br/hc/pt-br/articles/4404608210587" target="_blank" rel="noopener noreferrer">
            <img src={utils.asset('/icons/question.svg')} alt={__('Help on how to use filters')} style={{ marginLeft: '8px', marginRight: '5px', height: '20px', width: '20px' }} />
          </a>
        </div>
        <div style={{ display: 'flex' }}>
          <div style={{ margin: 0, border: 0, background: 'none', boxShadow: 'none', width: '100%' }}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div style={{ display: 'flex' }}>
                <Input
                  data-action="search-message"
                  data-params="subject-content"
                  onChange={(e, { value }) => this.onValueChange('search', value)}
                  placeholder={__('Search for keywords...')}
                  iconPosition="left"
                  className="round-input adjust-inner-input"
                  style={{ width: '100%', marginRight: '5px' }}
                  value={this.state.parameters.search}
                  loading={this.state.loading}
                />
                {location.pathname.search('organizations') === 1 ?
                  null
                  :
                  <Menu.Item style={{ margin: 0, padding: 0, borderRadius: '1.5rem' }}>
                    <Dropdown
                      id="inputBox"
                      value={this.state.parameters.folder}
                      selection compact
                      options={folderTypes()}
                      onChange={(e, { value }) => this.__handleStatusFilterChange(value)}
                    />
                  </Menu.Item>
                }
              </div>
              <div style={{ display: 'flex', marginTop: '8px' }}>
                <div style={{ display: 'flex', width: '75%', marginRight: '5px' }}>
                  {
                    (location.pathname.search('organizations') === -1 || this.props.store.currentEntity === 'ADMIN' || (this.props.store.currentEntity.organization && this.props.store.currentEntity.organization.permissions && (this.props.store.currentEntity.organization.permissions.hasAdmin || this.props.store.currentEntity.organization.permissions.entityScopes.includes('READ_ENTITY')))) &&
                    <MultipleAutosuggest
                      location={this.props.location}
                      handleSelectedItems={array => this.onAutosuggestChange(array[0])}
                      requests={[request]}
                      requestArguments={[{ id }]}
                      apiExtract={[{ name: 'fullname' }]}
                      resultExtract={[resultExtract]}
                      style={[{ image: true, icon: 'user' }]}
                      placeholder={__('Search for people...')}
                      icon="at"
                      className="icon-left custom-label round-corners small-suggestion-avatars suggestion-dont-break-line"
                      cssStyle={{
                        width: '100%'
                      }}
                      ids={parameters.recipientIds}
                    />
                  }
                </div>
                <Menu
                  compact
                  style={{
                    minHeight: 'unset',
                    height: '38px',
                    border: '1px solid rgba(34,36,38,.15)',
                    borderRadius: '1.5rem',
                    width: '25%',
                    maxWidth: '70px'
                  }}
                >
                  <Dropdown
                    text={<span><Icon style={{ margin: 0 }} className="filter" /></span>}
                    className="force-margin-0"
                    data-action="search-message"
                    data-params="filters"
                    pointing="top right"
                    item
                    onOpen={() => onOpenFilters && onOpenFilters()}
                    onClose={() => onCloseFilters && onCloseFilters()}
                    style={{ margin: '0', padding: '11px 12px', paddingRight: '16px', background: '#fff', borderRadius: '1.5rem' }}
                  >
                    <Dropdown.Menu style={styles.dropdownMenu}>
                      <Dropdown.Header style={styles.dropdownHeader}>{__('Message types').toUpperCase()}</Dropdown.Header>
                      {(location.pathname.search('organizations') === 1 ? withTypesOrganization() : withTypes()).filter(item => item.type === 'info').map((item, i) => this.renderAttachmentItem(item, i))}
                      <Dropdown.Header style={styles.dropdownHeader}>{__('Attachments').toUpperCase()}</Dropdown.Header>
                      {withTypes().filter(item => item.type === 'attachment').map((item, i) => this.renderAttachmentItem(item, i))}
                      {
                        organization && organization.labels && organization.labels.nodes &&
                        store.currentOrganization && store.currentOrganization.features && store.currentOrganization.features.labels &&
                        [
                          <Dropdown.Header style={styles.dropdownHeader}>{__('Labels').toUpperCase()}</Dropdown.Header>,
                          organization.labels.nodes.map((item, i) => this.renderLabelItem(item, i))
                        ]
                      }
                    </Dropdown.Menu>
                  </Dropdown>
                </Menu>
              </div>
            </div>
          </div>
        </div>
        {
          (!!this.state.parameters.attachments.length || !!this.state.parameters.labelIds.length) &&
          <div style={{ display: 'flex', flexWrap: 'wrap', marginTop: 24, padding: selection ? '0px 0px 0px 32px' : 0 }}>
            {this.state.parameters.attachments.map((attachment, i) => this.renderAttachment(attachment, i))}
            {this.state.parameters.labelIds.map((label, i) => this.renderLabel(label, i))}
          </div>
        }
      </div>
    );
  }

  renderDesktop = () => {
    const { id, request, location, resultExtract, selection, organization, store } = this.props;
    const { parameters } = this.state;

    const simpleFiltersList = [
      { name: __('Sort'), key: 'order', removeButton: true, icon: 'arrow down arrow up' },
      { name: __('Group'), key: 'groups', removeButton: true, multipleTags: true, searchable: true, style: { width: 105 }, icon: 'chevron down' },
    ];

    const simpleFilters = simpleFiltersList.map(({ key, name, removeButton, multipleTags, searchable, style, icon }) => ({
      removeButton,
      multipleTags,
      searchable,
      style,
      icon,
      key,
      placeholder: name,
      options: this.state[key],
      fn: value => this.simpleFiltersCallback(value),
      onDelete: this.onDeleteFilter
    }));

    const validateInputWidth = () => {
      if (location.query.type === 'messagesToApprove') {
        return '63.9%';
      }

      return location.pathname.search('organizations') === -1 ? '36.5%' : '42%';
    }


    return (
      <div>
        <div style={{ display: 'flex' }}>
          {location.query.type !== 'messagesToApprove' && selection}

          <Segment style={{ padding: '2px', margin: 0, border: 0, background: 'none', boxShadow: 'none', width: '100%' }}>
            <Menu secondary style={{ display: 'flex', alignItems: 'center' }}>
              {location.query.type !== 'messagesToApprove' &&
                <Input
                  data-action="search-message"
                  data-params="subject-content"
                  onChange={(e, { value }) => this.onValueChange('search', value)}
                  placeholder={__('Search for keywords...')}
                  iconPosition="left"
                  className="round-input"
                  style={{
                    margin: 'auto 0 auto 8px',
                    width: location.pathname.search('organizations') === -1 ? '150px' : null,
                    minWidth: validateInputWidth()
                  }}
                  value={this.state.parameters.search}
                  loading={this.state.loading}
                />
              }

              {
                (location.pathname.search('organizations') === -1 || this.props.store.currentEntity === 'ADMIN' || (this.props.store.currentEntity.organization && this.props.store.currentEntity.organization.permissions && (this.props.store.currentEntity.organization.permissions.hasAdmin || this.props.store.currentEntity.organization.permissions.entityScopes.includes('READ_ENTITY')))) &&
                <>
                  <MultipleAutosuggest
                    location={this.props.location}
                    handleSelectedItems={array => this.onAutosuggestChange(array[0])}
                    requests={[request]}
                    requestArguments={[{ id }]}
                    apiExtract={[{ name: 'fullname' }]}
                    resultExtract={[resultExtract]}
                    style={[{ image: true, icon: 'user' }]}
                    placeholder={__('Search for people...')}
                    icon="at"
                    className="icon-left custom-label round-corners small-suggestion-avatars suggestion-dont-break-line"
                    cssStyle={{
                      margin: 'auto 0 auto 8px',
                      minWidth: '228px',
                      maxWidth: validateInputWidth()
                    }}
                    ids={parameters.recipientIds}
                  />
                  {location.query.type === 'messagesToApprove' &&
                    <div style={{ marginBottom: '-2rem' }}>
                      <TableActionBar
                        simpleFilters={simpleFilters}
                      />
                    </div>
                  }
                </>
              }
              <Menu
                compact
                style={{
                  marginLeft: '8px',
                  minHeight: 'unset',
                  height: '38px',
                  border: '1px solid rgba(34,36,38,.15)',
                  borderRadius: '1.5rem',
                  width: location.query.type === 'messagesToApprove' && '7rem',
                  height: location.query.type !== 'messagesToApprove' ? '38px' : '42px',
                }}
              >
                <Dropdown
                  text={__('Filters')}
                  data-action="search-message"
                  data-params="filters"
                  item
                  icon={{ name: location.query.type === 'messagesToApprove' ? 'chevron down' : 'dropdown' }}
                  style={{
                    margin: '0',
                    padding: '11px 12px',
                    borderRadius: '1.5rem',
                    fontSize: location.query.type === 'messagesToApprove' && '1.1rem',
                    width: '100%',
                    height: '100%',
                    justifyContent: location.query.type === 'messagesToApprove' && 'space-between'
                  }}
                >
                  <Dropdown.Menu style={styles.dropdownMenu}>
                    <Dropdown.Header style={styles.dropdownHeader}>{__('Message types').toUpperCase()}</Dropdown.Header>
                    {/* {(location.pathname.search('organizations') === 1 ? withTypesOrganization() : withTypes()).filter(item => item.type === 'info').map((item, i) => this.renderAttachmentItem(item, i))} */}

                    {location.query.type !== 'messagesToApprove' ? (
                      location.pathname.search('organizations') === 1 ? withTypesOrganization() : withTypes()).filter(item => item.type === 'info').map((item, i) => this.renderAttachmentItem(item, i)
                      ) : (
                      withTypes().filter(item => item.value === 'SCHEDULED').map((item, i) => this.renderAttachmentItem(item, i))
                    )
                    }

                    <Dropdown.Header style={styles.dropdownHeader}>{__('Attachments').toUpperCase()}</Dropdown.Header>
                    {withTypes().filter(item => item.type === 'attachment').map((item, i) => this.renderAttachmentItem(item, i))}
                    {
                      organization && organization.labels && organization.labels.nodes &&
                      store.currentOrganization && store.currentOrganization.features && store.currentOrganization.features.labels &&
                      [
                        <Dropdown.Header style={styles.dropdownHeader}>{__('Labels').toUpperCase()}</Dropdown.Header>,
                        organization.labels.nodes.map((item, i) => this.renderLabelItem(item, i))
                      ]
                    }
                  </Dropdown.Menu>
                </Dropdown>
              </Menu>

              {
                (location.pathname.search('organizations') === 1 || location.query.type === 'messagesToApprove') ?
                  null
                  :
                  <Menu.Item style={{ margin: '0 0 0 8px', padding: 0, borderRadius: '1.5rem' }}>
                    <Dropdown
                      style={{
                        paddingRight: '3em',
                        minWidth: '100px',
                      }}
                      id="inputBox"
                      value={this.state.parameters.folder}
                      selection compact
                      options={folderTypes()}
                      onChange={(e, { value }) => this.__handleStatusFilterChange(value)}
                    />
                  </Menu.Item>
              }

              {location.query.type !== 'messagesToApprove' && (
                <a href="https://ajuda.classapp.com.br/hc/pt-br/articles/4404608210587" target="_blank" rel="noopener noreferrer">
                  <img src={utils.asset('/icons/question.svg')} alt={__('Help on how to use filters')} style={{ marginLeft: '8px', marginRight: '5px', height: '20px', width: '20px' }} />
                </a>
              )
              }
            </Menu>
          </Segment>
        </div>
        {
          (!!this.state.parameters.attachments.length || !!this.state.parameters.labelIds.length) &&
          <div style={{ display: 'flex', flexWrap: 'wrap', marginTop: 24, padding: selection ? '0px 0px 0px 32px' : 0 }}>
            {this.state.parameters.attachments.map((attachment, i) => this.renderAttachment(attachment, i))}
            {this.state.parameters.labelIds.map((label, i) => this.renderLabel(label, i))}
          </div>
        }
      </div>
    );
  }

  render() {
    const newProps = omit(this.props,
      ['id', 'request', 'location', 'resultExtract', 'selection']);

    return (
      <div id="MessageFilter" style={{ display: 'grid' }} {...newProps}>
        {this.isMobile() ? this.renderMobile() : this.renderDesktop()}
      </div>
    );
  }
}
