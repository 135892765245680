import React from 'react';
import { List, Icon } from 'semantic-ui-react';

import Modal from '../../components/ui/Modal';
import Button from '../../components/ui/Button';
import ListView from '../../components/ListView';
import ColoredCheckbox from '../../components/ColoredCheckbox';

import { __ } from '../../i18n';

const styles = {
  row: {
    width: '100%',
    display: 'flex'
  },
  insideRow: {
    display: 'flex',
    alignItems: 'center'
  }
};

export default class MessageLabel extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedLabel: props.label
    };
  }

  onSelectLabel = (label) => {
    const { isMobile } = this.props;
    if (!isMobile) {
      this.setState({ selectedLabel: label.id });
    } else {
      this.setState({ selectedLabel: label.id }, () => this.props.onSubmit(label.id));
    }
  }

  render() {
    const { selectedLabel } = this.state;
    const { labels, isMobile } = this.props;

    return (
      <Modal
        id="LabelModal"
        onClose={this.props.onCancel}
        spaceFooter
        toast={isMobile}
        invertCloseButton={isMobile}
        noContentPadding={isMobile}
        actions={!isMobile ? [
          <Button
            data-action="cancel"
            round
            transparent
            floated="left"
            text={__('Cancel')}
            onClick={this.props.onCancel}
          />,
          <Button
            data-action="submit"
            round
            icon={{ name: 'check' }}
            text={__('Add')}
            onClick={() => this.props.onSubmit(selectedLabel)}
          />
        ] : false}
        header={__('Add a label')}
        content={
          <ListView
            renderRow={(label, key) =>
              <List.Item
                data-action="select-label"
                data-params={label.id}
                key={key}
                onClick={() => this.onSelectLabel(label)}
                style={{ ...styles.row, borderRadius: isMobile ? '0' : '.5em', height: isMobile ? '46px' : '40px', marginTop: isMobile ? '8px' : '', padding: isMobile ? '0px 8px' : '0px 16px', backgroundColor: selectedLabel === label.id ? '#F2F7FF' : 'initial' }}
              >
                <div style={isMobile ? { ...styles.insideRow, justifyContent: 'space-between', width: '100%' } : { ...styles.insideRow }}>
                  {!isMobile &&
                  <ColoredCheckbox style={{ display: 'flex' }} radio color="#084FFF" checked={selectedLabel === label.id} />
                  }
                  <div style={{ display: 'flex', alignItems: 'center', marginLeft: 16, alignSelf: 'center', fontWeight: 400, fontSize: '16px' }}>
                    {label.color && <Icon name="square" size="large" style={{ color: `#${label.color}`, fontWeight: 'bold' }} />} {label.title}
                  </div>
                  {isMobile &&
                  <ColoredCheckbox style={{ display: 'flex' }} radio color="#084FFF" checked={selectedLabel === label.id} />
                  }
                </div>
              </List.Item>}
            classNameLabels={`labels${isMobile ? ' mobile' : ''}`}
            notCelled
            source={[{ id: null, title: __('No label') }, ...labels]}
          />
        }
      />
    );
  }
}
