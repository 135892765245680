import React from 'react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import { filter } from 'graphql-anywhere';
import { inject, observer } from 'mobx-react';
import { Loader } from 'semantic-ui-react';

import CommitmentRemindForm from '../CommitmentRemind/Form';

@inject('store')
@graphql(gql`query MessageCommitmentRemindsEntityQuery (
  $entityId: ID!,
  $messageId: ID!
) {
    node(id: $entityId) @connection(key: "Entity", filter: ["entityId"]) {
      ... on Entity {
        id: dbId
        message (id: $messageId ) {
          id: dbId
          commitments (limit: 40) {
            nodes {
              id: dbId
              ...CommitmentRemindForm
            }
          }
        }
      }
    }
  }
  ${CommitmentRemindForm.fragments.commitment}
`, {
  options: ownProps => ({
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    variables: {
      entityId: ownProps.params.entity_id,
      messageId: ownProps.params.message_id
    }
  }),
  skip: ownProps => !ownProps.params.entity_id || ownProps.fromHistory
})
@graphql(gql`query MessageCommitmentRemindsOrganizationQuery (
  $messageId: ID!,
) {
    node(id: $messageId) @connection(key: "Message", filter: ["messageId"]) {
      ... on Message {
        id: dbId
        commitments (limit: 40) {
          nodes {
            id: dbId
            ...CommitmentRemindForm
          }
        }
      }
    }
  }
  ${CommitmentRemindForm.fragments.commitment}
`, {
  options: ownProps => ({
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    variables: {
      organizationId: ownProps.params.organization_id,
      messageId: ownProps.params.message_id
    }
  }),
  skip: ownProps => !ownProps.params.organization_id || ownProps.fromHistory
})
@observer
export default class MessageCommitmentReminds extends React.Component {
  static fragments = {
    commitments: gql`
      fragment MessageCommitmentReminds on Message {
        commitments (limit: 40) {
          nodes {
            id: key
            ...CommitmentRemindForm
          }
        }
      }
      ${CommitmentRemindForm.fragments.commitment}
      `,
  }

  state = {
    isLoaded: false
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!nextProps.fromHistory && !this.state.isLoaded && nextProps.data.node && !nextProps.data.loading) {
      this.setState({ isLoaded: true });
    }
  }

  render() {
    const { data, params, messageType, entity, onSubmit, fromHistory, isMessageToApprove } = this.props;
    const { isLoaded } = this.state;

    if (!fromHistory && ((data.loading && !isLoaded) || !data?.node)) { return <Loader active inline="centered" />; }

    const commitments = fromHistory ? this?.props?.commitments : (data?.node?.commitments || data?.node?.message?.commitments);

    if ((!commitments || !commitments?.nodes?.length) && !commitments?.length) return null;

    const renderForMessageToApprove = () => {
      return (
        <div id="MessageCommitmentReminds">
          {
            commitments?.map(commitment => (
              <CommitmentRemindForm
                key={commitment?.id || commitment?.name}
                commitment={commitment}
                params={params}
                messageType={messageType}
                entity={entity}
                onSubmit={() => data.refetch()}
                fromHistory={this.props.fromHistory}
              />
            ))
          }
        </div>
      )
    }

    return (
      <div id="MessageCommitmentReminds">
        {
          isMessageToApprove ? renderForMessageToApprove() : commitments?.nodes?.map(commitment => (
            <CommitmentRemindForm
              key={commitment?.id}
              commitment={filter(CommitmentRemindForm?.fragments?.commitment, commitment)}
              params={params}
              messageType={messageType}
              entity={entity}
              onSubmit={() => data.refetch()}
              fromHistory={this.props.fromHistory}
            />
          ))
        }
      </div>
    );
  }
}
