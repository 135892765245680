import { getOnReference } from '../getOnReference';

describe('getOnReference', () => {
  it('should return the right icon', () => {
    expect(getOnReference().COMMITMENT.icon).toBe('calendar');
    expect(getOnReference().SURVEY.icon).toBe('bar chart');
    expect(getOnReference().FORM.icon).toBe('poll h');
  });

  it('should return the right start', () => {
    expect(getOnReference().COMMITMENT.start).toBe('The commitment ');
    expect(getOnReference().SURVEY.start).toBe('The survey ');
    expect(getOnReference().FORM.start).toBe('The form ');
  });

  it('should return the right middle', () => {
    expect(getOnReference().COMMITMENT.middle).toBe(' on commitment ');
    expect(getOnReference().SURVEY.middle).toBe(' on survey ');
    expect(getOnReference().FORM.middle).toBe(' on form ');
  });
});
