import { sendApprovalMessageEvent } from '../utils/sendApprovalMessageEvent';
import { sendGoogleAnalyticsEvent } from '../../../../lib/analytics';

jest.mock('../../../../lib/analytics', () => ({
  sendGoogleAnalyticsEvent: jest.fn(),
}));

describe('sendApprovalMessageEvent util', () => {
  it('should call sendGoogleAnalyticsEvent with correct parameters', () => {
    const category = 'test-label';
    const store = {
      currentUser: { isMaster: false },
      app: { env: 'development' },
      currentOrganization: { id: 1 },
    };
    sendApprovalMessageEvent(category, store);
    expect(sendGoogleAnalyticsEvent).toBeCalledWith(
      {
        name: 'Approval Message',
        category,
        label: 'OrganizationID: 1',
      },
      { store },
    );
  });
});
