import React from 'react';
import { inject, observer } from 'mobx-react';
import { Image, Container, Divider, Icon } from 'semantic-ui-react';
import gql from 'graphql-tag';
import { filter } from 'graphql-anywhere';

import MediaItem from '../Media/Item';

@inject('store') @observer
export default class MessageMedias extends React.Component {
  static fragments = {
    medias: gql`
      fragment MessageMedias on Message {
        images: medias (type: IMAGE, limit: 40) {
          nodes {
            id: dbId
            original: uri(size:"w1280")
            ...MediaItemImage
          }
        }
        videos: medias (type: VIDEO, limit: 40) {
          nodes {
            id: dbId
            ...MediaItemVideo
          }
        }
        audios: medias (type: AUDIO, limit: 40) {
          nodes {
            id: dbId
            ...MediaItemAudio
          }
        }
      }
      ${MediaItem.fragments.image}
      ${MediaItem.fragments.video}
      ${MediaItem.fragments.audio}
      `,
  }

  render() {
    const { images, videos, audios, router } = this.props;

    const hasImages = !!images && !!images.nodes && !!images.nodes.length;
    const hasVideos = !!videos && !!videos.nodes && !!videos.nodes.length;
    const hasAudios = !!audios && !!audios.nodes && !!audios.nodes.length;

    if (!hasImages && !hasVideos && !hasAudios) return null;

    if (router && (images.length || videos.length || audios.length)) {
      return (
        <div id="MessageMedias">
          <Container key={'medias'} textAlign="center">
            {images.map(media =>
              <div key={media.id}>
                <Image
                  data-action="open-media-item"
                  key={media.id} src={media.original} onClick={(e) => {
                    e.preventDefault();
                    this.props.store.appends.push(<MediaItem media={filter(MediaItem.fragments.image, media)} />);
                  }}
                />
                <Divider hidden />
              </div>
            )}
            {videos.map(media =>
              <div key={media.id}>
                <MediaItem media={filter(MediaItem.fragments.video, media)} container />
                <Divider hidden />
              </div>
            )}
            {audios.map(media =>
              <MediaItem key={media.id} media={filter(MediaItem.fragments.audio, media)} container />
            )}
          </Container>
          {this.props.children}
        </div>
      );
    } else if (router && !(images.length || videos.length || audios.length)) return null;

    return (
      <div id="MessageMedias">
        <Container key={'medias'} textAlign="center">
          {images && images.nodes && images.nodes.map(media =>
            <div key={media.id}>
              <Image
                centered
                data-action="open-media-item"
                key={media.id} src={media.original} onClick={(e) => {
                  e.preventDefault();
                  this.props.store.appends.push(<MediaItem media={filter(MediaItem.fragments.image, media)} />);
                }}
              />
              <Divider hidden />
            </div>
          )}
          {videos && videos.nodes && videos.nodes.map(media =>
            <div key={media.id}>
              <MediaItem media={filter(MediaItem.fragments.video, media)} container />
              <Divider hidden />
            </div>
          )}
          {audios && audios.nodes && audios.nodes.map(media =>
            <MediaItem key={media.id} media={filter(MediaItem.fragments.audio, media)} container />
          )}
        </Container>
        {this.props.children}
      </div>
    );
  }
}
