import { validateContent } from '../validateContent';

describe('validateContent', () => {
  it('should return true when content and subject is provided', () => {
    const content = {
      content: 'Content Test',
      subject: 'Subject Test',
      medias: [],
      surveys: [],
      commitments: [],
      reports: [],
      charges: [],
      forms: [],
    };
    expect(validateContent(content)).toBeTruthy();
  });
});
