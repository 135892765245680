import React from 'react';
import { inject, observer } from 'mobx-react';
import {
  Image, Container, Divider
} from 'semantic-ui-react';

import MediaItem from '../../Media/Item';

/**
* @typedef {{
*   images: Array<any>,
*   videos: Array<any>,
*   audios: Array<any>,
*   children: React.ReactNode
* }} Props
* @extends {React.Component<Props>}
*/
@inject('store') @observer
class MessageMedias extends React.Component {
  render() {
    const {
      images, videos, audios, router, children, store
    } = this.props;

    const hasImages = !!images && !!images.length;
    const hasVideos = !!videos && !!videos.length;
    const hasAudios = !!audios && !!audios.length;

    if (!hasImages && !hasVideos && !hasAudios) return null;

    if (router && (images.length || videos.length || audios.length)) {
      return (
        <div id="MessageMedias">
          <Container key="medias" textAlign="center">
            {images.map(media => (
              <div key={media.id}>
                <Image
                  data-action="open-media-item"
                  key={media.id}
                  src={media.uri}
                  onClick={(e) => {
                    e.preventDefault();
                    store.appends.push(<MediaItem media={media} />);
                  }}
                />
                <Divider hidden />
              </div>
            ))}
            {videos.map(media => (
              <div key={media.id}>
                <MediaItem media={media} container />
                <Divider hidden />
              </div>
            ))}
            {audios.map(media => <MediaItem key={media.id} media={media} container />)}
          </Container>
          {children}
        </div>
      );
    } if (router && !(images.length || videos.length || audios.length)) return null;

    return (
      <div id="MessageMedias">
        <Container key="medias" textAlign="center">
          {images && images.map(media => (
            <div key={media.id}>
              <Image
                centered
                data-action="open-media-item"
                key={media.id}
                src={media.uri}
                onClick={(e) => {
                  e.preventDefault();
                  store.appends.push(<MediaItem media={media} />);
                }}
              />
              <Divider hidden />
            </div>
          ))}
          {videos && videos.map(media => (
            <div key={media.id}>
              <MediaItem media={media} container />
              <Divider hidden />
            </div>
          ))}
          {audios && audios.map(media => <MediaItem key={media.id} media={media} container />)}
        </Container>
        {children}
      </div>
    );
  }
}

export { MessageMedias };
