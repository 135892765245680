import { getContentFromInput } from '../getContentFromInput';

describe('getContentFromInput', () => {
  it('should return null if input was not provided', async () => {
    expect(getContentFromInput(undefined)).toBeNull();
    expect(getContentFromInput(null)).toBeNull();
    expect(getContentFromInput(false)).toBeNull();
  });

  it('should return null if has no content', async () => {
    const input = JSON.stringify({});
    expect(getContentFromInput(input)).toBeNull();
  });

  it('should return parsed value', async () => {
    const input = JSON.stringify({
      content: 'Test string',
    });
    expect(getContentFromInput(input)).toBe('Test string');
  });
});
