/**
 * Validate forms content
 * @param {{
*  id: number | string
*  hasDateLimit: boolean
*  dateLimit: string
* }} forms
* @returns {boolean}
*/
export const validateForms = (forms) => {
  if (!forms) return null;

  return forms.map((__form) => {
    const params = { id: __form.id };
    if (__form.hasDateLimit && __form.dateLimit) {
      const timeLimit = '23:59';
      const dateLimitToDateObject = new Date(__form.dateLimit);
      params.dateLimit = new Date(dateLimitToDateObject.getTime() - (dateLimitToDateObject.getTimezoneOffset() * 60000)).toISOString().substring(0, 10) + ' ' + timeLimit;
    }

    return params;
  });
};
