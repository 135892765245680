import { castToValidURL } from '../../../utils';

export const validateCommitments = (commitments) => {
  if (!commitments) return null;

  return commitments.map((c) => {
    const params = {
      name: c.name, noConfirm: c.noConfirm, videocallLink: c.videocallLink, videocallType: c.videocallType
    };

    const convertStringToDate = new Date(c.date);

    if (c.hasDate) {
      params.date = new Date(convertStringToDate.getTime() - (convertStringToDate.getTimezoneOffset() * 60000)).toISOString().substring(0, 10);

      if (c.time) {
        params.time = c.time;
      }
    }

    if (c.noConfirm) {
      c.dateLimit = null;
    } else {
      const convertDateLimitStringToDate = new Date(c.dateLimit);
      const time = c.time ? c.time.split(':').map(t => parseInt(t, 10)) : c.time;
      let timeLimit = '23:59';

      if (time && convertStringToDate.getDate() === convertDateLimitStringToDate.getDate() && convertStringToDate.getMonth() === convertDateLimitStringToDate.getMonth()
          && convertStringToDate.getFullYear() === convertDateLimitStringToDate.getFullYear()) {
        timeLimit = c.time;
      }

      params.dateLimit = new Date(convertDateLimitStringToDate.getTime() - (convertDateLimitStringToDate.getTimezoneOffset() * 60000)).toISOString().substring(0, 10) + ' ' + timeLimit;
    }

    if (c.videocallLink) {
      params.videocallLink = castToValidURL(c.videocallLink);
      params.videocallType = c.videocallType;
    } else {
      params.videocallLink = null;
      params.videocallType = null;
    }

    return params;
  });
};
