import React from 'react';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router';
import { List, Icon, Header, Label } from 'semantic-ui-react';
import gql from 'graphql-tag';
import { __ } from '../../i18n';
import * as utils from '../../utils';
import Avatar from '../../components/Avatar';
import ReviewedBy from '../../components/ui/ReviewedBy';

const styles = {
  moreRecipients: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    width: '36px',
    height: '36px',
    backgroundColor: '#9696a0',
    color: '#fff',
    fontWeight: 'bold',
    borderRadius: '4em',
    border: 'solid 2px #ffffff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  noRecipients: {
    width: '48px',
    height: '48px',
    borderRadius: '4em',
    border: 'solid 2px #f0f1f3',
  },
  typeIcon: {
    color: '#9696a0'
  }
};

@inject('store') @observer
export default class MessageDraft extends React.Component {
  static fragments = {
    draft: gql`
      fragment MessageDraft on MessageDraft {
        id: dbId
        created
        modified
        summary
        type
        recipientsCount
        fwMessageId
        pin
        forum
        public
        input
        imagesCount
        videosCount
        audiosCount
        filesCount
        reportsCount
        surveysCount
        commitmentsCount
        chargesCount
        formsCount
        signaturesCount
        entity {
          id: dbId
          fullname
          disabled
          picture {
            uri
            id: dbId
            key
          }
        }
        user {
          id: dbId
          fullname
        }
        toEntity {
          id: dbId
          fullname
          status
          picture {
            uri
            id: dbId
            key
          }
        }
        review {
          id
          user {
            id: dbId
            fullname
          }
          reviewStatus
          reviewIn
        }
        label {
          id: dbId
          title
          color
        }
        userAuthorHasEntity
      }`
  }

  renderInfo() {
    const { draft } = this.props;

    return (
      <span>
        <span>
          {!!draft.pin &&
            <Icon data-name="pin" color="grey" name="star" style={{ margin: 'auto 6px' }} />
          }
          {!!draft.forum &&
            <Icon data-name="forum" color="grey" name="comments" style={{ margin: 'auto 6px' }} />
          }
          {!!draft.filesCount &&
            <Icon data-name="file" color="grey" name="file" style={{ margin: 'auto 6px' }} />
          }
          {!!draft.commitmentsCount &&
            <Icon data-name="commiment" color="grey" name="calendar" style={{ margin: 'auto 6px' }} />
          }
          {!!draft.imagesCount &&
            <Icon data-name="image" className="semantic_icon" color="grey" name="image" style={{ margin: 'auto 6px' }} />
          }
          {!!draft.videosCount &&
            <Icon data-name="video" color="grey" name="video" style={{ margin: 'auto 6px' }} />
          }
          {!!draft.audiosCount &&
            <Icon data-name="audio" color="grey" name="volume up" style={{ margin: 'auto 6px' }} />
          }
          {!!draft.surveysCount &&
            <Icon data-name="survey" color="grey" name="bar chart" style={{ margin: 'auto 6px' }} />
          }
          {!!draft.reportsCount &&
            <Icon data-name="report" color="grey" name="list" style={{ margin: 'auto 6px', padding: '0px' }} />
          }
          {!!draft.public &&
            <Icon data-name="public" color="grey" name="globe" style={{ margin: 'auto 6px' }} />
          }
          {!!draft.chargesCount &&
            <Icon data-name="public" color="grey" name="donate" style={{ margin: 'auto 6px' }} />
          }
          {!!draft.formsCount &&
            <Icon data-name="form" color="grey" name="poll h" style={{ margin: 'auto 6px' }} />
          }
        </span>
      </span>
    );
  }


  renderBody() {
    const { recipientsCount, toEntity, sendAt } = this.props.draft;

    return (<div>
      {
        sendAt ?
          <Icon name="clock" style={styles.typeIcon} />
          :
          <Icon name="pencil" style={{ ...styles.typeIcon, fontWeight: 'bold' }} />
      }
      <span data-name="recipients" data-value={recipientsCount}>
        {
          !recipientsCount ?
            __('No recipient selected')
            :
            recipientsCount === 1 ?
              toEntity.fullname
              :
              __('%s recipients', recipientsCount.toString())
        }
      </span>
    </div>
    );
  }


  render() {
    const { draft, onClick, store, to } = this.props;
    const lang = store.app.locale ? (store.app.locale === 'pt' ? 'pt-BR' : store.app.locale) : 'en';
    const date = draft.sendAt ? utils.simpleDate(draft.sendAt, true, 'ddd DD MMM, LT', lang) : utils.simpleDate(draft.modified || draft.created, true, null, lang);
    const params = {};
    const { fwMessageId } = JSON.parse(draft.input);

    if (to) {
      params.as = Link;
      params.to = to;
    }

    const validateReviewStatus = () => {
      const status = draft?.review?.reviewStatus;

      switch (status) {
        case 'approved':
          return true;
        case 'rejected':
          return true;
        default:
          return false;
      }
    }

    return (
      <List.Item
        onClick={onClick}
        className={'MessageDraft'}
        style={{
          display: 'flex',
          padding: '12px 16px',
          borderBottom: 'solid 1px #e9ecef'
        }}
        title={draft.summary}
        data-id={draft.id}
        {...params}
      >
        <div style={{ width: '48px', height: '48px', position: 'relative' }}>
          {
            draft.recipientsCount ?
              <Avatar
                avatar
                src={draft.toEntity.picture && draft.toEntity.picture.uri}
                alt={draft.toEntity.fullname}
                style={draft.recipientsCount > 1 ? { width: '36px', height: '36px' } : { width: '48px', height: '48px' }}
              />
              :
              (<div style={styles.noRecipients} />)
          }
          {
            draft.recipientsCount > 1 ?
              <div style={styles.moreRecipients}>
                +{draft.recipientsCount - 1}
              </div>
              :
              null
          }
        </div>
        <div style={{ width: '90%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', marginLeft: '16px' }}>
          <div className="horizontallySpacedItems">
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {
                fwMessageId &&
                <Icon data-name="forward-name" name="share" />
              }
              <Header as="h3" style={{ fontSize: 'larger', fontWeight: 'bold' }}>
                {draft.summary !== null ?
                  (draft.summary.length > 45 ?
                    draft.summary.replace(/^(.{43}).+/, '$1...')
                    : draft.summary)
                  : __('No Subject')}
              </Header>
              {
                validateReviewStatus() &&
                <ReviewedBy
                  entity={draft?.review?.user?.fullname}
                  status={draft?.review?.reviewStatus}
                />
              }
            </div>
            <div style={{ display: 'flex' }}>
              {!!draft.label && <Label data-name="label" size="mini" style={{ color: 'white', backgroundColor: `#${draft.label.color}`, marginRight: '8px' }}>{draft.label.title}</Label>}
              {
                !draft.userAuthorHasEntity &&
                <Header size="tiny" style={{ color: '#ffb43c' }}>
                  <Icon style={{ marginRight: '2px' }} alt="User author is no longer linked to entity!" name="warning sign" color="orange" />
                  {__('Error while sending')}
                </Header>
              }
              {
                draft.sendAt && draft.userAuthorHasEntity &&
                <Header size="tiny" style={{ color: '#ffb43c' }}>
                  {date}
                </Header>
              }
              {
                !draft.sendAt && draft.userAuthorHasEntity &&
                <Header size="tiny" disabled>
                  {date}
                </Header>
              }
            </div>
          </div>
          <div className="horizontallySpacedItems">
            <div>
              {this.renderBody()}
            </div>
            <div>
              {this.renderInfo()}
            </div>
          </div>
        </div>
      </List.Item>
    );
  }
}
