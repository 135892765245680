export const validateReports = (__reports) => {
  if (!__reports) return null;

  return __reports.reduce((reports, report) => {
    if (report && report.fields && !report.multiple) {
      report.fields.forEach((field) => {
        if (field.type === 'CHECK' && field.result !== null) {
          field.result = field.result.filter(item => item.selected).map(item => item.value);
          field.result = (field.result.length > 0) ? JSON.stringify(field.result) : null;
        }

        if (!field.result) return;

        reports.push({
          reportId: report.id,
          value: Array.isArray(field.result) ? field.result.toString() : field.result,
          reportFieldId: field.id
        });
      });
    } else if (report && report.fields) {
      report.fields.forEach((field, i) => {
        report.entities.forEach((entity) => {
          if (field.type === 'CHECK' && entity.fields[i] !== null) {
            entity.fields[i] = entity.fields[i].filter(item => item.selected).map(item => item.value);
            entity.fields[i] = (entity.fields[i].length > 0) ? JSON.stringify(entity.fields[i]) : null;
          }

          if (!entity.fields[i]) return;

          reports.push({
            reportId: report.id,
            value: Array.isArray(entity.fields[i]) ? entity.fields[i].toString() : entity.fields[i],
            reportFieldId: field.id,
            entityId: entity.id
          });
        });
      });
    }
    return reports;
  }, []);
};
