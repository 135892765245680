import { getMediaType } from '../../../../utils';
import { validateCharges } from '../../utils/validateCharges';
import { validateCommitments } from '../../utils/validateCommitments';
import { validateForms } from '../../utils/validateForms';
import { validateReports } from '../../utils/validateReports';
import { validateSurveys } from '../../utils/validateSurveys';

/**
  Creates an object with the necessary data to send an approval message.
  @param {Object} values
  @param {string} approverId
  @param {boolean} selectedAll
  @returns {Object|null}
*/
export const createInputMessageToApprove = (values, approverId, selectedAll) => {
  if (values.commitments?.length > 0) {
    values.commitments = validateCommitments(values.commitments);
  }
  if (values.reports?.length > 0) {
    values.reports = validateReports(values.reports);
  }
  if (values.surveys?.length > 0) {
    values.surveys = validateSurveys(values.surveys);
  }
  if (values.charges?.length > 0) {
    values.charges = validateCharges(values.charges);
  }
  if (values.forms?.length > 0) {
    values.forms = validateForms(values.forms);
  }

  if (Object.keys(values).length === 0) return null;

  const { messageToApprove } = values.entity;
  const recipients = ((!!values.allRecipients && !!values.allRecipients.length) || (!!values.allGroups && !!values.allGroups.length)) ? {
    id: approverId,
    selected: !!selectedAll,
    groups: {
      nodes: values.allGroups,
      __typename: 'EntityGroupsConnection'
    },
    recipients: {
      nodes: values.allRecipients,
      __typename: 'EntityRecipientsConnection'
    },
    __typename: 'Entity'
  } : undefined;

  const tags = values.tags.map((tag) => ({ id: tag }));
  const medias = (values && values.medias) ? values.medias.map((media) => ({
    type: media.type && (media.type === 'IMAGE' || media.type === 'FILE' || media.type === 'VIDEO' || media.type === 'AUDIO') ? media.type : getMediaType(media.type, media.name),
    filename: media.name || media.filename,
    origName: media.origName,
    uri: media.response ? media.response.uri : media.uri,
  })) : [];

  return {
    messageToApproveId: messageToApprove.id,
    entityApproverId: approverId,
    summary: values?.summary || values?.subject,
    content: values.content,
    recipients: {
      entityIds: (recipients.recipients.nodes && recipients.recipients.nodes.filter(recipient => Number.isInteger(recipient.id) && recipient.selected).map(recipient => recipient.id)) || [],
      groupIds: (recipients.groups.nodes && recipients.groups.nodes.filter(group => Number.isInteger(group.id) && group.selected).map(group => group.id)) || [],
    },
    reports: values.reports,
    helpers: values.helpers,
    labelId: values.labelId,
    tags,
    pin: values.pin,
    noReply: values.noReply,
    public: values.public,
    medias,
    commitments: values.commitments,
    surveys: values.surveys,
    charges: values.charges,
    forms: values.forms,
  };
};
