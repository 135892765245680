import React from 'react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import { filter } from 'graphql-anywhere';
import { inject, observer } from 'mobx-react';
import { Loader } from 'semantic-ui-react';

import FormResultItem from '../FormResult/Item';

@inject('store')
@graphql(gql`query MessageFormsEntityQuery (
  $entityId: ID!,
  $messageId: ID!
) {
    node(id: $entityId) @connection(key: "Entity", filter: ["entityId"]) {
      ... on Entity {
        id: dbId
        message (id: $messageId ) {
          id: dbId
          forms (limit: 40) {
            nodes {
              id: formMessageId
              ...FormResultItem
            }
          }
        }
      }
    }
  }
${FormResultItem.fragments.form}
`, {
  options: ownProps => ({
    skip:  ownProps.messageToApprove || !ownProps.params.entity_id || ownProps.fromHistory,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    variables: {
    entityId: ownProps.params.entity_id,
    messageId: ownProps.params.message_id
    }
    })
  })
@graphql(gql`query MessageFormsOrganizationQuery (
  $messageId: ID!,
) {
    node(id: $messageId) @connection(key: "Message", filter: ["messageId"]) {
      ... on Message {
        id: dbId
        forms (limit: 40) {
          nodes {
            id: formMessageId
            ...FormResultItem
          }
        }
      }
    }
  }
${FormResultItem.fragments.form}
`, {
  options: ownProps => ({
    skip: ownProps.messageToApprove || !ownProps.params.organization_id || ownProps.fromHistory,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    variables: {
    organizationId: ownProps.params.organization_id,
    messageId: ownProps.params.message_id
    }
    })
  })
@observer
export default class MessageFormResults extends React.Component {
  static fragments = {
    forms: gql`
      fragment MessageFormResults on Message {
        forms (limit: 40) {
          nodes {
            id: key
            ...FormResultItem
          }
        }
      }
      ${FormResultItem.fragments.form}
      `,
  }

  state = {
    isLoaded: false
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!nextProps.fromHistory && !this.state.isLoaded && nextProps?.data?.node && !nextProps?.data?.loading) {
      this.setState({ isLoaded: true });
    }
  }

  render() {
    const { data, params, messageType, entity, fromHistory, messageToApprove } = this.props;
    const { isLoaded } = this.state;

    if (!messageToApprove && !fromHistory && ((data.loading && !isLoaded) || !data.node) ) {
      return <Loader active inline="centered" />; 
    }

    const forms = this.props.forms || (data.node.forms || data.node.message.forms);

    if ((!forms || !forms?.length) && !forms?.nodes?.length) return null;

    return (
      <div id="MessageFormsResults" 
      key={
        messageToApprove ? ( 
          `message-form-results-${forms.map(({ id }) => id)}`
        ):(
          `message-form-results-${forms.nodes.map(({ id }) => id)}`
        )
      }>
        {messageToApprove ? (
            forms.map(form =>
              <FormResultItem
              key={form.id}
              form={form}
              params={params}
              messageType={messageType}
              entity={entity}
              onSubmit={() => data.refetch()}
              fromHistory={this.props.fromHistory}
              messageToApprove={messageToApprove}
              />
            )
          ) : (
            forms.nodes.map(form =>
              <FormResultItem
                key={form.id}
                form={filter(FormResultItem.fragments.form, form)}
                params={params}
                messageType={messageType}
                entity={entity}
                onSubmit={() => data.refetch()}
                fromHistory={this.props.fromHistory}
              />
          ))
        }
        {this.props.children}
      </div>
    );
  }
}
