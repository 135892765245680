import { flow } from 'lodash'

export const updateChargeFromMessageDraft = (charges) => {
    return charges.map((c) => {
        let absorbedFee = c.absorbedFee

        absorbedFee = Object.keys(flow([
            Object.entries,
            arr => arr.filter(([key, value]) => Boolean(value)),
            Object.fromEntries
        ])({
            "BOLETO": c.absorbBoleto,
            "CARD": c.absorbCard,
            "PIX": c.absorbPix
        }));

        const paymentMethodsUpperCase = c.paymentMethods && c.paymentMethods.map(item => item.toUpperCase())

        if (!c.dateLimit) {
            return {
                id: c.id,
                name: c.name,
                description: c.description ? c.description : '',
                amount: c.amount,
                organizationAccountId: c.organizationAccountId,
                absorbedFee,
                paymentMethods: paymentMethodsUpperCase,
                allowedInstallments: c.allowedInstallments
            };
        }
        c.dateLimit = formatChargeDateLimit(c.dateLimit)
        return {
            id: c.id,
            name: c.name,
            description: c.description ? c.description : '',
            amount: c.amount,
            dateLimit: c.dateLimit,
            organizationAccountId: c.organizationAccountId,
            absorbedFee,
            paymentMethods: paymentMethodsUpperCase,
            allowedInstallments: c.allowedInstallments
        };
    });
}

export const formatChargeDateLimit = (dateLimit) => {
    const TIME_LIMIT = '23:59';
    return new Date(dateLimit.getTime() - (dateLimit.getTimezoneOffset() * 60000)).toISOString().substring(0, 10) + ' ' + TIME_LIMIT;
}