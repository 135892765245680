import React from 'react';
import MoreOptionsMenu from '../MoreOptionsMenu';
import { render } from '../../../../../newRender';

describe('<MoreOptionsMenu />', () => {
  it('should render null if no message is provided', () => {
    const { container } = render(<MoreOptionsMenu message={null} />);
    expect(container).toBeEmptyDOMElement();
  });
});
