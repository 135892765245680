import { getContentFromInput } from './getContentFromInput';
import { getCommitmentsFromInput } from './getCommitmentsFromInput';
import { getReportsFromHelpers } from './getReportsFromHelpers';
import { getSurveysFromInput } from './getSurveysFromInput';
import { getFormsFromHelpers } from './getFormsFromHelpers';
import { getMediasFromInput } from './getMediasFromInput';
import { getTagsFromInput } from './getTagsFromInput';
import { getChargesFromInput } from './getChargesFromInput';

/**
 * Parses the messages to approve
 *
 * @param {object} nextProps
 * @param {Promise<(allGroups: Array<any>, allRecipients: Array<any>) => {groups, recipients}>} refetchGroupsAndRecipients
 * @returns {Promise<object>}
 */
export const parseMessageToApprove = async (nextProps, refetchGroupsAndRecipients) => {
  const {
    input, helpers, fwMessageId, user, pin, noReply, public: publicField
  } = nextProps.data.node.messageToApprove;

  const entity = nextProps.data.node;
  const parsedInput = JSON.parse(input);

  const values = {
    ...parsedInput,
    fwMessageId,
    recipients: [],
    user,
    entity,
    public: publicField,
    pin,
    noReply,
    content: getContentFromInput(input),
    tags: getTagsFromInput(input)
  };

  const parsedHelpers = JSON.parse(helpers);

  values.reports = getReportsFromHelpers(helpers);

  values.allGroups = parsedHelpers.recipients && parsedHelpers.recipients.groups && parsedHelpers.recipients.groups.nodes ? parsedHelpers.recipients.groups.nodes : [];
  values.allRecipients = parsedHelpers.recipients && parsedHelpers.recipients.recipients && parsedHelpers.recipients.recipients.nodes ? parsedHelpers.recipients.recipients.nodes : [];

  const { groups, recipients } = await refetchGroupsAndRecipients(values.allGroups, values.allRecipients);
  values.allGroups = groups;
  values.allRecipients = recipients;
  values.groups = groups.filter(group => group.selected);
  values.recipients = recipients.filter(recipient => recipient.selected && values.groups.find(group => group.entities.nodes.find(entityItem => entityItem.id == recipient.id)) === undefined);

  values.surveys = getSurveysFromInput(input);
  values.commitments = getCommitmentsFromInput(values);
  values.charges = getChargesFromInput(input);
  values.forms = getFormsFromHelpers(helpers);

  values.medias = getMediasFromInput(parsedInput, nextProps?.store?.app?.env);

  return values;
};
