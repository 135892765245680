import React from 'react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import { filter } from 'graphql-anywhere';
import { inject, observer } from 'mobx-react';
import { Loader } from 'semantic-ui-react';

import SurveyResultForm from './SurveyResultForm';

@inject('store')
/* @graphql(gql`query MessageSurveyResultsEntityQuery (
  $entityId: ID!,
  $messageId: ID!
) {
    node(id: $entityId) @connection(key: "Entity", filter: ["entityId"]) {
      ... on Entity {
        id: dbId
        message (id: $messageId ) {
          id: dbId
          surveys (limit: 40) {
            nodes {
              id: dbId
              ...SurveyResultForm
            }
          }
        }
      }
    }
  }
${SurveyResultForm.fragments.survey}
`, {
  options: ownProps => ({
    skip: !ownProps.params.entity_id || ownProps.fromHistory,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    variables: {
    entityId: ownProps.params.entity_id,
    messageId: ownProps.params.message_id
    }
    })
  })
@graphql(gql`query MessageSurveyResultsOrganizationQuery (
  $messageId: ID!,
) {
    node(id: $messageId) @connection(key: "Message", filter: ["messageId"]) {
      ... on Message {
        id: dbId
        surveys (limit: 40) {
          nodes {
            id: dbId
            ...SurveyResultForm
          }
        }
      }
    }
  }
${SurveyResultForm.fragments.survey}
`, {
  options: ownProps => ({
    skip: !ownProps.params.organization_id || ownProps.fromHistory,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    variables: {
    organizationId: ownProps.params.organization_id,
    messageId: ownProps.params.message_id
    }
    })
  }) */
@observer

export default class MessageSurveyResults extends React.Component {
  render() {
    const { surveys } = this.props;

    if (!surveys || !surveys.length) return null;

    return (
      <div id="MessageSurveyResults">
        {
          surveys.map(survey => (
            <SurveyResultForm
              key={`message-survey-result-item-${survey.name}`}
              survey={survey}
            />
          ))
        }
      </div>
    );
  }
}
