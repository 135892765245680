/**
 * @param {{
 * store: {
 *  currentUser: {
 *     isMaster: boolean;
 *  }
 * }
 * message: {
 *  entity: {
 *   organizationId: number | string;
 *  }
 * }
 * entityType: 'ADMIN' | 'STAFF' | 'STUDENT',
 * organizationId: number | string
 * params: {
 *  organization_id: number | string;
 * }
 * entity: {
 *  fullname: string,
 * },
 * }} params
 */
export function getEntityLink({
  store,
  message,
  entityType,
  organizationId,
  params,
  entity,
}) {
  return (store.currentUser.isMaster ||
    params.organization_id ||
    (entityType === 'ADMIN' &&
      organizationId === message.entity.organizationId)) &&
    entity.organizationId
    ? `/organizations/${entity.organizationId}/${entity.isChannel ? 'channels' : 'entities'}?search=${entity.fullname}`
    : null;
}
