import React from 'react';
import { inject, observer } from 'mobx-react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import { Button, Icon } from 'semantic-ui-react';
import { withRouter } from 'react-router';
import { cloneDeep, uniqueId, flattenDeep, uniqBy, uniq, flatten } from 'lodash';
import { execute, makePromise } from 'apollo-link';
import { Button as DSButton } from '@classapp-tech/edna';
import { ThumbsUpSolid } from '@classapp-tech/edna-icons';

import Controller from '../../../../components/Controller';
import Modal from '../../../../components/Modal';
import BetaModal from '../../../../components/ui/Modal';
import BetaButton from '../../../../components/ui/Button';

import MessageForm from '../../Form';
import EntityItem from '../../../Entity/Item';

import { __ } from '../../../../i18n';
import * as utils from '../../../../utils';
import { parseMessageToApprove } from '../utils/parseMessageToApprove';
import { getRecipientsFromHelpers } from '../utils/getRecipientsFromHelpers';
import { validateContent } from '../../utils/validateContent';
import { createInputMessageToApprove } from '../utils/createInputMessageToApprove';
import { sendApprovalMessageEvent } from '../utils/sendApprovalMessageEvent';

const GROUPS_SIZE = 40;

const entityGroupRecipientsQuery = gql`
  query EntityGroupRecipientsQuery($entityId: ID!, $groupId: ID!, $selected: Boolean ) {
  node (id: $entityId) {
    ... on Entity {
      id: dbId
      recipients: groupRecipients (groupId: $groupId, limit: 1400) {
        nodes {
          id: dbId
          selected (selected: $selected)
          ... EntityItemRecipient
        }
      }
    }
  }
}
${EntityItem.fragments.recipient}`;

const entityStaffRecipientsQuery = gql`
  query EntityStaffRecipientsQuery($entityId: ID!, $selected: Boolean ) {
  node (id: $entityId) {
    ... on Entity {
      id: dbId
      recipients: staffRecipients (limit: 800) {
        nodes {
          id: dbId
          selected (selected: $selected)
          ... EntityItemRecipient
        }
      }
    }
  }
}
${EntityItem.fragments.recipient}`;

@withRouter @inject('store', 'api', 'client')
@graphql(gql`query MessageToApproveEditQuery($entityId: ID!, $messageToApproveId: ID!) {
  node(id: $entityId) {
    ... on Entity {
      id: dbId
      messageToApprove(id: $messageToApproveId) {
        id
        subject
        content
        summary
        public
        noReply
        pin
        input
        helpers
        created
        modified
        recipientsCount
        deleted
        fwMessageId
        entity {
          id: dbId
          type
          isChannel
          roles {
            nodes {
              id: dbId
              name
            }
          }
          groups {
            totalCount
            nodes {
              id: dbId
              name
            }
          }
          organizationId
          organization {
            id: dbId
            confMedia
            confSurvey
            confCharge
            confReport
            confCommitment
            confForm
            confMediaStudent
            confAdminList
            plan
            premiumPeriod
            isDemonstration
            monthlyMessagesCount
            monthlyMessageQuota
            features {
              messages
              messageManager
              readingControl
              files
              surveys
              commitments
              charges
              moments
              reports
              videos
              audios
              forms
              otherFiles
              dashboard
              labels
              deleteMessages
              editMessages
              videoConference
            }
            labels (limit: 40) {
              nodes {
                id: dbId
                title
                color
              }
            }
            tags (limit: 400) {
              nodes {
                name
                checked
                id: dbId
              }
            }
          }
          fullname
          shortname
          seeAll
          code
          disabled
          picture {
            id
          }
        }
        toEntity {
          id
          isChannel
          status
          organizationId
          id
          fullname
          shortname
          seeAll
          code
          disabled
          type
          picture {
            id
          }
          organization {
            id
            type
          }
        }
        user {
          id: dbId
          fullname
        }
      }
      groups {
        totalCount
        nodes {
          id: dbId
          name
        }
      }
      organization {
        id: dbId
        confMedia
        confSurvey
        confCharge
        confReport
        confCommitment
        confForm
        confMediaStudent
        confAdminList
        plan
        premiumPeriod
        isDemonstration
        monthlyMessagesCount
        monthlyMessageQuota
        features {
          messages
          messageManager
          readingControl
          files
          surveys
          commitments
          charges
          moments
          reports
          videos
          audios
          forms
          otherFiles
          dashboard
          labels
          deleteMessages
          editMessages
          videoConference
        }
        labels (limit: 40) {
          nodes {
            id: dbId
            title
            color
          }
        }
        tags (limit: 400) {
          nodes {
            name
            checked
            id: dbId
          }
        }
      }
    }
  }
}
`, {
  options: ownProps => ({
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    variables: {
      messageToApproveId: ownProps.params.message_id,
      entityId: ownProps.params.entity_id
    },
  }),
})
/* @graphql(gql`mutation updateMessageToApprove($UpdateMessageToApproveInput: UpdateMessageToApproveInput!) {
  updateMessageToApprove(input: $UpdateMessageToApproveInput) {
    messageToApprove {
      id
    }
  }
}`, {
  name: 'updateMessageToApprove',
  options: {
    awaitRefetchQueries: true,
    refetchQueries: [
      'MessageNodeOrganizationQuery',
      'MessageNodeEntityQuery',
      'MessageEditQuery',
      'MessageOrganizationEditQuery',
      'MessageSurveyResultsEntityQuery',
      'MessageSurveyResultsOrganizationQuery',
      'MessageCommitmentRemindsEntityQuery',
      'MessageCommitmentRemindsOrganizationQuery',
      'MessageReportsEntityQuery',
      'MessageReportsOrganizationQuery',
      'MessageEntityHistoryQuery',
      'MessageOrganizationHistoryQuery',
      'EntityMessagesQuery',
      'OrganizationMessagesQuery',
      'MessageFormsEntityQuery',
      'MessageFormsOrganizationQuery'
    ]
  }
}) */
@graphql(gql`mutation approveMessageToApprove($entityId: ID!, $messageId: ID!) {
  approveMessageToApprove(input: {entityApproverId: $entityId, messageToApproveId: $messageId}) {
     messageToApprove {
       id
     }
   }
 }`, {
   name: 'approveMessageToApprove',
   options: {
     refetchQueries: ['EntityMessagesToApprove'],
     awaitRefetchQueries: true
   }
 })
@observer
export default class MessageToApproveEdit extends Controller {
  constructor(props) {
    super(props);
    this.state = {
      values: null
    };

    this.isMessageParsed = false;
  }

  async componentDidUpdate() {
    if (!this.isMessageParsed && !this.props.data.loading) {
      const values = await parseMessageToApprove(this.props, this.refetchGroupsAndRecipients);
      this.isMessageParsed = true;
      this.setState({ values });
    }
    return true;
  }

  refetchGroupsAndRecipients = async (groups, recipients) => {
    const { params, client } = this.props;
    const response = {};

    const promises = groups.filter(group => group.loaded).map(group => makePromise(execute(client.link, {
      query: group.id === 'staff' ? entityStaffRecipientsQuery : entityGroupRecipientsQuery,
      variables: {
        entityId: params.entity_id,
        groupId: group.id,
        selected: group.selected
      },
    })).then((result) => {
      response[group.id] = cloneDeep(result);
    }));

    await Promise.all(promises);

    groups.filter(group => group.loaded).forEach((group) => {
      group.entities.nodes = response[group.id].data.node.recipients.nodes.map((r) => {
        if (r.organization) r.organization.__typename = 'Organization';
        if (r.picture) r.picture.__typename = 'Picture';

        r.__typename = 'Entity';

        return r;
      });
      group.recipientsCount = group.entities.nodes.length;
      group.loaded = true;
    });

    return { groups, recipients };
  }

  updateMessageToApprove = async (mutation, variables, options, client) => {
    return client.mutate({
      mutation,
      variables,
      options
    });
  }

  _handleSubmit = async (values) => {
    const { params, store, router, client } = this.props;

    try {
      const approverId = store && store.currentEntity && store.currentEntity.id;
      const selectedAll = this.state.selectedAll;
      const UpdateMessageToApproveInput = createInputMessageToApprove(values, approverId, selectedAll);
      const variables = {
        UpdateMessageToApproveInput
      };

      const options = {
        awaitRefetchQueries: true,
        refetchQueries: [
          'EntityMessagesQuery',
          'OrganizationMessagesQuery'
        ]
      };

      const UPDATE_MESSAGE_TO_APPROVE_MUTATION = gql`mutation updateMessageToApprove($UpdateMessageToApproveInput: UpdateMessageToApproveInput!) {
        updateMessageToApprove(input: $UpdateMessageToApproveInput) {
          messageToApprove {
            id
          }
        }
      }`;

      await this.updateMessageToApprove(
        UPDATE_MESSAGE_TO_APPROVE_MUTATION,
        variables,
        options,
        client
      );

      store.snackbar = { active: true, message: __('Message to approve edited successfully!'), success: true };

      const baseLink = 'entity_id' in params ?
          `/entities/${params.entity_id}` : `/organizations/${params.organization_id}`;

      this.setState({ onLeave: true }, () => {
        if (store.history.length > 1) {
          router.goBack();
        } else {
          router.push(baseLink + '/messages?type=messagesToApprove');
        }
      });
    } catch(err) {
      this.setState({ loading: !this.state.loading });
      store.snackbar = { active: true, message: utils.handleError(err.graphQLErrors[0]), success: false, dismissAfter: 5000 };
    }
  }

  onCancelEdited = (values) => {
    const hasContent = values && validateContent(values);

    this.props.store.appends.push(<BetaModal
      id="MessageCloseModal"
      toast={this.isMobile()}
      invertCloseButton={this.isMobile()}
      size="tiny"
      onClose={() => this.props.store.appends.pop()}
      header={__('Delete Edit', this.props.object)}
      content={
        this.isMobile() ? this.renderCloseEditedToastContent() :
          hasContent &&
          __('Are you sure you want to discard these changes?')}
      actions={this.isMobile() ? null :
        [
          <BetaButton
            data-action="cancel"
            key={0}
            round
            transparent
            text={__('Cancel')}
            style={{ marginRight: 'auto', marginLeft: 0 }}
            onClick={() => this.props.store.appends.pop()}
          />,
          <BetaButton
            data-action="discard-changes"
            key={2}
            round
            red
            text={__('Discard Changes')}
            style={{ marginRight: 0 }}
            onClick={() => {
              this.props.store.appends.pop();
              this.props.store.appends.pop();
              this.props.store.snackbar = { active: true, message: __('Changes discarded'), success: true };
            }}
          />
        ]
      }
    />);
  }

  renderCloseEditedToastContent = () => (
    <div className="options-toast" style={{ display: 'flex', flexDirection: 'column', marginBottom: '12px' }}>
      <Button
        data-action="discard-changes"
        key={2}
        onClick={() => {
          this.props.store.appends.pop();
          this.props.store.appends.pop();
          this.props.store.snackbar = { active: true, message: __('Changes discarded'), success: true };
        }}
      >
        <span style={{ display: 'flex', fontSize: '16px' }}>
          <Icon name="times" style={{ marginRight: '16px', color: 'rgba(0, 0, 0, 0.6)' }} />
          {__('Discard Changes')}
        </span>
      </Button>
    </div>
  );

  onCancel = (values) => {
    const hasContent = values && validateContent(values);
    const { store, params, router } = this.props;

    store.appends.push(<BetaModal
      id="MessageCloseModal"
      toast={this.isMobile()}
      invertCloseButton={this.isMobile()}
      size={(values.sendAt || !hasContent || values.onCancel) ? 'tiny' : 'small'}
      onClose={() => store.appends.pop()}
      header={__('Discard changes')}
      content={
        this.isMobile() ?
          this.renderCloseToastContent(values) :
          __('This message was not sent and contains unsaved changes.')
      }
      actions={this.isMobile() ? null :
        [
          <BetaButton
            data-action="cancel"
            key={0}
            round
            transparent
            text={__('Cancel')}
            style={{ marginRight: 'auto', marginLeft: 0 }}
            onClick={() => store.appends.pop()}
          />,
            <BetaButton
              data-action="discard"
              key={3}
              round
              transparent
              red
              text={__('Discard')}
              style={{ marginRight: 0 }}
              onClick={() => {
                store.appends.pop();
                this.setState({ onLeave: true }, () => {
                  this.props.router.goBack();
                  store.snackbar = { active: true, message: __('Changes discarded'), success: true };
                });
              }}
            />,
          values.sendAt || !hasContent || values.onCancel ? null :
            (
              <BetaButton
                data-action="save"
                key={1}
                round
                icon={{ name: 'check' }}
                text={__('Save')}
                onClick={async () => {
                  try {
                   sendApprovalMessageEvent('Message edited and saved', store)
                    await this._handleSubmit(values);
                    store.appends.pop();
                  } catch (err) {
                    this.setState({ loading: !this.state.loading });
                    store.snackbar = { active: true, message: utils.handleError(err.graphQLErrors[0]), success: false, dismissAfter: 5000 };
                  }
                }}
              />
            )
        ]
      }
    />);
  }

  renderCloseToastContent = (values) => {
    const hasContent = values && validateContent(values);
    const { store } = this.props;

    return (
      <div className="options-toast" style={{ display: 'flex', flexDirection: 'column', marginBottom: '12px' }}>
        {
            values.sendAt || !hasContent || values.onCancel ? null : <Button
            data-action="save"
            key={1}
            onClick={async () => {
              await store.appends.pop();
              await this._handleSubmit(values);
            }}
          >
            <span style={{ display: 'flex', fontSize: '16px' }}>
              <Icon name="save" style={{ marginRight: '16px', color: 'rgba(0, 0, 0, 0.6)' }} />
              {__('Save')}
            </span>
          </Button>
        }
        {
          <Button
            data-action="discard-changes"
            key={2}
            onClick={() => {
              store.appends.pop();
              this.setState({ onLeave: true }, () => {
                this.props.router.goBack();
                store.snackbar = { active: true, message: __('Changes discarded'), success: true };
              });
            }}
          >
            <span style={{ display: 'flex', fontSize: '16px' }}>
              <Icon name="times" style={{ marginRight: '16px', color: 'rgba(0, 0, 0, 0.6)' }} />
              {__('Discard Changes')}
            </span>
          </Button>
        }
      </div>
    );
  }

  render() {
    const { values } = this.state;
    const { data, store, location } = this.props;


    if ((data.loading || !this.state.values)) return <Modal loading />;

    const entity = data.node.messageToApprove.entity;

    const { type, picture, fullname, groups, organization, id } = entity;
    const { helpers } =  data.node.messageToApprove

    const recipients = getRecipientsFromHelpers(data.node.messageToApprove.helpers, values.allGroups, values.allRecipients);
    const messageToApproveId = data.node.messageToApprove.id
    const isConsultant = !store.currentOrganization;
    let organizationIds = null;
    if (isConsultant) {
      const organizationId = recipients
        && recipients.recipients
        && recipients.recipients.nodes
        && recipients.recipients.nodes[0]
        && recipients.recipients.nodes[0].organization
        && recipients.recipients.nodes[0].organization.id;
      organizationIds = [organizationId];
    }
    const isMessageWriter = utils.isMessageWriter({ store });
    const isWriterAndMessageApproved = isMessageWriter && data?.draft?.review?.reviewStatus === 'approved';

    return (
      <Controller
        id="MessageToApproveEdit"
        edit
        toggleLoading={() => this.setState({ loading: !this.state.loading })}
        form={MessageForm}
        loading={this.state.loading}
        disableSave={isWriterAndMessageApproved}
        errors={this.state.errors}
        onSubmit={async (_values) => {
          try {
            await this._handleSubmit(_values);
            await this.props.approveMessageToApprove({
              variables: {
                entityId: _values.entity.id,
                messageId: _values.entity.messageToApprove.id,
              }
            });

            this.setState({ onLeave: true }, () => {
              if (store.history.length > 1) {
                this.props.router.goBack();
              } else {
                this.props.router.push(baseLink + '/messages');
              }
            });
          } catch(err) {
            this.setState({ loading: !this.state.loading });
            store.snackbar = { active: true, message: utils.handleError(err.graphQLErrors[0]), success: false, dismissAfter: 5000 };
          }
        }}
        submitButton={{
          text: isMessageWriter ? __('Submit for approval') : __('Send now'),
          isActionButtom: true,
          DSButton: true
        }}
        onCancel={v => this.onCancel(v)}
        cancelButton={{
          text: null,
          icon: { name: 'trash', style: { margin: '0px', color: '#000000' } },
          style: { boxShadow: 'none' },
          isActionButtom: true
        }}
        formProps={{
          organization: {
            id: organization && organization.id,
            confMedia: organization && organization.confMedia,
            confSurvey: organization && organization.confSurvey,
            confCharge: organization && organization.confCharge,
            confReport: organization && organization.confReport,
            confCommitment: organization && organization.confCommitment,
            confForm: organization && organization.confForm,
            confMediaStudent: organization && organization.confMediaStudent,
            confAdminList: organization && organization.confAdminList,
            tags: organization && organization.tags.nodes,
            features: organization && organization.features,
            labels: organization && organization.labels.nodes,
            plan: organization && organization.plan,
            premiumPeriod: organization && organization.premiumPeriod,
            monthlyMessagesCount: organization && organization.monthlyMessagesCount,
            monthlyMessageQuota: organization && organization.monthlyMessageQuota,
          },
          isMessageToApprove: true,
          entity: {
            id,
            type,
            fullname,
            picture,
            groups
          },
          recipients,
          modalTitle: (values && values.sendAt) ? __('Edit send later') : __('Edit message'),
          location,
          fwMessageId: null,
          id: 'MessageToApproveEdit',
          consultantForm: isConsultant,
          organizationIds,
          messageToApproveId,
          helpers,
        }}
        values={values}
        {...this.props}
      />
    );
  }
}
