import React from 'react';

import MessageEdit from './ui/Message';
import MessageDraft from './ui/MessageDraft';
import MessageToApprove from './ui/MessageToApprove';

export default class MessageNode extends React.Component {
  render() {
    /**
     * @type {{
     *    type: 'MESSAGE' | 'MESSAGE_DRAFT' | 'MESSAGE_TO_APPROVE',
     * }} props
     */
    const { type, shouldSkipDraft, location } = this.props;

    if (type === 'MESSAGE_DRAFT' || location?.query?.type === 'drafts') {
      return (
        <MessageDraft {...this.props} />
      );
    }
    if (type === 'MESSAGE_TO_APPROVE' || location?.query?.type === 'messagesToApprove') {
      return (
        <MessageToApprove {...this.props} />
      );
    }
    return (
      <MessageEdit shouldSkipDraft={shouldSkipDraft} {...this.props} />
    );
  }
}
