import React from 'react';
import { Link } from 'react-router';
import { Icon, Dropdown, Popup } from 'semantic-ui-react';
import moment from 'moment';

import MessageEdit from '../../Edit';
import MessageHistory from '../../History';

import { isEntityType } from '../../../../utils';

import { __ } from '../../../../i18n';

class MoreOptionsMenu extends React.Component {
  render() {
    const {
      isMobile,
      message,
      isMessageOwner,
      canModifyMessage,
      hasEditMessagesFeature,
      hasDeleteMessagesFeature,
      isMaster,
      isWriterAndApproved,
      store,
      newParams,
      location,
      params,
      userHasEntity,
      canDeleteMessage,
      baseLink,
      setOpenPaywall,
      setUpdateStatus,
      setDeleteMessage
    } = this.props;

    const moreOptions = [];

    if (!message) return null;

    if (!message.deleted && (isMessageOwner || canModifyMessage) && (hasEditMessagesFeature || isMaster)) {
      moreOptions.push(<Dropdown.Item
        data-action="update-message"
        data-params="edit"
        text={__('Edit message')}
        disabled={isWriterAndApproved}
        icon="edit"
        onClick={() => store.appends.push(<MessageEdit shouldSkipDraft type="MESSAGE" message={message} params={newParams} location={location} />)}
      />);
    } else if ((!message.deleted && (isMessageOwner || canModifyMessage) && !isEntityType(store, 'STUDENT'))) {
      moreOptions.push(
        <Dropdown.Item
          data-action="update-message"
          data-params="edit"
          onClick={setOpenPaywall}
        >
          <Icon name="edit" />
          <span>{__('Edit message')}</span>
          <Icon name="stars" style={{ marginLeft: '3px', color: '#A5A5A5' }} />
        </Dropdown.Item>
      );
    }

    if ((message.type === 'SENT' || params.organization_id || store.currentEntity.type === 'ADMIN') && message.logs.totalCount > 0) {
      moreOptions.push(<Dropdown.Item
        data-action="open-edit-history"
        data-params="history"
        text={__('View edit history')}
        icon="history"
        onClick={() => store.appends.push(<MessageHistory params={newParams} message={message} />)}
      />);
    }

    if (userHasEntity && !location.query.forwarded) {
      moreOptions.push(<Dropdown.Item
        data-action="update-status"
        data-params="unread"
        text={__('Mark as unread')}
        icon="envelope"
        onClick={() => setUpdateStatus('AS_UNREAD')}
      />);

      if (hasDeleteMessagesFeature || isMaster) {
        moreOptions.push(<Dropdown.Item
          data-action="update-status"
          data-params="delete"
          text={__('Delete for me')}
          disabled={isWriterAndApproved}
          icon="trash"
          onClick={setDeleteMessage}
        />);
      } else if (store.currentEntity.type !== 'STUDENT') {
        moreOptions.push(
          <Dropdown.Item
            data-action="update-status"
            data-params="delete"
            onClick={setOpenPaywall}
          >
            <Icon name="trash" />
            <span>{__('Delete for me')}</span>
            <Icon name="stars" style={{ marginLeft: '3px', color: '#A5A5A5' }} />
          </Dropdown.Item>
        );
      }
    }

    if (canDeleteMessage) {
      moreOptions.push(<Dropdown.Item
        as={Link}
        data-action="update-status"
        data-params="delete"
        disabled={isWriterAndApproved}
        text={__('Delete for everyone')}
        icon="trash alt"
        to={`${baseLink}/messages/${params.message_id}/delete?deleteForEveryone=true`}
      />);
    } else if ((!message.deleted && (isMessageOwner || canModifyMessage)) && store.currentEntity.type !== 'STUDENT' && (moment(message.created) >= moment().subtract(1, 'hour'))) {
      moreOptions.push(
        <Dropdown.Item
          data-action="update-message"
          data-params="delete"
          onClick={setOpenPaywall}
          disabled={isWriterAndApproved}
        >
          <Icon name="trash alt" />
          <span>{__('Delete for everyone')}</span>
          <Icon name="stars" style={{ marginLeft: '3px', color: '#A5A5A5' }} />
        </Dropdown.Item>
      );
    }

    if (userHasEntity && !location.query.forwarded) {
      moreOptions.push(<Dropdown.Item
        data-action="update-status"
        data-params="archive"
        text={(message.status === 4)
          ? __('Unarchive') : __('Archive')}
        icon="archive"
        onClick={() => setUpdateStatus(message.status !== 4 ? 'ARCHIVED' : 'UNARCHIVED')}
      />);
    }

    if (!moreOptions.length) {
      return null;
    }

    return (
      isMobile
        ? (
          <Dropdown data-action="open-options" data-params="more" icon="ellipsis h" className="link item icon">
            <Dropdown.Menu>
              {moreOptions}
            </Dropdown.Menu>
          </Dropdown>
        )
        : (
          <Popup
            trigger={(
              <Dropdown data-action="open-options" data-params="more" icon="ellipsis h" className="link item icon">
                <Dropdown.Menu>
                  {moreOptions}
                </Dropdown.Menu>
              </Dropdown>
    )}
            content={isWriterAndApproved ? __('Approved messages can only be edited by an reviewer') : __('More')}
          />
        )
    );
  }
}

export default MoreOptionsMenu;
