import React from 'react';
import { inject, observer } from 'mobx-react';
import { ReportResultItem } from './ReportResultItem';

@inject('store')
@observer
class MessageReportResults extends React.Component {
  render() {
    const {
      params, messageType, reports
    } = this.props;

    if (!reports || !reports?.length) return null;

    return (
      <div id="MessageReportResults">
        {
          reports?.map(report => (
            <ReportResultItem
              key={report?.id?.toString() || report?.name}
              report={report}
              params={params}
              messageType={messageType}
            />
          ))
        }
      </div>
    );
  }
}

export { MessageReportResults };
