/* eslint-disable no-debugger */
import React from 'react';
import { inject, observer } from 'mobx-react';
import { Button, Popup, Icon } from 'semantic-ui-react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import { withRouter } from 'react-router';
import moment from 'moment';
import { uniq, flatten } from 'lodash';
import cookie from 'react-cookie';

import Controller from '../../components/Controller';
import Modal from '../../components/Modal';
import BetaModal from '../../components/ui/Modal';
import BetaButton from '../../components/ui/Button';

import MessageForm from './Form';

import { __ } from '../../i18n';
import * as utils from '../../utils';

import { validateCommitments } from './utils/validateCommitments';
import { validateReports } from './utils/validateReports';
import { validateSurveys } from './utils/validateSurveys';
import { validateCharges } from './utils/validateCharges';
import { validateForms } from './utils/validateForms';
import { validateContent } from './utils/validateContent';
import { sendApprovalMessageEvent } from './Edit/utils/sendApprovalMessageEvent';

@withRouter @inject('store', 'client')
@graphql(gql`query MessageAddQuery ($id: ID!) {
    node(id: $id) @connection(key: "Entity", filter: ["id"]) {
      ... on Entity {
        id: dbId
        type
        fullname
        picture {
          uri
          id: dbId
          key
        }
        organization {
          id: dbId
          confMedia
          confSurvey
          confCharge
          confReport
          confCommitment
          confForm
          confMediaStudent
          confAdminList
          plan
          premiumPeriod
          isDemonstration
          monthlyMessagesCount
          monthlyMessageQuota
          features {
            messages
            messageManager
            readingControl
            files
            surveys
            commitments
            charges
            moments
            reports
            videos
            audios
            forms
            otherFiles
            api
            dashboard
            labels
            deleteMessages
            editMessages
            videoConference
          }
          tags (limit: 400) {
            nodes {
              name
              checked
              id: dbId
            }
          }
          labels (limit: 100) {
            nodes {
              id: dbId
              title
              color
            }
          }
        }
        groups {
          totalCount
        }
      }
    }
  }
`, {
  options: ownProps => ({
    variables: {
      id: ownProps.params.entity_id
    }
  })
})
@graphql(gql`mutation createMessage($createMessageMutation: CreateMessageInput!) {
  createMessage(input: $createMessageMutation) {
    clientMutationId
  }
}`, {
  name: 'createMessage',
  options: (ownProps) => {
    const refetchQueries = ['MessageRecipientsQuery', 'EntityCalendarQuery', 'EntityMessagesQuery'];
    const { hasAdmin, entityScopes } = ownProps.store.currentOrganization.permissions;

    if (ownProps.store.currentUser.isMaster || hasAdmin || entityScopes.includes('READ_MESSAGE')) {
      refetchQueries.push('OrganizationMessagesQuery');
    }

    return {
      awaitRefetchQueries: true,
      refetchQueries
    };
  }
})
@graphql(gql`mutation createMessageToApprove($CreateMessageToApproveInput: CreateMessageToApproveInput!) {
  createMessageToApprove(input: $CreateMessageToApproveInput) {
    messageToApprove {
			id
    }
  }
}`, {
  name: 'createMessageToApprove',
  options: (ownProps) => {
    const refetchQueries = ['MessageRecipientsQuery', 'EntityCalendarQuery', 'EntityMessagesQuery'];
    const { hasAdmin, entityScopes } = ownProps.store.currentOrganization.permissions;

    if (ownProps.store.currentUser.isMaster || hasAdmin || entityScopes.includes('READ_MESSAGE')) {
      refetchQueries.push('OrganizationMessagesQuery');
    }

    return {
      awaitRefetchQueries: true,
      refetchQueries
    };
  }
})
@graphql(gql`mutation createMessageDraft($createMessageDraftMutation: CreateMessageDraftInput!) {
  createMessageDraft(input: $createMessageDraftMutation) {
    clientMutationId
  }
}`, {
  name: 'createMessageDraft',
  options: {
    refetchQueries: ['EntityMessagesQuery', 'EntitySendLaterQuery', 'EntityDraftsQuery', 'MessageRecipientsQuery']
  }
})
@observer
class MessageAdd extends Controller {
  static fetchData({ store }) {
    store.app.title = __('Send message');
  }

  UNSAFE_componentWillMount() {
    const { location, store } = this.props;
    if (location?.query.template) {
      const subject = cookie.load(`${location.query.template}TemplateMessageSubject`) || '';
      const content = (store.template && store.template[location.query.template] && store.template[location.query.template].content) || '';
      const tags = cookie.load(`${location.query.template}TemplateMessageTags`) || [];
      const recipients = (store.template && store.template[location.query.template] && store.template[location.query.template].recipients) || [];

      if (subject || content || recipients.length || tags) {
        const selectedRecipients = recipients.map(el => ({ ...el, selected: true }));
        const templateValues = {
          subject, content, recipients, allRecipients: selectedRecipients, tags
        };
        const loadedRecipients = {
          groups: {
            nodes: []
          },
          recipients: {
            nodes: [...selectedRecipients]
          },
          selected: true
        };

        this.setState({ values: templateValues, loadedRecipients });
      }
    }
  }

  componentDidMount() {
    const isForwardMessage =  
    sessionStorage.getItem('forwardMessageSubject') !== null && 
    sessionStorage.getItem('forwardMessageContent') !== null 

    this.props.router.setRouteLeaveHook(this.props.route, () => {
      if (this.props.store.appends[0] !== undefined) {
        this.props.store.appends.pop();
        return false;
      } if (!this.state.onLeave) {
        const confirmed = window.confirm(__('Your message was not sent! Are you sure you want to leave?'));
        this.shouldNavigate = confirmed;
        return confirmed ? null : false;
      }
    });

    if(isForwardMessage) {
      const forwardMessageSubject = sessionStorage.getItem('forwardMessageSubject')
      const forwardMessageContent = sessionStorage.getItem('forwardMessageContent')

      const decodeForwardMessageSubject = atob(forwardMessageSubject)
      const decodeForwardMessageContent = atob(forwardMessageContent)

      const Values = {
        ...Values,
        subject: decodeForwardMessageSubject, 
        content: decodeForwardMessageContent
      };
      this.setState({ values: Values });
      sessionStorage.removeItem('forwardMessageSubject')
      sessionStorage.removeItem('forwardMessageContent')
    }
  }

  treatValues = (values) => {
    const hasStaffGroup = values.groups.find(group => group === 'staff');

    if (hasStaffGroup) {
      values.types = 'STAFF';
      values.groups = values.groups.filter(group => group !== 'staff');
    }

    if (values.commitments.length > 0) {
      values.commitments = validateCommitments(values.commitments);
    }

    if (values.reports.length > 0) {
      values.reports = validateReports(values.reports);
    }

    if (values.surveys.length > 0) {
      values.surveys = validateSurveys(values.surveys);
    }

    if (values.charges.length > 0) {
      values.charges = validateCharges(values.charges);
    }

    if (values.forms.length > 0) {
      values.forms = validateForms(values.forms);
    }

    values.content = values.content.replace(/<a href/g, '<a target="_blank" rel="noreferrer noopener" href');

    return values;
  }

  request = (values, sendDraft = false) => {
    const {
      params, createMessage, createMessageDraft, store, router, data
    } = this.props;

    let request = { mutate: createMessage, input: 'createMessageMutation', message: __('Message was sent') };
    const organization = data.node && data.node.organization;

    if (!utils.canSubmitMessageWithRecipients(values, organization) && !values.sendAt) {
      // eslint-disable-next-line no-param-reassign
      sendDraft = true;
    }

    // eslint-disable-next-line no-param-reassign
    values = this.treatValues(values);

    const props = {
      entityId: this.props.params.entity_id,
      isHtml: true,
      subject: values.subject,
      content: values.content,
      pin: values.pin,
      public: values.public,
      forum: values.forum,
      noReply: values.noReply,
      surveys: values.surveys,
      commitments: values.commitments,
      reports: values.reports,
      medias: values.medias,
      tags: values && values.tags && values.tags.map(id => ({ id })),
      labelId: values.labelId,
      recipients: {
        entityIds: values.recipients,
        groupIds: values.groups,
        types: values.types
      },
      charges: values.charges,
      forms: values.forms
    };

    if (values.fwMessageId) props.fwMessageId = values.fwMessageId;

    if (sendDraft) {
      props.type = values.type;
      props.helpers = values.helpers;
      request = { mutate: createMessageDraft, input: 'createMessageDraftMutation', message: __('Draft saved successfully!') };
    } else if (values.sendAt) {
      const lang = store.app.locale ? (store.app.locale === 'pt' ? 'pt-BR' : store.app.locale) : 'en';

      values.sendAt.setSeconds(0);

      props.sendAt = values.sendAt;
      props.type = values.type;
      props.helpers = values.helpers;
      request = {
        mutate: createMessageDraft,
        input: 'createMessageDraftMutation',
        message: __('Message scheduled to send on %s', utils.simpleDate(values.sendAt, true, 'ddd, DD MMM, LT', lang))
      };
    }

    if (sendDraft) {
      this.setState({ loading: !this.state.loading });
    }

    return request.mutate({
      variables: {
        [request.input]: {
          ...props,
          fwMessageId: typeof props.fwMessageId === 'string' ? parseInt(props.fwMessageId, 10) : props.fwMessageId
        }
      }
    }).then(() => {
      store.snackbar = { active: true, message: request.message, success: true };
      const baseLink = 'entity_id' in params
        ? `/entities/${params.entity_id}` : `/organizations/${params.organization_id}`;

      this.setState({ onLeave: true }, () => {
        if (store.history.length > 1) {
          router.goBack();
        } else {
          router.push(baseLink + '/messages');
        }
      });
    }).catch((err) => {
      this.setState({ loading: !this.state.loading });
      store.snackbar = {
        active: true, message: utils.handleError(err.graphQLErrors[0]), success: false, dismissAfter: 5000
      };
    });
  }

  onCancel = (values) => {
    const previousUrl = this.props.store?.history[this.props.store?.history?.length - 2];
    const hasContent = values && validateContent(values);

    this.props.store.appends.push(<BetaModal
      id="MessageCloseModal"
      toast={this.isMobile()}
      invertCloseButton={this.isMobile()}
      size={hasContent && !values.onCancel ? 'small' : 'tiny'}
      onClose={() => this.props.store.appends.pop()}
      header={__('Discard message', this.props.object)}
      content={
        this.isMobile()
          ? this.renderCloseToastContent(values)
          : hasContent && !values.onCancel
            ? __('This message has not been sent and contains unsaved changes. You can save it as a draft to work on later.')
            : __('Are you sure you want to discard this message?')
      }
      actions={this.isMobile() ? null
        : [
          <BetaButton
            data-action="cancel"
            key={0}
            round
            transparent
            text={__('Cancel')}
            style={{ marginRight: 'auto', marginLeft: 0 }}
            onClick={() => this.props.store.appends.pop()}
          />,
          <BetaButton
            data-action="submit"
            key={2}
            round
            red
            secondary={hasContent && !values.onCancel}
            text={__('Discard')}
            style={!(hasContent && !values.onCancel) ? { marginRight: 0 } : {}}
            onClick={() => {
              sessionStorage.removeItem('forwardMessageSubject')
              sessionStorage.removeItem('forwardMessageContent')

              this.props.store.appends.pop();
              this.setState({ onLeave: true }, () => {
                if (this.props.store.history.length > 1) {
                    window.location.href = previousUrl;
                  // this.props.store.snackbar = { active: true, message: __('Message discarded'), success: true };
                } else {
                  const link = `/entities/${this.props.params.entity_id}/messages`;
                  this.props.router.push(link);
                  // this.props.store.snackbar = { active: true, message: __('Message discarded'), success: true };
                }
              });
            }}
          />,
          hasContent && !values.onCancel ? (
            values.medias.filter(media => media.loading).length
              ? (
                <Popup
                  key={1}
                  trigger={(
                    <span>
                      <BetaButton
                        data-action="draft"
                        disabled
                        round
                        icon={{ name: 'check' }}
                        text={__('Save as draft')}
                      />
                    </span>
)}
                  content={__('You can not save this message as draft because there is one or more medias uploading, please go back to the message and wait for the upload to complete.')}
                />
              )
              : (
                <BetaButton
                  data-action="draft"
                  key={1}
                  round
                  icon={{ name: 'check' }}
                  text={__('Save as draft')}
                  onClick={() => {
                    this.props.store.appends.pop();
                    this.request(values, true);
                  }}
                />
              )
          ) : null
        ]}
    />);
  }

  renderCloseToastContent = (values) => {
    const hasContent = values && validateContent(values);

    return (
      <div className="options-toast" style={{ display: 'flex', flexDirection: 'column', marginBottom: '12px' }}>
        {
          [
            hasContent && !values.onCancel ? (
              <Button
                data-action="draft"
                key={1}
                onClick={() => {
                  this.props.store.appends.pop();
                  this.request(values, true);
                }}
              >
                <span style={{ display: 'flex', fontSize: '16px' }}>
                  <Icon name="save" style={{ marginRight: '16px', color: 'rgba(0, 0, 0, 0.6)' }} />
                  {__('Save as draft')}
                </span>
              </Button>
            ) : null,
            <Button
              data-action="submit"
              key={2}
              onClick={() => {
                this.props.store.appends.pop();
                this.setState({ onLeave: true }, () => {
                  if (this.props.store.history.length > 1) {
                    this.props.router.goBack();
                    // this.props.store.snackbar = { active: true, message: __('Message discarded'), success: true };
                  } else {
                    const link = `/entities/${this.props.params.entity_id}/messages`;
                    this.props.router.push(link);
                    // this.props.store.snackbar = { active: true, message: __('Message discarded'), success: true };
                  }
                });
              }}
            >
              <span style={{ display: 'flex', fontSize: '16px', color: '#BF2600' }}>
                <Icon name="trash alt" style={{ marginRight: '16px' }} />
                {__('Discard Message')}
              </span>
            </Button>
          ]
        }
      </div>
    );
  }

  renderExpiredLimitModal = (values) => {
    const { store } = this.props;

    this.props.store.appends.push(<BetaModal
      id="MessageCloseModal"
      size="tiny"
      onClose={() => this.props.store.appends.pop()}
      header={__('Message limit reached')}
      content={(
        <span>
          {__("Your message won't be send because the plan limit has been reached out. You still can salve as draft to send it later.")}
          {' '}
          {utils.isEntityType(store, 'STAFF') && __('Contact the admin for more information.')}
        </span>
      )}
      actions={[
        <BetaButton
          data-action="cancel"
          key={0}
          round
          transparent
          style={{ marginRight: 'auto', marginLeft: 0 }}
          text={__('Cancel')}
          onClick={() => this.props.store.appends.pop()}
        />,
        <BetaButton
          data-action="submit"
          key={1}
          round
          text={__('Save as draft')}
          style={{ marginRight: 0 }}
          onClick={() => {
            this.props.store.appends.pop();
            this.request(values, true);
          }}
        />
      ]}
    />);
  }

  onSendToApproving = (values) => {
    const {
      params, createMessageToApprove, createMessageDraft, store, router, data
    } = this.props;
    let sendDraft = false;

    let request = { mutate: createMessageToApprove, input: 'CreateMessageToApproveInput', message: __('Message sent for approval') };
    const organization = data.node && data.node.organization;

    if (!utils.canSubmitMessageWithRecipients(values, organization) && !values.sendAt) {
      sendDraft = true;
    }

    if (request) {
      this.setState({ loading: true });
    }

    // eslint-disable-next-line no-param-reassign
    values = this.treatValues(values);

    const userId = (store.currentUser && store.currentUser.id) || null;

    const props = {
      entityId: this.props.params.entity_id,
      isHtml: true,
      userId,
      organizationId: (organization && organization.id) || null,
      helpers: values.helpers,
      summary: values.subject,
      pin: values.pin,
      public: values.public,
      forum: values.forum,
      noReply: values.noReply,
      content: values.content,
      surveys: values.surveys,
      commitments: values.commitments,
      reports: values.reports,
      medias: values.medias,
      tags: values && values.tags && values.tags.map(id => ({ id })),
      labelId: values.labelId,
      recipients: {
        entityIds: values.recipients,
        groupIds: values.groups,
        types: values.types
      },
      charges: values.charges,
      forms: values.forms
    };

    if (values.fwMessageId) props.fwMessageId = values.fwMessageId;

    if (sendDraft) {
      props.type = values.type;
      request = { mutate: createMessageDraft, input: 'createMessageDraftMutation', message: __('Draft saved successfully!') };
    } else if (values.sendAt) {
      const lang = store.app.locale ? (store.app.locale === 'pt' ? 'pt-BR' : store.app.locale) : 'en';

      values.sendAt.setSeconds(0);

      props.sendAt = values.sendAt;
      request.message = __('Message scheduled to send on %s', utils.simpleDate(values.sendAt, true, 'ddd, DD MMM, LT', lang));
    }

    if (sendDraft) {
      this.setState({ loading: !this.state.loading });
    }

    return request.mutate({
      variables: {
        [request.input]: {
          ...props
        }
      }
    }).then(() => {
      sendApprovalMessageEvent('Message sent for approval', store);
      this.setState({ loading: true });
      store.snackbar = { active: true, message: request.message, success: true };
      const baseLink = 'entity_id' in params
        ? `/entities/${params.entity_id}` : `/organizations/${params.organization_id}`;

      this.setState({ onLeave: true }, () => {
        if (store.history.length > 1) {
          router.goBack();
        } else {
          router.push(baseLink + '/messages');
        }
      });
    }).catch((err) => {
      this.setState({ loading: !this.state.loading });
      store.snackbar = {
        active: true, message: utils.handleError(err.graphQLErrors[0]), success: false, dismissAfter: 5000
      };
    });
  }

  render() {
    const {
      data, params, location, store
    } = this.props;
    const { unleash } = store;
    // eslint-disable-next-line no-unused-vars
    const hasNetworkCommunication = (unleash && unleash.isReady && unleash.getStatus('network_communication'));

    let recievedTags;
    if (location?.query.template) {
      recievedTags = cookie.load(`${location.query.template}TemplateMessageTags`) || [];
    }

    if ((data.loading && !data.node) || !data.node) return <Modal loading />;

    const {
      organization, type, picture, fullname, groups
    } = data.node;

    const hasMessageApproval = store.currentOrganization && store.currentOrganization.unleashStatus && store.currentOrganization.unleashStatus.approve_message
      && store.currentOrganization.plan === 'PREMIUM';

    const isMaster = store?.currentUser && store?.currentUser?.isMaster;

    // Verifica se é redator
    const isMessageWriter = (store?.entity?.policy?.some(
      policyPermission => (policyPermission === 'NEED_APPROVAL_MESSAGE' || policyPermission === 'NEED_APPROVAL_REPLY')
    )) && hasMessageApproval && store?.entity?.type !== 'ADMIN' && !isMaster;

    const organizationProps = (organization) ? {
      id: organization.id,
      confMedia: organization.confMedia,
      confSurvey: organization.confSurvey,
      confCharge: organization.confCharge,
      confReport: organization.confReport,
      confCommitment: organization.confCommitment,
      confForm: organization.confForm,
      confMediaStudent: organization.confMediaStudent,
      confAdminList: organization.confAdminList,
      tags: recievedTags || organization.tags.nodes,
      features: organization.features,
      labels: organization.labels.nodes,
      plan: organization.plan,
      premiumPeriod: organization.premiumPeriod,
      monthlyMessagesCount: organization.monthlyMessagesCount,
      monthlyMessageQuota: organization.monthlyMessageQuota
    } : {};

    return (
      <Controller
        id="MessageAdd"
        add
        toggleLoading={() => this.setState({ loading: !this.state.loading })}
        form={MessageForm}
        loading={this.state.loading}
        errors={this.state.errors}
        onSubmit={values => {
          sessionStorage.removeItem('forwardMessageSubject')
          sessionStorage.removeItem('forwardMessageContent')
          
          const hasStudents = utils.hasStudentInList(values.allRecipients && values.allRecipients.filter(recipient => recipient.selected === true))
          || utils.hasStudentInGroup(values.allGroups && values.allGroups.filter(group => group.selected === true));
          return (utils.canSubmitMessageWithRecipients(values, organization) || values.sendAt
            ? (isMessageWriter && hasStudents) ? this.onSendToApproving(values) : this.onSubmit(values)
            : this.renderExpiredLimitModal(values));
        }}
        submitButton={{ text: isMessageWriter ? __('Submit for approval') : __('Send'), isActionButtom: true }}
        onCancel={values => this.onCancel(values)}
        cancelButton={{
          text: null,
          icon: { name: 'trash', style: { margin: '0px', color: '#000000' } },
          style: { boxShadow: 'none' },
          isActionButtom: true
        }}
        formProps={{
          organization: organizationProps,
          entity: {
            id: params.entity_id,
            type,
            fullname,
            picture,
            groups
          },
          modalTitle: __('Write message'),
          id: 'MessageAdd',
          location,
          params,
          recipients: this.state.loadedRecipients
        }}
        values={this.state.values || null}
        {...this.props}
      />
    );
  }
}

export default MessageAdd;
