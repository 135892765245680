import React from 'react';
import MessageNode from '..';
import { render } from '../../../../newRender';

jest.mock('../ui/Message', () => ({
  __esModule: true,
  default: () => <div>Message</div>,
}));

jest.mock('../ui/MessageDraft', () => ({
  __esModule: true,
  default: () => <div>MessageDraft</div>,
}));

jest.mock('../ui/MessageToApprove', () => ({
  __esModule: true,
  default: () => <div>MessageToApprove</div>,
}));

describe('given MessageEdit component', () => {
  let mockedProps = null;

  beforeEach(() => {
    jest.clearAllMocks();
    mockedProps = {
      type: 'MESSAGE',
      shouldSkipDraft: false,
    };
  });

  it('should be able to render MESSAGE if type is MESSAGE', () => {
    const { container, getByText } = render(<MessageNode {...mockedProps} />);

    expect(container).toBeInTheDocument();

    expect(getByText('Message')).toBeVisible();
  });

  it('should be able to render MESSAGE_DRAFT if type is MESSAGE_DRAFT', () => {
    mockedProps.type = 'MESSAGE_DRAFT';
    const { container, getByText } = render(<MessageNode {...mockedProps} />);

    expect(container).toBeInTheDocument();

    expect(getByText('MessageDraft')).toBeVisible();
  });

  it('should be able to render MESSAGE_TO_APPROVE if type is MESSAGE_TO_APPROVE', () => {
    mockedProps.type = 'MESSAGE_TO_APPROVE';
    const { container, getByText } = render(<MessageNode {...mockedProps} />);

    expect(container).toBeInTheDocument();

    expect(getByText('MessageToApprove')).toBeVisible();
  });
});
