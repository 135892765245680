/**
 * Get the surveys from input and validate it
 * @param {string} input
* @returns {string}
*/
export const getSurveysFromInput = (input) => {
  if (!input) return [];

  try {
    const parsedInput = JSON.parse(input);
    const surveys = parsedInput?.surveys;

    if (surveys) {
      return surveys instanceof Array ? surveys : [];
    }

    return [];
  } catch {
    return [];
  }
};
