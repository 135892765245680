/**
 * Format the report result
 * @param {string | Array<{ value: string, selected: boolean } | string>} result
 * @returns {boolean}
*/
export const formatReportResult = (result) => {
  if (result instanceof Array) {
    if (result[0]?.value) {
      return result.filter(item => item?.selected).map(item => item?.value)?.join(', ');
    }

    return result.join(', ');
  }

  return result;
};
