import { uniqueId } from 'lodash';
import { getThumbnailFromMedia } from './getThumbnailFromMedia';
/**
 * Get the medias from helpers and validate it
 * @param {string} helpers
 * @returns {string}
*/
export const getMediasFromInput = (input, env) => {
  if (!input) return [];
  try {
    const medias = input?.medias;

    if (medias) {
      return medias.map(media => ({
        name: media.origName || uniqueId(),
        type: media.type.toLowerCase(),
        preview: media.uri,
        thumbnail: getThumbnailFromMedia(media, env),
        response: { filename: media.filename, uri: media.uri }
      }));
    }

    return [];
  } catch {
    return [];
  }
};
