import React from 'react';
import { Divider } from 'semantic-ui-react';
import gql from 'graphql-tag';
import ChargeItem from '../ChargeResult/Item';

export default class MessageCharges extends React.Component {
  static fragments = {
    charges: gql`
      fragment MessageCharges on Message {
        charges {
          nodes {
            id: dbId
            ...ChargeItem
          }
        }
      }
      ${ChargeItem.fragments.charge}
      `,
  }

  render() {
    const {
      charges, params, messageType, entity, reload, messageToApprove
    } = this.props;

    if ((!charges || !charges?.length) && !charges?.nodes?.length) return null;

    return (
      <div id="MessageCharges">
        {messageToApprove ? (
          charges.map(charge => (
            <div key={charge.id}>
              <ChargeItem
                charge={charge}
                params={params}
                reload={reload}
                messageType="SENT"
                entity={entity}
                fromHistory={this.props.fromHistory}
                messageToApprove={messageToApprove}
              />
              <Divider hidden fitted />
            </div>
          ))
        ) : (
          charges.nodes.map(charge => (
            <div key={charge.id}>
              <ChargeItem
                charge={charge}
                params={params}
                reload={reload}
                messageType={messageType}
                entity={entity}
                fromHistory={this.props.fromHistory}
                messageToApprove={messageToApprove}
              />
              <Divider hidden fitted />
            </div>
          ))
        )}
      </div>
    );
  }
}
