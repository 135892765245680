/**
 * Get the commitment from input and validate it
 * @param {{
 *  commitments: [
 *    {
 *      name: string
 *      date: string
 *      endDate: string
 *      endTime: string
 *      hasDate: boolean
 *      hasTime: boolean
 *      noConfirm: boolean
 *      dateLimit: string
 *      hasvideocallLink: boolean
 *      videocallLink: string
 *      videocallType: string
 *    }
 *  ]
 * }} input
 * @returns {string}
*/

export const getCommitmentsFromInput = (input) => {
  if (!input) return [];

  try {
    const commitments = input?.commitments;

    if (commitments) {
      return commitments.map((commitment) => {
        if (commitment.date) {
          const date = new Date(commitment.date);
          commitment.date = new Date(date.setTime(date.getTime() + (date.getTimezoneOffset() * 60 * 1000)));
          commitment.hasDate = true;
        } else {
          commitment.date = null;
          commitment.hasDate = false;
        }

        if (commitment.dateLimit) {
          const date = new Date(commitment.dateLimit.substring(0, 10));
          commitment.dateLimit = new Date(date.setTime(date.getTime() + (date.getTimezoneOffset() * 60 * 1000)));
        }

        if (commitment.time) {
          commitment.hasTime = true;
        } else {
          commitment.hasTime = false;
          commitment.time = null;
        }

        return commitment;
      });
    }

    return [];
  } catch {
    return [];
  }
};
