/* eslint-disable sonarjs/no-duplicate-string */
import { isMessageFromNetwork } from '../isMessageFromNetwork';

describe('isMessageFromNetwork', () => {
  let props;
  beforeEach(() => {
    jest.clearAllMocks();

    props = {
      organization: {
        network: {
          id: 1,
        },
        unleashStatus: {
          network_communication: true,
        },
      },
      message: {
        entity: {
          organizationId: 2,
        },
      },
    };
  });

  it('should be able to return null when organization is not provided', () => {
    props.organization = undefined;

    const result = isMessageFromNetwork(props);

    expect(result).toBe(false);
  });

  it('should be able to return null when message is not provided', () => {
    props.message = undefined;

    const result = isMessageFromNetwork(props);

    expect(result).toBe(false);
  });

  it('should be able to return true when it is message from network and organization has unleash', () => {
    props.organization.network.id = 2;
    const result = isMessageFromNetwork(props);
    expect(result).toBe(true);
  });
});
