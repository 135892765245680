/**
 * Get the thumbnail from media
 * @param {string} helpers
 * @returns {string}
*/
export const getThumbnailFromMedia = (media, env) => {
  if (!media) return null;

  const mediaType = media?.type?.toLowerCase();

  if (mediaType === 'video') {
    if (env === 'production') {
      return `https://images.classapp.com.br/w320/classapp-live-media-1/thumb-${media.filename}-00001.png`;
    }

    return `https://images.classapp.com.br/w320/classapp-${env}-media-1/thumb-${media.filename}-00001.png`;
  }

  return null;
};
