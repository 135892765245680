/**
 * Get the reports from helpers and validate it
 * @param {string} helpers
* @returns {string}
*/
export const getReportsFromHelpers = (helpers) => {
  if (!helpers) return [];

  try {
    const parsedHelpers = JSON.parse(helpers);
    const reports = parsedHelpers?.reports;

    if (reports) {
      return reports instanceof Array ? reports : [];
    }

    return [];
  } catch {
    return [];
  }
};
