import { sendGoogleAnalyticsEvent } from '../../../../lib/analytics';

export const sendApprovalMessageEvent = (category, store) => {
  const organizationId = store && store.currentOrganization && store.currentOrganization.id;

  sendGoogleAnalyticsEvent({
    name: 'Approval Message',
    category,
    label: `OrganizationID: ${organizationId}`,
  }, { store });
};
