import { __ } from '../../../i18n';
import * as utils from '../../../utils';

/**
 *
 * @param {boolean} hasUndoOption
 * @param {object} messageStatus
 * @param {any} props
 * @param {(hasUndoOption?: boolean) => any} deleteMessage
 * @param {() => void} backAndRefresh
 * @returns {Promise<any>}
 */
const recoverMessage = async (hasUndoOption = true, messageStatus = null, props, deleteMessage, backAndRefresh) => {
  props?.super?.setLoading(true);

  const { recoverForMe, data, store } = props;
  const request = {
    mutate: recoverForMe,
    input: 'DeleteMessageStatusMutation',
    message: hasUndoOption ? __('Message recovered') : __('Action successfully undone'),
    onClick: hasUndoOption ? () => deleteMessage(false) : null
  };

  const id = messageStatus ? messageStatus.id : data.node.message.statuses.nodes[0].id;

  return request?.mutate({
    variables: {
      [request.input]: {
        id
      }
    }
  }).then(async () => {
    if (props.super.resetStore) await props.super.resetStore();
    if (hasUndoOption && backAndRefresh) backAndRefresh();
    props?.super?.setLoading(false);

    utils.clearSnackbar(store);

    store.snackbar = {
      active: true,
      message: request.message,
      success: true,
      action: hasUndoOption ? __('Undo') : __('Close'),
      dismissAfter: 10000,
      onClick: request.onClick
    };
  }).catch((err) => {
    console.error(err);

    utils.clearSnackbar(store);

    props.store.snackbar = {
      active: true,
      message: __('An error occurred while recovering message'),
      success: false,
      dismissAfter: 5000
    };
  });
};

export default recoverMessage;
